import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { X } from 'lucide-react';

const NavItem = ({ to, children, onClick, isMobile }) => {
  const location = useLocation();
  const isActive = location.pathname === to;

  if (isMobile) {
    return (
      <Link
        to={to}
        onClick={onClick}
        className={`
          block w-full px-4 py-3 
          text-gray-700
          hover:text-black
          border-b border-gray-100
          ${isActive ? 'text-blue-600' : ''}
        `}
      >
        {children}
      </Link>
    );
  }

  return (
    <Link
      to={to}
      onClick={onClick}
      className={`
        px-4 py-2 text-gray-700
        hover:text-black
        transition-colors relative group
        ${isActive ? 'text-black font-semibold' : ''}
      `}
    >
      {children}
      <span
        className={`
          absolute bottom-0 left-0 w-full h-0.5 
          bg-blue-600 transform scale-x-0 
          group-hover:scale-x-100 transition-transform duration-300
          ${isActive ? 'scale-x-100' : ''}
        `}
      />
    </Link>
  );
};

const Logo = ({ className = "h-8 w-8" }) => (
  <svg className={className} viewBox="0 0 128 128">
    <defs>
      <linearGradient id="logoGradient" x1="0%" y1="0%" x2="100%" y2="100%">
        <stop offset="0%" stopColor="#3B82F6" />
        <stop offset="100%" stopColor="#2563EB" />
      </linearGradient>
    </defs>
    <path 
      fill="url(#logoGradient)"
      d="M108 28H20c-6.627 0-12 5.373-12 12v48c0 6.627 5.373 12 12 12h28l12 16 12-16h36c6.627 0 12-5.373 12-12V40c0-6.627-5.373-12-12-12zm-4 52c0 2.21-1.79 4-4 4H28c-2.21 0-4-1.79-4-4V48c0-2.21 1.79-4 4-4h72c2.21 0 4 1.79 4 4v32z"
    />
    <path 
      fill="#fff"
      d="M36 52h56v4H36zm0 12h40v4H36z"
    />
  </svg>
);

const MainPageNavbar = ({ brand, links, isDashboard = false }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  
  const navbarClasses = `
    fixed top-0 left-0 right-0 
    ${isDashboard ? 'md:left-64' : ''} 
    z-50 px-4 py-3
    bg-white
    shadow-md
    transition-colors duration-200
  `;

  const mobileMenuClasses = `
    fixed inset-0 bg-white z-50
    flex flex-col
    transform transition-transform duration-200
    ${isMenuOpen ? 'translate-x-0' : 'translate-x-full'}
  `;

  return (
    <>
      <nav className={navbarClasses}>
        <div className="max-w-7xl mx-auto flex items-center justify-between">
          {/* Logo and Brand */}
          <Link 
            to="/" 
            className="flex items-center space-x-3 text-black hover:opacity-90 transition-opacity"
          >
            <Logo />
            <span className="font-bold text-lg md:text-xl">{brand}</span>
          </Link>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-1">
            {links.map((link) => (
              <NavItem key={link.to} to={link.to}>
                {link.label}
              </NavItem>
            ))}
          </div>

          {/* Mobile Menu Button */}
          <button
            onClick={() => setIsMenuOpen(true)}
            className="md:hidden p-2"
          >
            <span className="sr-only">Open menu</span>
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>

          {/* Login Button (Desktop) */}
          <Link
            to="/login"
            className="hidden md:inline-flex items-center px-4 py-2 
              text-sm md:text-base rounded-md 
              bg-blue-600 text-white
              border border-transparent 
              hover:bg-blue-700 
              transition-all duration-200
              shadow-sm hover:shadow-md"
          >
            Log In
          </Link>
        </div>
      </nav>

      {/* Mobile Menu Overlay */}
      <div className={mobileMenuClasses}>
        <div className="flex items-center justify-between p-4 border-b border-gray-100">
          <span className="font-bold text-lg">{brand}</span>
          <button
            onClick={() => setIsMenuOpen(false)}
            className="p-2"
          >
            <X size={24} />
          </button>
        </div>
        
        <div className="flex flex-col">
          {links.map((link) => (
            <NavItem 
              key={link.to} 
              to={link.to} 
              onClick={() => setIsMenuOpen(false)}
              isMobile
            >
              {link.label}
            </NavItem>
          ))}
        </div>

        <Link
          to="/login"
          onClick={() => setIsMenuOpen(false)}
          className="mt-auto mx-4 mb-4 py-3 px-4
            text-center rounded-md 
            bg-blue-600 text-white
            hover:bg-blue-700 
            transition-colors duration-200"
        >
          Log In
        </Link>
      </div>
    </>
  );
};

MainPageNavbar.propTypes = {
  brand: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  isDashboard: PropTypes.bool,
};

export default MainPageNavbar;