import React from 'react';
import PropTypes from 'prop-types';

const FeatureCard = ({ 
  Icon, 
  title, 
  description, 
  highlight, 
  variant = 'default',
  className = '' 
}) => {
  // Card style variants
  const variants = {
    default: {
      card: "bg-white",
      icon: "bg-blue-100",
      iconColor: "text-blue-600",
      highlight: "bg-blue-50 text-blue-600"
    },
    dark: {
      card: "bg-gray-800",
      icon: "bg-gray-700",
      iconColor: "text-blue-400",
      highlight: "bg-gray-700 text-blue-400"
    },
    gradient: {
      card: "bg-gradient-to-br from-blue-500 to-blue-700 text-white",
      icon: "bg-white/20",
      iconColor: "text-white",
      highlight: "bg-white/20 text-white"
    }
  };

  const styles = variants[variant];

  return (
    <div 
      className={`
        text-center p-8 rounded-xl shadow-sm 
        hover:shadow-xl transition-all duration-300 
        ${styles.card} ${className}
      `}
    >
      {Icon && (
        <div className={`
          w-16 h-16 rounded-full 
          flex items-center justify-center 
          mx-auto mb-6 transform 
          hover:rotate-12 transition-transform 
          ${styles.icon}
        `}>
          <Icon className={`h-8 w-8 ${styles.iconColor}`} />
        </div>
      )}
      
      <h3 className={`
        text-xl font-semibold mb-4 
        ${variant === 'dark' || variant === 'gradient' ? 'text-white' : 'text-gray-900'}
      `}>
        {title}
      </h3>
      
      <p className={`
        mb-4 
        ${variant === 'dark' || variant === 'gradient' ? 'text-gray-300' : 'text-gray-600'}
      `}>
        {description}
      </p>
      
      {highlight && (
        <span className={`
          inline-block px-3 py-1 rounded-full text-sm
          ${styles.highlight}
        `}>
          {highlight}
        </span>
      )}
    </div>
  );
};

FeatureCard.propTypes = {
  Icon: PropTypes.elementType, // Lucide icon component
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  highlight: PropTypes.string,
  variant: PropTypes.oneOf(['default', 'dark', 'gradient']),
  className: PropTypes.string
};

export default FeatureCard;