import React, { useState, useRef, useEffect } from 'react';
import { Calendar, ChevronDown } from 'lucide-react';
import { 
  format, 
  subDays, 
  startOfMonth, 
  endOfMonth, 
  subMonths,
  startOfYear,
  endOfYear,
  subYears,
  isAfter,
  startOfDay,
  parse
} from 'date-fns';

const getCurrentDate = () => {
  const currentDate = startOfDay(new Date());
  console.log("Current date is:", currentDate.toString());
  return currentDate;
};

const PRESET_RANGES = [
  {
    label: "Today vs Yesterday",
    getCurrentRange: () => {
      const today = getCurrentDate();
      return {
        startDate: format(today, "yyyy-MM-dd"),
        endDate: format(today, "yyyy-MM-dd"),
      };
    },
    getComparisonRange: () => {
      const today = getCurrentDate();
      const yesterday = subDays(today, 1);
      return {
        startDate: format(yesterday, "yyyy-MM-dd"),
        endDate: format(yesterday, "yyyy-MM-dd"),
      };
    },
  },
  {
    label: "Last 7 days vs Previous 7 days",
    getCurrentRange: () => {
      const today = getCurrentDate();
      return {
        startDate: format(subDays(today, 6), "yyyy-MM-dd"),
        endDate: format(today, "yyyy-MM-dd"),
      };
    },
    getComparisonRange: () => {
      const today = getCurrentDate();
      return {
        startDate: format(subDays(today, 13), "yyyy-MM-dd"),
        endDate: format(subDays(today, 7), "yyyy-MM-dd"),
      };
    },
  },
  {
    label: "Last 30 days vs Previous 30 days",
    getCurrentRange: () => {
      const today = getCurrentDate();
      return {
        startDate: format(subDays(today, 29), "yyyy-MM-dd"),
        endDate: format(today, "yyyy-MM-dd"),
      };
    },
    getComparisonRange: () => {
      const today = getCurrentDate();
      return {
        startDate: format(subDays(today, 59), "yyyy-MM-dd"),
        endDate: format(subDays(today, 30), "yyyy-MM-dd"),
      };
    },
  },
  {
    label: "This month vs Last month",
    getCurrentRange: () => {
      const today = getCurrentDate();
      const firstDayOfMonth = startOfMonth(today);
      return {
        startDate: format(firstDayOfMonth, "yyyy-MM-dd"),
        endDate: format(today, "yyyy-MM-dd"),
      };
    },
    getComparisonRange: () => {
      const today = getCurrentDate();
      const lastMonth = subMonths(today, 1);
      return {
        startDate: format(startOfMonth(lastMonth), "yyyy-MM-dd"),
        endDate: format(endOfMonth(lastMonth), "yyyy-MM-dd"),
      };
    },
  },
  {
    label: "Last month vs Month before",
    getCurrentRange: () => {
      const today = getCurrentDate();
      const lastMonth = subMonths(today, 1);
      return {
        startDate: format(startOfMonth(lastMonth), "yyyy-MM-dd"),
        endDate: format(endOfMonth(lastMonth), "yyyy-MM-dd"),
      };
    },
    getComparisonRange: () => {
      const today = getCurrentDate();
      const twoMonthsAgo = subMonths(today, 2);
      return {
        startDate: format(startOfMonth(twoMonthsAgo), "yyyy-MM-dd"),
        endDate: format(endOfMonth(twoMonthsAgo), "yyyy-MM-dd"),
      };
    },
  },
  {
    label: "This year vs Last year",
    getCurrentRange: () => {
      const today = getCurrentDate();
      const startOfThisYear = startOfYear(today);
      return {
        startDate: format(startOfThisYear, "yyyy-MM-dd"),
        endDate: format(today, "yyyy-MM-dd"),
      };
    },
    getComparisonRange: () => {
      const today = getCurrentDate();
      const lastYear = subYears(today, 1);
      const startOfLastYear = startOfYear(lastYear);
      const endOfLastYear = endOfYear(lastYear);
      return {
        startDate: format(startOfLastYear, "yyyy-MM-dd"),
        endDate: format(endOfLastYear, "yyyy-MM-dd"),
      };
    },
  },
];

const DateRangeSelector = ({ currentRange, comparisonRange, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [customMode, setCustomMode] = useState(false);
  const [error, setError] = useState(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const formatDisplayDate = (date) => {
    const parsedDate = parse(date, 'yyyy-MM-dd', new Date());
    return format(parsedDate, 'MMM d, yyyy');
  };

  const handlePresetSelect = (preset) => {
    setError(null);
    onChange({
      current: preset.getCurrentRange(),
      comparison: preset.getComparisonRange(),
    });
    setCustomMode(false);
    setIsOpen(false);
  };

  const validateCustomDateRange = (newRanges) => {
    const { current, comparison } = newRanges;

    const currentStart = parse(current.startDate, 'yyyy-MM-dd', new Date());
    const currentEnd = parse(current.endDate, 'yyyy-MM-dd', new Date());
    const comparisonStart = parse(comparison.startDate, 'yyyy-MM-dd', new Date());
    const comparisonEnd = parse(comparison.endDate, 'yyyy-MM-dd', new Date());

    // Validate current range
    if (isAfter(currentStart, currentEnd)) {
      setError("Current period: Start date must be before end date");
      return false;
    }

    // Validate comparison range
    if (isAfter(comparisonStart, comparisonEnd)) {
      setError("Comparison period: Start date must be before end date");
      return false;
    }

    setError(null);
    return true;
  };

  const handleCustomRangeChange = (dates, isComparison = false) => {
    const newRanges = {
      current: { ...currentRange },
      comparison: { ...comparisonRange },
    };

    if (isComparison) {
      newRanges.comparison = dates;
    } else {
      newRanges.current = dates;
    }

    if (validateCustomDateRange(newRanges)) {
      onChange(newRanges);
    }
  };

  const getCurrentRangeDisplay = () => {
    const preset = PRESET_RANGES.find(
      preset =>
        preset.getCurrentRange().startDate === currentRange.startDate &&
        preset.getComparisonRange().startDate === comparisonRange.startDate
    );
    
    if (preset) return preset.label;

    // Format custom range display
    const currentMonth = format(parse(currentRange.startDate, 'yyyy-MM-dd', new Date()), 'MMM yyyy');
    const comparisonMonth = format(parse(comparisonRange.startDate, 'yyyy-MM-dd', new Date()), 'MMM yyyy');
    return `${currentMonth} vs ${comparisonMonth}`;
  };

  const MonthYearSelector = ({ value, onChange, disabledMonths = [] }) => {
    const currentDate = parse(value, 'yyyy-MM-dd', new Date());
    const today = getCurrentDate();
    const currentYear = today.getFullYear();
    const years = Array.from({ length: 5 }, (_, i) => currentYear - i);
    const months = Array.from({ length: 12 }, (_, i) => ({
      value: i,
      label: format(new Date(currentYear, i, 1), "MMMM"),
    }));

    const handleChange = (month, year) => {
      const newDate = new Date(year, month, 1);
      const endDate = endOfMonth(newDate);
      onChange({
        startDate: format(newDate, 'yyyy-MM-dd'),
        endDate: format(endDate, 'yyyy-MM-dd')
      });
    };

    return (
      <div className="flex gap-2">
        <select
          value={currentDate.getMonth()}
          onChange={(e) => {
            handleChange(parseInt(e.target.value), currentDate.getFullYear());
          }}
          className="px-2 py-1 border rounded bg-white dark:bg-gray-800"
        >
          {months.map((month) => (
            <option 
              key={month.value} 
              value={month.value}
              disabled={disabledMonths.some(
                disabled => 
                  disabled.month === month.value && 
                  disabled.year === currentDate.getFullYear()
              )}
            >
              {month.label}
            </option>
          ))}
        </select>
        <select
          value={currentDate.getFullYear()}
          onChange={(e) => {
            handleChange(currentDate.getMonth(), parseInt(e.target.value));
          }}
          className="px-2 py-1 border rounded bg-white dark:bg-gray-800"
        >
          {years.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </div>
    );
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full md:w-auto flex items-center justify-between px-4 py-2 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg shadow-sm hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors"
      >
        <div className="flex items-center gap-2">
          <Calendar className="w-4 h-4 text-gray-500 dark:text-gray-400" />
          <span className="text-sm text-gray-700 dark:text-gray-300 truncate">
            {getCurrentRangeDisplay()}
          </span>
        </div>
        <ChevronDown
          className={`w-4 h-4 text-gray-500 dark:text-gray-400 transition-transform duration-200 ${
            isOpen ? "transform rotate-180" : ""
          }`}
        />
      </button>

      {isOpen && (
        <div className="fixed inset-0 z-50 md:absolute md:inset-auto md:top-full md:left-0 md:mt-2">
          <div 
            className="fixed inset-0 bg-black/50 md:hidden"
            onClick={() => setIsOpen(false)}
          />
          
          <div className="fixed bottom-0 inset-x-0 md:absolute md:bottom-auto md:inset-x-auto md:w-[400px] bg-white dark:bg-gray-800 rounded-t-xl md:rounded-lg shadow-lg">
            <div className="h-1.5 w-16 mx-auto my-2 bg-gray-300 dark:bg-gray-600 rounded-full md:hidden" />
            
            <div className="p-4 space-y-4 max-h-[80vh] md:max-h-[600px] overflow-y-auto">
              <div className="space-y-2">
                {PRESET_RANGES.map((preset, index) => (
                  <button
                    key={index}
                    onClick={() => {
                      handlePresetSelect(preset);
                      setIsOpen(false);
                    }}
                    className="w-full text-left px-3 py-3 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-md transition-colors"
                  >
                    {preset.label}
                  </button>
                ))}
                <button
                  onClick={() => setCustomMode(!customMode)}
                  className="w-full text-left px-3 py-3 text-sm text-blue-600 dark:text-blue-400 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-md transition-colors"
                >
                  Custom Range
                </button>
              </div>

              {customMode && (
                <div className="space-y-4 border-t dark:border-gray-700 pt-4">
                  <div className="space-y-2">
                    <label className="text-sm font-medium text-gray-700 dark:text-gray-300">
                      Current Period
                    </label>
                    <MonthYearSelector
                      value={currentRange.startDate}
                      onChange={(dates) => handleCustomRangeChange(dates)}
                      disabledMonths={[{
                        month: parse(comparisonRange.startDate, 'yyyy-MM-dd', new Date()).getMonth(),
                        year: parse(comparisonRange.startDate, 'yyyy-MM-dd', new Date()).getFullYear()
                      }]}
                    />
                  </div>

                  <div className="space-y-2">
                    <label className="text-sm font-medium text-gray-700 dark:text-gray-300">
                      Compare To
                    </label>
                    <MonthYearSelector
                      value={comparisonRange.startDate}
                      onChange={(dates) => handleCustomRangeChange(dates, true)}
                      disabledMonths={[{
                        month: parse(currentRange.startDate, 'yyyy-MM-dd', new Date()).getMonth(),
                        year: parse(currentRange.startDate, 'yyyy-MM-dd', new Date()).getFullYear()
                      }]}
                    />
                  </div>

                  {error && (
                    <div className="text-sm text-red-500 dark:text-red-400">
                      {error}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DateRangeSelector;
 