import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loadingStates: {},
  globalLoading: false,
  authInitialized: false
};

const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    startLoading: (state, action) => {
      const key = action.payload || 'global';
      state.loadingStates[key] = true;
      if (key === 'global') state.globalLoading = true;
    },
    stopLoading: (state, action) => {
      const key = action.payload || 'global';
      state.loadingStates[key] = false;
      if (key === 'global') state.globalLoading = false;
    },
    resetLoading: (state) => {
      state.loadingStates = {};
      state.globalLoading = false;
    },
    setAuthInitialized: (state, action) => {
      state.authInitialized = action.payload;
    }
  }
});

export const { 
  startLoading, 
  stopLoading, 
  resetLoading,
  setAuthInitialized 
} = loadingSlice.actions;

// Selectors
export const selectLoadingState = (state, key = 'global') => 
  key === 'global' ? state.loading.globalLoading : state.loading.loadingStates[key];

export const selectAuthInitialized = (state) => state.loading.authInitialized;

// Custom middleware to handle loading states for async actions
export const loadingMiddleware = () => (next) => (action) => {
  if (action.type.endsWith('/pending')) {
    const key = action.type.split('/pending')[0];
    next(startLoading(key));
  }
  if (action.type.endsWith('/fulfilled') || action.type.endsWith('/rejected')) {
    const key = action.type.split('/')[0];
    next(stopLoading(key));
  }
  return next(action);
};

export default loadingSlice.reducer;