// src/store/slices/locationSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getLocations,
  createLocation,
  updateLocation as updateLocationApi,
  deleteLocation as deleteLocationApi,
} from "../../services/api/apiUtility";

const initialState = {
  locations: [],
  selectedLocation: null,
  isLoading: true,
  error: null,
  offsets: {
    offset: 0,
    staffOffset: 0,
    menuOffset: 0,
  },
};

export const fetchLocations = createAsyncThunk(
  "location/fetchLocations",
  async (_, { getState, rejectWithValue }) => {
    try {
      const {
        auth: { accessToken, userRole, selectedRestaurant },
      } = getState();

      if (!accessToken) {
        throw new Error("No access token available");
      }

      const response = await getLocations(accessToken);

      if (response.status === "success") {
        let fetchedLocations = response.data;

        // Filter locations based on selected restaurant for SuperAdmin
        if (userRole === "SuperAdmin" && selectedRestaurant) {
          fetchedLocations = fetchedLocations.filter(
            (location) => location.restaurant_id === selectedRestaurant.id
          );
        }

        // Add 'All' option for certain roles
        if (["SuperAdmin", "Admin", "BOH"].includes(userRole)) {
          fetchedLocations.unshift({ name: "All", id: null });
        }

        return fetchedLocations;
      } else {
        throw new Error(response.message || "Failed to fetch locations");
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const addLocation = createAsyncThunk(
  "location/addLocation",
  async (locationData, { getState, rejectWithValue, dispatch }) => {
    try {
      const {
        auth: { accessToken },
      } = getState();
      const response = await createLocation(locationData, accessToken);

      if (response.status === "success") {
        // Refresh the locations list after adding
        await dispatch(fetchLocations());
        return response.data;
      } else {
        throw new Error(response.message || "Failed to add location");
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateLocation = createAsyncThunk(
  "location/updateLocation",
  async ({ id, locationData }, { getState, rejectWithValue, dispatch }) => {
    try {
      const {
        auth: { accessToken },
      } = getState();
      const response = await updateLocationApi(id, locationData, accessToken);

      if (response.status === "success") {
        // Refresh the locations list after updating
        await dispatch(fetchLocations());
        return response.data;
      } else {
        throw new Error(response.message || "Failed to update location");
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteLocation = createAsyncThunk(
  "location/deleteLocation",
  async (id, { getState, rejectWithValue, dispatch }) => {
    try {
      const {
        auth: { accessToken },
      } = getState();
      await deleteLocationApi(id, accessToken);
      // Refresh the locations list after deleting
      await dispatch(fetchLocations());
      return id;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    setSelectedLocation: (state, action) => {
      if (!action.payload) {
        state.selectedLocation = null;
        return;
      }
      // Ensure consistent handling of "All" location
      if (action.payload.name === "All") {
        state.selectedLocation = { 
          ...action.payload, 
          id: "all" // Always use "all" as the ID for All location
        };
      } else {
        state.selectedLocation = action.payload;
      }
    },
    setOffsets: (state, action) => {
      state.offsets = action.payload;
    },
    resetOffsets: (state) => {
      state.offsets = initialState.offsets;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch Locations
      .addCase(fetchLocations.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchLocations.fulfilled, (state, action) => {
        state.isLoading = false;
        state.locations = action.payload;
        state.initialized = true;  
        // Set initial selected location if none is selected
        if (!state.selectedLocation && action.payload.length > 0) {
          state.selectedLocation = action.payload[0];
        }
      })
      .addCase(fetchLocations.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      // Other async actions can be handled similarly if needed
      .addCase(addLocation.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(updateLocation.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(deleteLocation.rejected, (state, action) => {
        state.error = action.payload;
      });
  },
});

// Actions
export const { setSelectedLocation, setOffsets, resetOffsets } =
  locationSlice.actions;

// Selectors
export const selectLocationsInitialized = (state) => state.location.initialized;
export const selectLocations = (state) => state.location.locations;
export const selectSelectedLocation = (state) =>
  state.location.selectedLocation;
export const selectLocationLoading = (state) => state.location.isLoading;
export const selectLocationError = (state) => state.location.error;
export const selectOffsets = (state) => state.location.offsets;
export const selectLocationById = (state, id) =>
  state.location.locations.find((loc) => loc.id === id);
export const selectLocationName = (state, id) =>
  state.location.locations.find((loc) => loc.id === id)?.name || "Unknown";

export default locationSlice.reducer;
