// store/slices/restaurantSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { 
  createRestaurant, 
  deleteRestaurant,
  getRestaurant
} from '../../services/api/apiUtility';

// Initial state matching the hook's state structure
const initialState = {
  restaurants: [],
  loading: false,
  error: null,
  showSuccess: false,
  lastUpdated: null
};

// Async thunks
export const fetchRestaurants = createAsyncThunk(
  'restaurant/fetchRestaurants',
  async (_, { getState, rejectWithValue }) => {
    try {
      const { auth: { accessToken } } = getState();
      const response = await getRestaurant(accessToken);
      
      if (response.status === 'success' && Array.isArray(response.data)) {
        return response.data;
      } else {
        throw new Error('Unexpected response format');
      }
    } catch (error) {
      console.error('Error fetching restaurants:', error);
      return rejectWithValue('Failed to fetch restaurants. Please try again.');
    }
  }
);

export const addRestaurant = createAsyncThunk(
  'restaurant/addRestaurant',
  async (name, { getState, rejectWithValue, dispatch }) => {
    if (!name || typeof name !== 'string' || !name.trim()) {
      return rejectWithValue('Restaurant name cannot be empty');
    }

    try {
      const { auth: { accessToken } } = getState();
      const response = await createRestaurant(name.trim(), accessToken);
      
      if (response && response.status === 'success' && response.data) {
        // Dispatch success message after successful addition
        dispatch(showSuccessMessage());
        return response.data;
      } else {
        throw new Error('Unexpected response format');
      }
    } catch (error) {
      console.error('Error adding restaurant:', error);
      return rejectWithValue('Failed to add restaurant. Please try again.');
    }
  }
);

export const removeRestaurant = createAsyncThunk(
  'restaurant/removeRestaurant',
  async (id, { getState, rejectWithValue, dispatch }) => {
    try {
      const { auth: { accessToken } } = getState();
      const response = await deleteRestaurant(id, accessToken);
      
      if (response && response.status === 'success') {
        // Dispatch success message after successful deletion
        dispatch(showSuccessMessage());
        return id;
      } else {
        throw new Error('Unexpected response format');
      }
    } catch (error) {
      console.error('Error deleting restaurant:', error);
      return rejectWithValue('Failed to delete restaurant. Please try again.');
    }
  }
);

// Success message thunk
export const showSuccessMessage = createAsyncThunk(
  'restaurant/showSuccessMessage',
  async (_, { dispatch }) => {
    dispatch(setShowSuccess(true));
    await new Promise(resolve => setTimeout(resolve, 3000));
    dispatch(setShowSuccess(false));
  }
);

// Slice
const restaurantSlice = createSlice({
  name: 'restaurant',
  initialState,
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
    setShowSuccess: (state, action) => {
      state.showSuccess = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      // Fetch restaurants
      .addCase(fetchRestaurants.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchRestaurants.fulfilled, (state, action) => {
        state.loading = false;
        state.restaurants = action.payload;
        state.lastUpdated = new Date().toISOString();
      })
      .addCase(fetchRestaurants.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      
      // Add restaurant
      .addCase(addRestaurant.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addRestaurant.fulfilled, (state, action) => {
        state.loading = false;
        state.restaurants = [action.payload, ...state.restaurants];
        state.lastUpdated = new Date().toISOString();
      })
      .addCase(addRestaurant.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      
      // Remove restaurant
      .addCase(removeRestaurant.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(removeRestaurant.fulfilled, (state, action) => {
        state.loading = false;
        state.restaurants = state.restaurants.filter(
          restaurant => restaurant.id !== action.payload
        );
        state.lastUpdated = new Date().toISOString();
      })
      .addCase(removeRestaurant.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

// Actions
export const { clearError, setShowSuccess } = restaurantSlice.actions;

// Selectors
export const selectRestaurants = (state) => state.restaurant.restaurants;
export const selectRestaurantLoading = (state) => state.restaurant.loading;
export const selectRestaurantError = (state) => state.restaurant.error;
export const selectRestaurantSuccess = (state) => state.restaurant.showSuccess;
export const selectRestaurantLastUpdated = (state) => state.restaurant.lastUpdated;

export default restaurantSlice.reducer;