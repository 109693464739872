import React, { useContext } from 'react';
import { ThemeContext } from '../../../contexts/ThemeContext';

const Reports = () => {
  const { theme } = useContext(ThemeContext);
  const isDark = theme === 'dark';

  return (
    <div>
      <h3 className={`text-xl font-semibold mb-4 ${isDark ? 'text-white' : 'text-gray-900'}`}>
        Reports Panel
      </h3>
      <p className={`${isDark ? 'text-gray-300' : 'text-gray-600'}`}>
        No reports yet.
      </p>
    </div>
  );
};

export default Reports;