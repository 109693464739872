import React from 'react';

const DeleteConfirmationModal = ({ isDark, deleteConfirmation, handleDeleteUser, setDeleteConfirmation }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className={`${isDark ? 'bg-gray-800' : 'bg-white'} p-6 rounded-lg shadow-xl`}>
        <h3 className="text-xl font-bold mb-4">Confirm Deletion</h3>
        <p className="mb-4">
          Are you sure you want to delete the user "{deleteConfirmation.username}"?
        </p>
        <div className="flex justify-end space-x-2">
          <button
            onClick={() => setDeleteConfirmation(null)}
            className={`px-4 py-2 ${isDark ? 'bg-gray-600 text-white' : 'bg-gray-300 text-black'} rounded hover:bg-gray-400 transition-colors`}
          >
            Cancel
          </button>
          <button
            onClick={() => handleDeleteUser(deleteConfirmation.id)}
            className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition-colors"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmationModal;
