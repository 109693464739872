// src/hooks/useProcessedData.js
import { useMemo } from 'react';

const useProcessedData = (reviewsData, selectedLocation, getLocationNameById, userRole) => {
  return useMemo(() => {
    if (userRole === 'BOH' || !selectedLocation) {
      return [];
    }

    if (selectedLocation.name === 'All') {
      const locationMap = {};

      reviewsData.forEach((review) => {
        const locationId = review.location_id;
        if (!locationMap[locationId]) {
          locationMap[locationId] = {
            name: getLocationNameById(locationId),
            totalReviews: 0,
            positiveReviews: 0,
            negativeReviews: 0,
            sumRating: 0,
            avgRating: 0,
          };
        }

        const locationData = locationMap[locationId];
        const ratingValue = parseFloat(review.rating || 0);

        if (!isNaN(ratingValue) && ratingValue > 0) {
          locationData.totalReviews += 1;
          locationData.sumRating += ratingValue;

          if (ratingValue >= 4) {
            locationData.positiveReviews += 1;
          } else if (ratingValue <= 3) {
            locationData.negativeReviews += 1;
          }
        }
      });

      Object.values(locationMap).forEach((locationData) => {
        if (locationData.totalReviews > 0) {
          locationData.avgRating = locationData.sumRating / locationData.totalReviews;
        }
      });

      return Object.values(locationMap);
    } else {
      const totalReviews = reviewsData.length;
      const positiveReviews = reviewsData.filter(
        (review) => parseFloat(review.rating || 0) >= 4
      ).length;
      const negativeReviews = reviewsData.filter(
        (review) => parseFloat(review.rating || 0) <= 3
      ).length;
      const sumRating = reviewsData.reduce(
        (sum, review) => sum + parseFloat(review.rating || 0),
        0
      );
      const avgRating = totalReviews > 0 ? sumRating / totalReviews : 0;

      return [
        {
          name: selectedLocation.name,
          totalReviews,
          positiveReviews,
          negativeReviews,
          sumRating,
          avgRating,
        },
      ];
    }
  }, [reviewsData, getLocationNameById, selectedLocation, userRole]);
};

export default useProcessedData;
