import React, { useContext, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { format, subDays } from 'date-fns';
import { ThemeContext } from '../../../contexts/ThemeContext';
import { 
  selectDateRanges,
  fetchTrendReviews,
  selectTrendReviews,
  setDateRanges,
  fetchTrendStaff,
  selectTrendStaff,
  fetchTrendMenu,
  selectTrendMenu
} from '../../../store/slices/dashboardSlice';
import OverviewCard from './OverviewCard';
import DateRangeSelector from '../../../components/common/DateRangeSelector';
import Loading from '../../../components/common/Loading';

const Trend = () => {
  const { theme } = useContext(ThemeContext);
  const dispatch = useDispatch();
  const dateRanges = useSelector(selectDateRanges);
  const trendReviews = useSelector(selectTrendReviews);
  const trendStaff = useSelector(selectTrendStaff);
  const trendMenu = useSelector(selectTrendMenu);
  const accessToken = useSelector(state => state.auth.accessToken);
  const selectedLocation = useSelector(state => state.location.selectedLocation);

  // Function to fetch all trend data
  const fetchAllTrendData = useCallback(() => {
    if (accessToken) {
      const locationId = selectedLocation?.id || 'all';
      console.log('Fetching trend data for location:', locationId);
      
      dispatch(fetchTrendReviews());
      dispatch(fetchTrendStaff());
      dispatch(fetchTrendMenu());
    }
  }, [dispatch, accessToken, selectedLocation]);

  // Initial setup and data fetch
  useEffect(() => {
    if (accessToken) {
      if (!dateRanges.current || !dateRanges.comparison) {
        dispatch(setDateRanges({
          current: {
            startDate: format(subDays(new Date(), 6), 'yyyy-MM-dd'),
            endDate: format(new Date(), 'yyyy-MM-dd')
          },
          comparison: {
            startDate: format(subDays(new Date(), 13), 'yyyy-MM-dd'),
            endDate: format(subDays(new Date(), 7), 'yyyy-MM-dd')
          }
        }));
      }
      fetchAllTrendData();
    }
  }, [dispatch, accessToken, fetchAllTrendData]);

  // Add a separate effect to handle location changes
  useEffect(() => {
    if (accessToken && dateRanges.current && dateRanges.comparison) {
      fetchAllTrendData();
    }
  }, [accessToken, selectedLocation, fetchAllTrendData]);

  // Handle date range changes
  const handleDateRangeChange = (newRanges) => {
    dispatch(setDateRanges(newRanges));
    fetchAllTrendData();
  };

  const calculateMetrics = useCallback((data, type) => {
    if (!data || data.length === 0) {
      return {
        total: 0,
        average: 0,
        positiveRate: 0
      };
    }

    const total = data.length;

    switch (type) {
      case 'reviews':
        const sumRatings = data.reduce((sum, item) => sum + Number(item.rating), 0);
        const averageRating = sumRatings / total;
        const positiveReviews = data.filter(item => Number(item.rating) >= 4).length;
        return {
          total,
          average: averageRating,
          positiveRate: (positiveReviews / total) * 100
        };

      case 'staff':
      case 'menu':
        const positiveMentions = data.filter(item => item.sentiment === 'positive').length;
        
        const sentimentScore = data.reduce((sum, item) => {
          switch (item.sentiment) {
            case 'positive': return sum + 1;
            case 'neutral': 
            case 'mixed': return sum + 0.5;
            case 'negative': return sum + 0;
            default: return sum;
          }
        }, 0);
        const averageSentiment = (sentimentScore / total) * 5;

        return {
          total,
          average: averageSentiment,
          positiveRate: (positiveMentions / total) * 100
        };

      default:
        return {
          total: 0,
          average: 0,
          positiveRate: 0
        };
    }
  }, []);

  const calculateChange = useCallback((current, previous) => {
    // Add check for identical date ranges
    if (dateRanges.current.startDate === dateRanges.comparison.startDate && 
        dateRanges.current.endDate === dateRanges.comparison.endDate) {
      return 0;
    }
    
    // If previous is 0 and current has a value, it's a 100% increase for each count
    if (previous === 0 && current > 0) {
      return current * 100; // This will give 100% for each count (e.g., 7 becomes 700%)
    }

    // If both are 0, there's no change
    if (previous === 0 && current === 0) {
      return 0;
    }

    // Normal percentage change calculation
    return ((current - previous) / previous) * 100;
  }, [dateRanges]);

  const isLoading = trendReviews.loading || trendStaff.loading || trendMenu.loading;
  const error = trendReviews.error || trendStaff.error || trendMenu.error;

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <Loading.Spinner />
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4 bg-red-100 dark:bg-red-900/50 rounded-lg">
        <p className="text-red-700 dark:text-red-200">{error}</p>
      </div>
    );
  }

  const currentReviewMetrics = calculateMetrics(trendReviews.current, 'reviews');
  const comparisonReviewMetrics = calculateMetrics(trendReviews.comparison, 'reviews');
  
  const currentStaffMetrics = calculateMetrics(trendStaff.current, 'staff');
  const comparisonStaffMetrics = calculateMetrics(trendStaff.comparison, 'staff');
  
  const currentMenuMetrics = calculateMetrics(trendMenu.current, 'menu');
  const comparisonMenuMetrics = calculateMetrics(trendMenu.comparison, 'menu');

  const trendData = [
    // Reviews Section
    {
      title: "Total Reviews",
      value: currentReviewMetrics.total.toString(),
      comparisonValue: comparisonReviewMetrics.total.toString(),
      change: calculateChange(currentReviewMetrics.total, comparisonReviewMetrics.total)
    },
    {
      title: "Average Rating",
      value: currentReviewMetrics.average.toFixed(1),
      comparisonValue: comparisonReviewMetrics.average.toFixed(1),
      change: calculateChange(currentReviewMetrics.average, comparisonReviewMetrics.average)
    },
    {
      title: "Positive Reviews",
      value: `${Math.round(currentReviewMetrics.positiveRate)}%`,
      comparisonValue: `${Math.round(comparisonReviewMetrics.positiveRate)}%`,
      change: calculateChange(currentReviewMetrics.positiveRate, comparisonReviewMetrics.positiveRate)
    },
    // Staff Section
    {
      title: "Total Staff Mentions",
      value: currentStaffMetrics.total.toString(),
      comparisonValue: comparisonStaffMetrics.total.toString(),
      change: calculateChange(currentStaffMetrics.total, comparisonStaffMetrics.total)
    },
    {
      title: "Average Staff Rating",
      value: currentStaffMetrics.average.toFixed(1),
      comparisonValue: comparisonStaffMetrics.average.toFixed(1),
      change: calculateChange(currentStaffMetrics.average, comparisonStaffMetrics.average)
    },
    {
      title: "Positive Staff Mentions",
      value: `${Math.round(currentStaffMetrics.positiveRate)}%`,
      comparisonValue: `${Math.round(comparisonStaffMetrics.positiveRate)}%`,
      change: calculateChange(currentStaffMetrics.positiveRate, comparisonStaffMetrics.positiveRate)
    },
    // Menu Section
    {
      title: "Total Menu Mentions",
      value: currentMenuMetrics.total.toString(),
      comparisonValue: comparisonMenuMetrics.total.toString(),
      change: calculateChange(currentMenuMetrics.total, comparisonMenuMetrics.total)
    },
    {
      title: "Average Menu Rating",
      value: currentMenuMetrics.average.toFixed(1),
      comparisonValue: comparisonMenuMetrics.average.toFixed(1),
      change: calculateChange(currentMenuMetrics.average, comparisonMenuMetrics.average)
    },
    {
      title: "Positive Menu Mentions",
      value: `${Math.round(currentMenuMetrics.positiveRate)}%`,
      comparisonValue: `${Math.round(comparisonMenuMetrics.positiveRate)}%`,
      change: calculateChange(currentMenuMetrics.positiveRate, comparisonMenuMetrics.positiveRate)
    }
  ];

  return (
    <div className="space-y-6 relative">
      <div className="flex justify-end px-4 relative">
        <div className="w-[300px]">
          <DateRangeSelector
            currentRange={dateRanges.current}
            comparisonRange={dateRanges.comparison}
            onChange={handleDateRangeChange}
          />
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 p-4">
        {trendData.map((card, index) => (
          <React.Fragment key={card.title}>
            {index % 3 === 0 && index !== 0 && (
              <div className="col-span-full border-t border-gray-200 dark:border-gray-700 my-4" />
            )}
            <OverviewCard 
              title={card.title}
              value={card.value}
              comparisonValue={card.comparisonValue}
              change={card.change}
            />
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

Trend.propTypes = {
  // Define prop types if any props are expected
};

export default React.memo(Trend);