import React, { useState, useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { ThemeContext } from "../../../contexts/ThemeContext";
import {
  Star,
  MessageCircle,
  MapPin,
  Calendar,
  User,
  Search,
} from "lucide-react";
import { selectSelectedLocation } from "../../../store/slices/locationSlice";
import {
  selectUserRole,
  selectAccessToken,
} from "../../../store/slices/authSlice";
import ReviewResponseModal from "./ReviewResponseModal";
import DateFilter from "../../../components/common/DateFilter";
import { useDateFilter } from "../../../contexts/DateFilterContext";
import ReviewActionCell from "./ReviewActionCell";
import DataTable, { zIndexConfig } from "../../../components/common/DataTable";
import useMediaQuery from "../../../hooks/useMediaQuery";
import { 
  regenerateReviewResponseGPT, 
  updateReviewResponse 
} from '../../../services/api/apiUtility'

// Add this constant near the top of the file, after imports
const REVIEW_STATUS_OPTIONS = [
  { value: 'approved', label: 'Approved' },
  { value: 'posted', label: 'Posted' },
  { value: 'edited', label: 'Edited' },
];

const ReviewsTable = ({
  reviews,
  locations,
  reviewsLoading,
  reviewsHasMore,
  loadMoreReviews,
}) => {
  const { theme } = useContext(ThemeContext);
  const { dateFilter, handleDateChange } = useDateFilter("reviews");
  const isMobile = useMediaQuery("(max-width: 768px)");
  const isDark = theme === "dark";

  // Redux selectors
  const selectedLocation = useSelector(selectSelectedLocation);
  const userRole = useSelector(selectUserRole);
  const accessToken = useSelector(selectAccessToken);

  // Local state
  const [modalData, setModalData] = useState({
    isOpen: false,
    review: null,
  });
  const [isRegenerating, setIsRegenerating] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedRating, setSelectedRating] = useState("");
  const [selectedFlag, setSelectedFlag] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");

  const isAdminOrSuperAdmin = userRole === "Admin" || userRole === "SuperAdmin";

  // Memoized location lookup
  const locationMap = useMemo(() => {
    return locations.reduce((acc, location) => {
      acc[location.id || "all"] = location.name;
      return acc;
    }, {});
  }, [locations]);

  // Add this memoized filtered data function
  const filteredReviews = useMemo(() => {
    return reviews.filter(review => {
      const matchesSearch = searchTerm === "" || 
        review.customer_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        review.review.toLowerCase().includes(searchTerm.toLowerCase());
      
      const matchesRating = selectedRating === "" || 
        review.rating.toString() === selectedRating;

      const matchesFlag = selectedFlag === "" || 
        review.flags.some(flag => flag.type === selectedFlag);

      const matchesStatus = selectedStatus === "" || 
        review.status === selectedStatus;

      const matchesLocation = !selectedLocation?.id || 
        selectedLocation.id === "all" || 
        review.location_id === selectedLocation.id;

      return matchesSearch && matchesRating && matchesFlag && matchesStatus && matchesLocation;
    });
  }, [reviews, searchTerm, selectedRating, selectedFlag, selectedStatus, selectedLocation]);

  // Mobile card renderer
  const MobileReviewCard = ({ review }) => (
    <div
      className={`p-4 rounded-lg shadow-sm mb-4 ${
        isDark ? "bg-gray-800" : "bg-white"
      }`}
      onClick={() => handleOpenModal(review)}
    >
      {/* Header */}
      <div className="flex justify-between items-start mb-3">
        <div className="flex items-center space-x-2">
          <User size={16} className="text-gray-400" />
          <span
            className={`font-medium ${
              isDark ? "text-gray-200" : "text-gray-900"
            }`}
          >
            {review.customer_name}
          </span>
        </div>
        <div className="flex items-center space-x-2">
          {/* Flags */}
          {review.flags.length > 0 && (
            <div className="flex space-x-1">
              {review.flags.map((flag) => (
                <span
                  key={flag.type}
                  className={`text-xs px-2 py-1 rounded-full ${
                    isDark ? "bg-gray-700 text-gray-200" : "bg-gray-100 text-gray-700"
                  }`}
                >
                  {flag.type === "staff_mention" ? "Staff👨‍🍳" : "Menu🍽️"}
                </span>
              ))}
            </div>
          )}
          {/* Rating */}
          <div className="flex items-center">
            <Star className="w-4 h-4 text-yellow-400 mr-1" />
            <span className={isDark ? "text-gray-200" : "text-gray-900"}>
              {review.rating}
            </span>
          </div>
        </div>
      </div>

      {/* Review Content */}
      <div className="space-y-2">
        <p className={`text-sm ${isDark ? "text-gray-300" : "text-gray-600"}`}>
          {review.review}
        </p>

        {/* Metadata */}
        <div className="flex flex-wrap gap-2 text-xs text-gray-500 dark:text-gray-400">
          <div className="flex items-center">
            <MapPin size={12} className="mr-1" />
            {locationMap[review.location_id] || "Unknown"}
          </div>
          <div className="flex items-center">
            <Calendar size={12} className="mr-1" />
            {new Date(review.review_date).toLocaleDateString()}
          </div>
        </div>

        {/* Response Section */}
        <div className="mt-3 pt-3 border-t border-gray-200 dark:border-gray-700">
          <div className="flex items-center justify-between">
            <span
              className={`text-sm font-medium ${
                isDark ? "text-gray-200" : "text-gray-900"
              }`}
            >
              Response
            </span>
            {isAdminOrSuperAdmin && (
              <ReviewActionCell review={review} onOpenModal={handleOpenModal} />
            )}
          </div>
          <p
            className={`mt-2 text-sm ${
              review.response
                ? isDark
                  ? "text-gray-300"
                  : "text-gray-600"
                : "italic text-gray-400"
            }`}
          >
            {review.response || "No response yet"}
          </p>
        </div>
      </div>
    </div>
  );

  // Desktop table columns
  const columns = [
    { key: "customer_name", label: "Name", sortable: true, width: "15%" },
    { key: "review", label: "Review", sortable: false, width: "25%" },
    { key: "rating", label: "Rating", sortable: true, width: "8%" },
    { key: "flags", label: "Flag", sortable: false, width: "8%" },
    { key: "location", label: "Location", sortable: true, width: "12%" },
    { key: "review_date", label: "Review Date", sortable: true, width: "12%" },
    { key: "response", label: "Response", sortable: false, width: "15%" },
    ...(isAdminOrSuperAdmin
      ? [{ key: "actions", label: "Actions", sortable: false, width: "5%" }]
      : []),
  ];

  // Custom cell renderers
  const customCellRenderers = {
    flags: (value) => {
      const hasStaffMention = value.some(
        (flag) => flag.type === "staff_mention"
      );
      const hasMenuInsight = value.some((flag) => flag.type === "menu_insight");
      return (
        <>
          {hasStaffMention && "Staff👨‍🍳"}
          {hasStaffMention && hasMenuInsight && <br />}
          {hasMenuInsight && "Menu🍽️"}
        </>
      );
    },
    location: (value, item) => locationMap[item.location_id] || "Unknown",
    response: (value, item) => (
      <div
        onClick={() => handleOpenModal(item)}
        className={`cursor-pointer p-2 rounded transition-colors ${
          isDark ? "hover:bg-gray-700" : "hover:bg-gray-100"
        }`}
      >
        {value || <span className="text-gray-500 italic">No response yet</span>}
      </div>
    ),
    rating: (value) => (
      <div className="flex items-center">
        <span>{value}</span>
        <Star className="w-4 h-4 text-yellow-400 ml-1" />
      </div>
    ),
    actions: (_, item) => (
      <ReviewActionCell review={item} onOpenModal={handleOpenModal} />
    ),
    review_date: (value) => (
      <div className={`flex items-center ${isDark ? "text-gray-300" : "text-gray-600"}`}>
        <Calendar size={16} className="mr-2 text-gray-400" />
        {new Date(value).toLocaleDateString()}
      </div>
    ),
  };

  // Filters
  const filters = [
    {
      key: "rating",
      placeholder: "All Ratings",
      options: [1, 2, 3, 4, 5].map((rating) => ({
        value: rating.toString(),
        label: `${rating} Stars`,
      })),
      filterFunction: (item, value) => item.rating.toString() === value,
    },
    {
      key: "flags",
      placeholder: "All Flags",
      options: [
        { value: "staff_mention", label: "Staff Mention" },
        { value: "menu_insight", label: "Menu Insight" },
      ],
      filterFunction: (item, value) =>
        item.flags.some((flag) => flag.type === value),
    },
    {
      key: "status",
      placeholder: "All Statuses",
      options: REVIEW_STATUS_OPTIONS,
      filterFunction: (item, value) => item.status === value,
    },
  ];

  const handleOpenModal = (review) => {
    setModalData({
      isOpen: true,
      review: {
        ...review,
        customer_name: review.customer_name || "Anonymous",
      },
    });
  };

  const handleRegenerate = async (instructions) => {
    setIsRegenerating(true);
    try {
      // Call your API function here
      const response = await regenerateReviewResponseGPT(instructions);
      return response;
    } catch (error) {
      console.error("Error regenerating response:", error);
    } finally {
      setIsRegenerating(false);
    }
  };

  const handleSaveResponse = async (newResponse) => {
    try {
      // Call your API function here
      await updateReviewResponse(
        modalData.review.location_id,
        modalData.review.id,
        newResponse,
        accessToken
      );
      // Update the local reviews data if needed
    } catch (error) {
      console.error("Error saving response:", error);
    }
  };

  return (
    <div className="space-y-4">
      {/* Date Filter */}
      <div className={`relative z-${zIndexConfig.datePickerDropdown}`}>
        <DateFilter
          dateFilter={dateFilter}
          onDateChange={handleDateChange}
          className="mb-4"
        />
      </div>

      {/* Mobile View */}
      {isMobile ? (
        <div className="space-y-4">
          {/* Mobile Search and Filters */}
          <div className="sticky top-0 bg-gray-100 dark:bg-gray-900 p-4 rounded-lg shadow-sm">
            <div className="relative mb-4">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Search reviews..."
                className={`w-full pl-10 pr-4 py-2 rounded-lg border ${
                  isDark
                    ? "bg-gray-800 border-gray-700 text-white"
                    : "bg-white border-gray-200"
                }`}
              />
            </div>
            <div className="flex gap-2 overflow-x-auto pb-2">
              {/* Rating Filter */}
              <select
                value={selectedRating}
                onChange={(e) => setSelectedRating(e.target.value)}
                className={`px-3 py-2 rounded-lg border ${
                  isDark
                    ? "bg-gray-800 border-gray-700 text-white"
                    : "bg-white border-gray-200"
                }`}
              >
                <option value="">All Ratings</option>
                {[1, 2, 3, 4, 5].map((rating) => (
                  <option key={rating} value={rating.toString()}>
                    {rating} Stars
                  </option>
                ))}
              </select>

              {/* Flag Filter */}
              <select
                value={selectedFlag}
                onChange={(e) => setSelectedFlag(e.target.value)}
                className={`px-3 py-2 rounded-lg border ${
                  isDark
                    ? "bg-gray-800 border-gray-700 text-white"
                    : "bg-white border-gray-200"
                }`}
              >
                <option value="">All Flags</option>
                <option value="staff_mention">Staff Mention</option>
                <option value="menu_insight">Menu Insight</option>
              </select>

              {/* Add status filter */}
              <select
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
                className={`w-36 px-3 py-2 rounded-lg border ${
                  isDark
                    ? "bg-gray-800 border-gray-700 text-white"
                    : "bg-white border-gray-200"
                }`}
              >
                <option value="">All Statuses</option>
                {REVIEW_STATUS_OPTIONS.map(({ value, label }) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* Add no data message for mobile */}
          {filteredReviews.length === 0 ? (
            <div className={`text-center p-8 rounded-lg ${
              isDark ? "bg-gray-800 text-gray-300" : "bg-white text-gray-500"
            }`}>
              <p>No reviews found</p>
              <p className="text-sm mt-2">Try adjusting your search or filters</p>
            </div>
          ) : (
            /* Mobile Reviews List - use filtered data */
            <div className="space-y-4">
              {filteredReviews.map((review) => (
                <MobileReviewCard key={review.id} review={review} />
              ))}
            </div>
          )}

          {/* Update Load More button logic */}
          {reviewsHasMore && !reviewsLoading && filteredReviews.length > 0 && (
            <button
              onClick={loadMoreReviews}
              className={`w-full py-2 px-4 rounded-lg ${
                isDark
                  ? "bg-gray-700 text-gray-200 hover:bg-gray-600"
                  : "bg-gray-100 text-gray-700 hover:bg-gray-200"
              } transition-colors`}
            >
              Load More
            </button>
          )}
        </div>
      ) : (
        /* Desktop Table View */
        <DataTable
          columns={columns}
          data={reviews}
          defaultSortKey="review_date"
          defaultSortDirection="desc"
          filters={filters}
          searchPlaceholder="Search reviews..."
          customCellRenderers={customCellRenderers}
          theme={theme}
          isLoading={reviewsLoading}
          hasMore={reviewsHasMore}
          onLoadMore={loadMoreReviews}
          noDataMessage="No reviews available for this location."
          filterClassName="w-36"
        />
      )}

      {/* Review Response Modal */}
      <ReviewResponseModal
        isOpen={modalData.isOpen}
        onClose={() => setModalData({ isOpen: false, review: null })}
        review={modalData.review}
        onSave={handleSaveResponse}
        onRegenerate={handleRegenerate}
        isRegenerating={isRegenerating}
        setIsRegenerating={setIsRegenerating}
      />
    </div>
  );
};

ReviewsTable.propTypes = {
  reviews: PropTypes.arrayOf(PropTypes.object).isRequired,
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.string,
    })
  ).isRequired,
  reviewsLoading: PropTypes.bool.isRequired,
  reviewsHasMore: PropTypes.bool.isRequired,
  loadMoreReviews: PropTypes.func.isRequired,
};

export default React.memo(ReviewsTable);
