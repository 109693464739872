import React, { useState, useEffect } from "react";
import {
  Mail,
  MapPin,
  FileText,
  ChevronRight,
  ChevronLeft,
  Check,
  Search,
  Star,
} from "lucide-react";
import Confetti from "react-confetti";

// Define the steps for the onboarding flow
const STEPS = {
  BUSINESS_INFO: "BUSINESS_INFO",
  AUTOMATION_ACCESS: "AUTOMATION_ACCESS",
  MENU_UPLOAD: "MENU_UPLOAD",
  TONE_SELECTION: "TONE_SELECTION",
  LOCATION_SELECTION: "LOCATION_SELECTION",
  REVIEW: "REVIEW",
};

// Define tone options for AI responses
const toneOptions = [
  {
    id: "friendly",
    label: "Friendly and Casual",
    example:
      "Thanks a ton for your awesome review! We're thrilled you loved the food and our friendly crew. Can't wait to see you again soon!",
    icon: "😊",
  },
  {
    id: "formal",
    label: "Formal and Professional",
    example:
      "Thank you for taking the time to share your positive experience. We are delighted to hear that you enjoyed both the cuisine and service. We look forward to welcoming you again soon.",
    icon: "👔",
  },
  {
    id: "fun",
    label: "Fun and Lighthearted",
    example:
      "Woohoo! We're so happy you had a great time! Our team was thrilled to serve you, and hope to see you back for more tasty bites and good vibes!",
    icon: "🎉",
  },
  {
    id: "neutral",
    label: "Neutral and Factual",
    example:
      "We appreciate your positive feedback. We are glad that you enjoyed your meal and found the service satisfactory. We hope to serve you again in the future.",
    icon: "📝",
  },
];

// Mock Places API data (hardcoded for demonstration)
const mockLocations = [
  {
    place_id: "1",
    name: "Lucille's Dix30",
    address: "9395 Boul. Leduc, Brossard, QC J4Y 0A5",
    photos: ["https://via.placeholder.com/400x200"],
    rating: 4.5,
    user_ratings_total: 120,
  },
  {
    place_id: "2",
    name: "Lucille's Laval",
    address: "2105 Le Carrefour Blvd, Laval, QC H7S 1Z7",
    photos: ["https://via.placeholder.com/400x200"],
    rating: 4.6,
    user_ratings_total: 98,
  },
  {
    place_id: "3",
    name: "Lucille's Avenue des Canadiens",
    address: "1234 Avenue des Canadiens-de-Montréal, Montreal, QC H3B 5E8",
    photos: ["https://via.placeholder.com/400x200"],
    rating: 4.7,
    user_ratings_total: 150,
  },
];

export default function OnboardingFlow() {
  const [currentStep, setCurrentStep] = useState(STEPS.BUSINESS_INFO);
  const [searchQuery, setSearchQuery] = useState("");
  const [formData, setFormData] = useState({
    businessName: "",
    contactName: "",
    contactEmail: "",
    contactPhone: "",
    automationAccess: false,
    menuFile: null,
    selectedTone: "",
    selectedLocations: [],
  });
  const [showConfetti, setShowConfetti] = useState(false);

  // Limit the confetti animation to a few seconds
  useEffect(() => {
    let timer;
    if (showConfetti) {
      timer = setTimeout(() => {
        setShowConfetti(false);
      }, 5000); // Confetti lasts for 5 seconds
    }
    return () => clearTimeout(timer);
  }, [showConfetti]);

  // Render the content for the current step
  const renderStepContent = () => {
    switch (currentStep) {
      case STEPS.BUSINESS_INFO:
        return (
          <div className="space-y-6">
            <h2 className="text-3xl font-bold text-gray-900">
              Business Information
            </h2>
            <div className="space-y-4">
              {/* Business Name */}
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Business Name
                </label>
                <input
                  type="text"
                  value={formData.businessName}
                  onChange={(e) =>
                    setFormData({ ...formData, businessName: e.target.value })
                  }
                  className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter your business name"
                />
              </div>
              {/* Contact Name */}
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Contact Name
                </label>
                <input
                  type="text"
                  value={formData.contactName}
                  onChange={(e) =>
                    setFormData({ ...formData, contactName: e.target.value })
                  }
                  className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter contact person's name"
                />
              </div>
              {/* Contact Email */}
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Contact Email
                </label>
                <input
                  type="email"
                  value={formData.contactEmail}
                  onChange={(e) =>
                    setFormData({ ...formData, contactEmail: e.target.value })
                  }
                  className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter contact email"
                />
              </div>
              {/* Contact Phone */}
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Contact Phone
                </label>
                <input
                  type="tel"
                  value={formData.contactPhone}
                  onChange={(e) =>
                    setFormData({ ...formData, contactPhone: e.target.value })
                  }
                  className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter contact phone number"
                />
              </div>
            </div>
          </div>
        );

      case STEPS.AUTOMATION_ACCESS:
        return (
          <div className="space-y-6">
            <h2 className="text-3xl font-bold text-gray-900">
              Grant Access to Automation
            </h2>
            <div className="bg-blue-50 border border-blue-200 rounded-lg p-6 space-y-4">
              <h3 className="font-medium text-blue-900">Follow these steps:</h3>
              <ol className="space-y-4 text-blue-800">
                <li className="flex items-start space-x-2">
                  <span className="font-medium">1.</span>
                  <span>
                    Log in to your Google Business Profile at business.google.com
                  </span>
                </li>
                <li className="flex items-start space-x-2">
                  <span className="font-medium">2.</span>
                  <span>
                    Click on Business Profile settings → People and access
                  </span>
                </li>
                <li className="flex items-start space-x-2">
                  <span className="font-medium">3.</span>
                  <span>Click "Add" and enter our automation email:</span>
                </li>
                <div className="flex items-center space-x-2 bg-white p-2 rounded border border-blue-200">
                  <Mail className="text-blue-500" size={20} />
                  <code className="font-mono">automations@robotaize.ca</code>
                </div>
                <li className="flex items-start space-x-2">
                  <span className="font-medium">4.</span>
                  <span>Select "Manager" role and click "Invite"</span>
                </li>
              </ol>
              <div className="mt-6">
                <label className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={formData.automationAccess}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        automationAccess: e.target.checked,
                      })
                    }
                    className="h-4 w-4 text-blue-600 rounded focus:ring-blue-500"
                  />
                  <span>
                    I confirm I've granted access to the automation account
                  </span>
                </label>
              </div>
            </div>
          </div>
        );

      case STEPS.MENU_UPLOAD:
        return (
          <div className="space-y-6">
            <h2 className="text-3xl font-bold text-gray-900">Upload Your Menu</h2>
            <div className="border-2 border-dashed border-gray-300 rounded-lg p-6 hover:bg-gray-50 transition-colors duration-200">
              <div className="flex flex-col items-center space-y-4">
                <FileText className="h-12 w-12 text-gray-400" />
                <div className="text-center">
                  <label className="block">
                    <span className="sr-only">Choose menu file</span>
                    <input
                      type="file"
                      accept=".pdf,.png,.jpg,.jpeg"
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          menuFile: e.target.files[0],
                        })
                      }
                      className="block w-full text-sm text-gray-500
                          file:mr-4 file:py-2 file:px-4
                          file:rounded-full file:border-0
                          file:text-sm file:font-semibold
                          file:bg-blue-50 file:text-blue-700
                          hover:file:bg-blue-100"
                    />
                  </label>
                  <p className="text-xs text-gray-500 mt-2">
                    PDF or PNG up to 10MB
                  </p>
                </div>
              </div>
            </div>
            {formData.menuFile && (
              <div className="flex items-center space-x-2 text-sm text-green-600">
                <Check size={16} />
                <span>{formData.menuFile.name} uploaded</span>
              </div>
            )}
          </div>
        );

      case STEPS.TONE_SELECTION:
        return (
          <div className="space-y-6">
            <h2 className="text-3xl font-bold text-gray-900">
              Select Response Tone
            </h2>
            <p className="text-gray-600">
              Choose how you'd like our AI to respond to your customers
            </p>
            <div className="grid gap-4">
              {toneOptions.map((tone) => (
                <label
                  key={tone.id}
                  className={`
                      relative block p-4 rounded-lg border-2 cursor-pointer
                      transition-all duration-200
                      ${
                        formData.selectedTone === tone.id
                          ? "border-blue-500 bg-blue-50"
                          : "border-gray-200 hover:border-blue-200"
                      }
                    `}
                >
                  <input
                    type="radio"
                    name="tone"
                    value={tone.id}
                    checked={formData.selectedTone === tone.id}
                    onChange={(e) =>
                      setFormData({ ...formData, selectedTone: e.target.value })
                    }
                    className="sr-only"
                  />
                  <div className="flex items-start space-x-4">
                    <span className="text-2xl">{tone.icon}</span>
                    <div>
                      <div className="font-medium">{tone.label}</div>
                      <p className="text-sm text-gray-600 mt-1">
                        {tone.example}
                      </p>
                    </div>
                  </div>
                </label>
              ))}
            </div>
          </div>
        );

      case STEPS.LOCATION_SELECTION:
        const filteredLocations =
          searchQuery.toLowerCase() === "lucille's" ? mockLocations : [];
        return (
          <div className="space-y-6">
            <h2 className="text-3xl font-bold text-gray-900">
              Select Your Locations
            </h2>
            <div className="relative">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              <input
                type="text"
                placeholder="Search your business locations..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full pl-10 pr-4 py-3 rounded-lg border border-gray-200 focus:ring-2 focus:ring-blue-500"
              />
            </div>
            {filteredLocations.length > 0 ? (
              <div className="grid gap-4">
                {filteredLocations.map((location) => (
                  <label
                    key={location.place_id}
                    className={`
                        relative block rounded-lg border-2 overflow-hidden cursor-pointer
                        transition-all duration-200
                        ${
                          formData.selectedLocations.includes(location.place_id)
                            ? "border-blue-500 bg-blue-50"
                            : "border-gray-200 hover:border-blue-200"
                        }
                      `}
                  >
                    <input
                      type="checkbox"
                      checked={formData.selectedLocations.includes(
                        location.place_id
                      )}
                      onChange={(e) => {
                        const newLocations = e.target.checked
                          ? [...formData.selectedLocations, location.place_id]
                          : formData.selectedLocations.filter(
                              (id) => id !== location.place_id
                            );
                        setFormData({
                          ...formData,
                          selectedLocations: newLocations,
                        });
                      }}
                      className="sr-only"
                    />
                    <div className="flex">
                      <img
                        src={location.photos[0]}
                        alt={location.name}
                        className="w-32 h-32 object-cover"
                      />
                      <div className="p-4 flex-1">
                        <div className="font-medium text-lg">{location.name}</div>
                        <div className="text-sm text-gray-600 mt-1">
                          <div className="flex items-center space-x-1">
                            <MapPin size={14} />
                            <span>{location.address}</span>
                          </div>
                          <div className="flex items-center space-x-1 mt-1">
                            <Star size={14} className="text-yellow-500" />
                            <span>
                              {location.rating} (
                              {location.user_ratings_total} reviews)
                            </span>
                          </div>
                        </div>
                      </div>
                      {formData.selectedLocations.includes(location.place_id) && (
                        <div className="absolute top-2 right-2 bg-blue-500 text-white p-1 rounded-full">
                          <Check size={16} />
                        </div>
                      )}
                    </div>
                  </label>
                ))}
              </div>
            ) : (
              <p className="text-gray-500 mt-4">
                {searchQuery
                  ? "No locations found. Please try searching for 'Lucille's'."
                  : "Type your business name to search for locations."}
              </p>
            )}
          </div>
        );

      case STEPS.REVIEW:
        return (
          <div className="space-y-6">
            <h2 className="text-3xl font-bold text-gray-900">Review Your Setup</h2>
            <div className="space-y-6">
              {/* Business Information */}
              <div className="bg-gray-50 rounded-lg p-4">
                <h3 className="font-medium mb-2">Business Information</h3>
                <dl className="grid grid-cols-2 gap-2 text-sm">
                  <dt className="text-gray-600">Business Name:</dt>
                  <dd>{formData.businessName}</dd>
                  <dt className="text-gray-600">Contact:</dt>
                  <dd>{formData.contactName}</dd>
                  <dt className="text-gray-600">Email:</dt>
                  <dd>{formData.contactEmail}</dd>
                  <dt className="text-gray-600">Phone:</dt>
                  <dd>{formData.contactPhone}</dd>
                </dl>
              </div>
              {/* Selected Locations */}
              <div className="bg-gray-50 rounded-lg p-4">
                <h3 className="font-medium mb-2">Selected Locations</h3>
                <ul className="space-y-2">
                  {mockLocations
                    .filter((loc) =>
                      formData.selectedLocations.includes(loc.place_id)
                    )
                    .map((location) => (
                      <li
                        key={location.place_id}
                        className="flex items-center space-x-2"
                      >
                        <MapPin size={16} className="text-gray-400" />
                        <span>{location.name}</span>
                      </li>
                    ))}
                </ul>
              </div>
              {/* Response Tone */}
              <div className="bg-gray-50 rounded-lg p-4">
                <h3 className="font-medium mb-2">Response Tone</h3>
                <p>
                  {
                    toneOptions.find((t) => t.id === formData.selectedTone)?.label
                  }
                </p>
              </div>
              {/* Menu File */}
              <div className="bg-gray-50 rounded-lg p-4">
                <h3 className="font-medium mb-2">Menu File</h3>
                <p>{formData.menuFile?.name || "No file uploaded"}</p>
              </div>
              {/* Automation Access Confirmation */}
              {formData.automationAccess && (
                <div className="bg-green-50 border border-green-200 rounded-lg p-4">
                  <div className="flex items-center space-x-2 text-green-800">
                    <Check size={20} />
                    <p>Automation access confirmed</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  // Determine if the user can proceed to the next step
  const canProceed = () => {
    switch (currentStep) {
      case STEPS.BUSINESS_INFO:
        return formData.businessName && formData.contactEmail;
      case STEPS.AUTOMATION_ACCESS:
        return formData.automationAccess;
      case STEPS.MENU_UPLOAD:
        return formData.menuFile;
      case STEPS.TONE_SELECTION:
        return formData.selectedTone;
      case STEPS.LOCATION_SELECTION:
        return formData.selectedLocations.length > 0;
      case STEPS.REVIEW:
        return true;
      default:
        return false;
    }
  };

  // Get the next step in the onboarding flow
  const getNextStep = (currentStep) => {
    const steps = Object.values(STEPS);
    const currentIndex = steps.indexOf(currentStep);
    return steps[currentIndex + 1];
  };

  // Get the previous step in the onboarding flow
  const getPrevStep = (currentStep) => {
    const steps = Object.values(STEPS);
    const currentIndex = steps.indexOf(currentStep);
    return steps[currentIndex - 1];
  };

  // Handle proceeding to the next step
  const handleNext = () => {
    const nextStep = getNextStep(currentStep);
    if (nextStep) {
      setCurrentStep(nextStep);
    } else {
      // Handle completion
      console.log("Onboarding complete!", formData);
      setShowConfetti(true);
    }
  };

  // Handle going back to the previous step
  const handleBack = () => {
    const prevStep = getPrevStep(currentStep);
    if (prevStep) {
      setCurrentStep(prevStep);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex items-center justify-center p-4">
      {showConfetti && (
        <Confetti
          numberOfPieces={200}
          recycle={false}
          colors={["#2563EB", "#3B82F6", "#60A5FA", "#93C5FD"]}
        />
      )}
      <div className="w-full max-w-3xl bg-white rounded-xl shadow-lg p-8">
        {/* Progress Bar */}
        <div className="mb-8">
          <div className="flex justify-between mb-4">
            {Object.values(STEPS).map((step, index) => (
              <div key={step} className="text-center flex-1">
                <div
                  className={`
                      w-10 h-10 mx-auto rounded-full flex items-center justify-center text-lg font-bold
                      ${
                        Object.values(STEPS).indexOf(currentStep) >= index
                          ? "bg-blue-600 text-white"
                          : "bg-gray-200 text-gray-600"
                      }
                    `}
                >
                  {index + 1}
                </div>
                <div
                  className={`text-sm mt-2 ${
                    Object.values(STEPS).indexOf(currentStep) >= index
                      ? "text-blue-600 font-semibold"
                      : "text-gray-600"
                  }`}
                >
                  {step.replace("_", " ")}
                </div>
              </div>
            ))}
          </div>
          <div className="relative h-2 bg-gray-200 rounded-full">
            <div
              className="absolute h-full bg-blue-600 rounded-full transition-all duration-300"
              style={{
                width: `${
                  ((Object.values(STEPS).indexOf(currentStep) + 1) /
                    Object.values(STEPS).length) *
                  100
                }%`,
              }}
            />
          </div>
        </div>

        {/* Step Content */}
        <div className="mb-8">{renderStepContent()}</div>

        {/* Navigation */}
        <div className="flex justify-between">
          <button
            onClick={handleBack}
            className={`
                bg-white text-gray-600 px-6 py-3 rounded-lg border-2 border-gray-200 hover:border-blue-600 transition-all flex items-center
                ${
                  currentStep === STEPS.BUSINESS_INFO
                    ? "invisible"
                    : "hover:shadow-md"
                }
              `}
          >
            <ChevronLeft size={20} />
            <span>Back</span>
          </button>

          <button
            onClick={handleNext}
            disabled={!canProceed()}
            className={`
                bg-blue-600 text-white px-8 py-3 rounded-lg hover:bg-blue-700 transition-all transform hover:scale-105 hover:shadow-lg flex items-center
                ${
                  !canProceed() &&
                  "bg-gray-200 text-gray-400 cursor-not-allowed hover:scale-100 hover:shadow-none"
                }
              `}
          >
            <span>{currentStep === STEPS.REVIEW ? "Complete" : "Next"}</span>
            <ChevronRight size={20} />
          </button>
        </div>
      </div>
    </div>
  );
}
