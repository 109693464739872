import React from "react";
import { Route, Routes } from "react-router-dom";
import DashboardCharts from "../../graphs/components/DashboardCharts";
import ReviewsTable from "../../reviews/components/ReviewsTable";
import StaffMentionsTable from "../../staff/components/StaffMentionsTable";
import MenuItemsTable from "../../menu/components/MenuItemsTable";
import Trend from "../../trend/components/Trend";
import Reports from "../../reports/components/Reports";
import Administration from "../../administration/component/Administration";
import Reservations from "../../reservations/components/Reservations";
import Loading from "../../../components/common/Loading";
import PropTypes from "prop-types";

const DashboardRoutes = ({
  userRole,
  selectedLocation,
  trendData,
  processedData,
  filteredReviews,
  reviewsLoading,
  reviewsHasMore,
  loadMoreReviews,
  loadMoreStaffMentions,
  menuItems,
  menuLoading,
  menuHasMore,
  loadMoreMenuItems,
  locations,
  selectedRestaurant,
  dateFilter,
  handleDateChange,
}) => {
  // Helper function to render section with loading state
  const renderSection = ({
    title,
    loading,
    children,
    type = "default",
    error = null,
  }) => (
    <div className="space-y-4">
      <Loading.ContentLoader loading={loading} type={type}>
        {error ? (
          <div className="p-4 bg-red-100 dark:bg-red-900/50 rounded-lg">
            <p className="text-red-700 dark:text-red-200">{error}</p>
          </div>
        ) : (
          children
        )}
      </Loading.ContentLoader>
    </div>
  );

  return (
    <Routes>
      {/* SuperAdmin, Admin, and Manager Routes */}
      {(userRole === "SuperAdmin" ||
        userRole === "Admin" ||
        userRole === "Manager") && (
        <>
          <Route
            path="trends"
            element={renderSection({
              title: "Trend",
              loading: reviewsLoading,
              type: "dashboard",
              children: <Trend data={trendData} />,
            })}
          />
          <Route
            path="graphs"
            element={renderSection({
              title:
                selectedLocation?.name === "All"
                  ? "Global Dashboard"
                  : "Dashboard",
              loading: false,
              type: "dashboard",
              children: (
                <DashboardCharts
                  data={processedData}
                  selectedLocation={selectedLocation}
                  locations={locations}
                />
              ),
            })}
          />

          <Route
            path="reviews"
            element={renderSection({
              title: "Review Management",
              loading: reviewsLoading,
              type: "table",
              children: (
                <ReviewsTable
                  reviews={filteredReviews}
                  locations={locations}
                  reviewsLoading={reviewsLoading}
                  reviewsHasMore={reviewsHasMore}
                  loadMoreReviews={loadMoreReviews}
                />
              ),
            })}
          />

          <Route
            path="staff"
            element={renderSection({
              title: "Staff Management",
              type: "table",
              children: (
                <StaffMentionsTable onLoadMore={loadMoreStaffMentions} />
              ),
            })}
          />
        </>
      )}

      {/* SuperAdmin, Admin, and BOH Routes */}
      {(userRole === "SuperAdmin" ||
        userRole === "Admin" ||
        userRole === "BOH") && (
        <>
          <Route
            path="menu"
            element={renderSection({
              title: "Menu Management",
              loading: menuLoading,
              type: "table",
              children: (
                <MenuItemsTable
                  menuItems={menuItems}
                  locations={locations}
                  menuLoading={menuLoading}
                  menuHasMore={menuHasMore}
                  loadMoreMenuItems={loadMoreMenuItems}
                />
              ),
            })}
          />

          <Route
            path="reports"
            element={renderSection({
              title: "Reports",
              loading: false,
              children: <Reports />,
            })}
          />
        </>
      )}

      {/* SuperAdmin and Admin Only Routes */}
      {(userRole === "SuperAdmin" || userRole === "Admin") && (
        <>
          <Route
            path="reservations"
            element={renderSection({
              title: "Reservations",
              loading: false,
              children: <Reservations />,
            })}
          />

          <Route
            path="administration"
            element={renderSection({
              title: "Administration",
              loading: false,
              children: (
                <Administration
                  selectedLocation={selectedLocation}
                  selectedRestaurant={selectedRestaurant}
                />
              ),
            })}
          />

          {/* <Route
            path="integrations"
            element={renderSection({
              title: "Integrations",
              loading: false,
              children: (
                <Administration
                  selectedLocation={selectedLocation}
                  selectedRestaurant={selectedRestaurant}
                />
              )
            })}
          /> */}
        </>
      )}
    </Routes>
  );
};

// PropTypes for type checking
DashboardRoutes.propTypes = {
  userRole: PropTypes.string.isRequired,
  selectedLocation: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  trendData: PropTypes.array.isRequired,
  processedData: PropTypes.array.isRequired,
  filteredReviews: PropTypes.array.isRequired,
  reviewsLoading: PropTypes.bool.isRequired,
  reviewsHasMore: PropTypes.bool.isRequired,
  loadMoreReviews: PropTypes.func.isRequired,
  loadMoreStaffMentions: PropTypes.func.isRequired,
  menuItems: PropTypes.array.isRequired,
  menuLoading: PropTypes.bool.isRequired,
  menuHasMore: PropTypes.bool.isRequired,
  loadMoreMenuItems: PropTypes.func.isRequired,
  locations: PropTypes.array.isRequired,
  selectedRestaurant: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  dateFilter: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }).isRequired,
  handleDateChange: PropTypes.func.isRequired,
};

export default DashboardRoutes;
