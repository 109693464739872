import { useState, useCallback, useContext, useEffect } from 'react';
import { AuthContext } from '../../../App';
import useUserManagement from './useUserManagement';

export const useAdministration = () => {
  const { accessToken, authorizedLocations } = useContext(AuthContext);
  const {
    users,
    roles,
    loading,
    error,
    deleteSuccess,
    fetchUsers,
    fetchRoles,
    addUser,
    handleDeleteUser,
  } = useUserManagement(accessToken);

  const [deleteConfirmation, setDeleteConfirmation] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [addUserError, setAddUserError] = useState(null);
  const [addUserSuccess, setAddUserSuccess] = useState(false);

  useEffect(() => {
    if (accessToken) {
      fetchUsers();
      fetchRoles();
    }
  }, [accessToken]);

  const handleAddUser = useCallback(
    async (userData) => {
      try {
        setAddUserError(null);
        setAddUserSuccess(false);
  
        if (
          !userData.username ||
          !userData.email ||
          !userData.firstname ||
          !userData.lastname ||
          !userData.roles.length ||
          !userData.location_id
        ) {
          throw new Error('Please fill all required fields and select a location.');
        }
  
        // Create a properly formatted user object
        const formattedUserData = {
          location_id: userData.location_id,
          email: userData.email,
          username: userData.username,
          firstname: userData.firstname,
          lastname: userData.lastname,
          roles: userData.roles // Keep the roles array as is
        };
  
        await addUser(formattedUserData);
        setAddUserSuccess(true);
        setTimeout(() => setAddUserSuccess(false), 3000);
        return true;
      } catch (error) {
        setAddUserError(error.message || 'Failed to add user. Please try again.');
        return false;
      }
    },
    [addUser]
  );

  const filteredUsers = useCallback(() => {
    return users.filter(
      (user) =>
        user.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.email.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [users, searchTerm]);

  return {
    users: filteredUsers(),
    roles,
    loading,
    error,
    deleteSuccess,
    deleteConfirmation,
    addUserError,
    addUserSuccess,
    searchTerm,
    setSearchTerm,
    handleAddUser,
    handleDeleteUser: useCallback(
      (userId) => {
        handleDeleteUser(userId);
        setDeleteConfirmation(null);
      },
      [handleDeleteUser]
    ),
    setDeleteConfirmation,
    authorizedLocations, // Pass this to components if needed
  };
};
