import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ThemeContext } from "../../../contexts/ThemeContext";
import {
  selectLocations,
  selectSelectedLocation,
  setSelectedLocation,
} from "../../../store/slices/locationSlice";
import { locationChanged } from "../../../store/slices/dashboardSlice";
import {
  ChevronDown,
  MessageSquare,
  TrendingUp,
  Users,
  Calendar,
  BarChart2,
  MapPin,
  LogOut,
  Sun,
  Moon,
  Clock,
  Settings,
} from "lucide-react";
import { useAuth } from "../../auth/hooks/useAuth";
import PropTypes from 'prop-types';

const Logo = ({ className = "" }) => (
  <svg className={`h-7 w-7 ${className}`} viewBox="0 0 128 128">
    <defs>
      <linearGradient
        id="sidebarLogoGradient"
        x1="0%"
        y1="0%"
        x2="100%"
        y2="100%"
      >
        <stop offset="0%" stopColor="#3B82F6" />
        <stop offset="100%" stopColor="#2563EB" />
      </linearGradient>
    </defs>
    <path
      fill="url(#sidebarLogoGradient)"
      d="M108 28H20c-6.627 0-12 5.373-12 12v48c0 6.627 5.373 12 12 12h28l12 16 12-16h36c6.627 0 12-5.373 12-12V40c0-6.627-5.373-12-12-12zm-4 52c0 2.21-1.79 4-4 4H28c-2.21 0-4-1.79-4-4V48c0-2.21 1.79-4 4-4h72c2.21 0 4 1.79 4 4v32z"
    />
    <path fill="#fff" d="M36 52h56v4H36zm0 12h40v4H36z" />
  </svg>
);

const DashboardIntegratedSidebar = ({ navigationItems = [] }) => {
  const dispatch = useDispatch();
  const { theme, toggleTheme } = useContext(ThemeContext);
  const { logout, selectedRestaurant, userRole } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const isDark = theme === "dark";
  const [isLocationExpanded, setIsLocationExpanded] = useState(false);

  // Redux selectors
  const locations = useSelector(selectLocations);
  const selectedLocation = useSelector(selectSelectedLocation);

  const handleLocationSelect = async (loc) => {
    if (selectedLocation?.id === loc.id) return;

    // Dispatch both actions
    await dispatch(setSelectedLocation(loc));
    await dispatch(locationChanged(loc?.id || "all"));
  };

  const handleLogout = async () => {
    await logout();
    navigate("/");
  };

  const LocationSection = () => (
    <div className="py-2 px-4">
      <button
        onClick={() => setIsLocationExpanded(!isLocationExpanded)}
        className="flex items-center justify-between w-full mb-2 group"
      >
        <div className="flex items-center space-x-2">
          <MapPin size={16} className="text-gray-400" />
          <span className="text-sm font-medium text-gray-600 dark:text-gray-400">
            Location:
            <span className="ml-1 text-gray-900 dark:text-gray-100">
              {selectedLocation?.name || "All"}
            </span>
          </span>
        </div>
        <ChevronDown
          size={16}
          className={`text-gray-400 transition-transform duration-200 ${
            isLocationExpanded ? "" : "-rotate-90"
          }`}
        />
      </button>
      <div
        className={`
          mt-1 space-y-1 overflow-hidden transition-all duration-200
          ${isLocationExpanded ? "max-h-96" : "max-h-0"}
        `}
      >
        {locations.map((loc) => (
          <button
            key={loc.id || "all"}
            onClick={() => handleLocationSelect(loc)}
            className={`
              w-full px-3 py-2 text-left rounded-md text-sm
              transition-colors duration-200
              ${
                selectedLocation?.id === loc.id
                  ? "bg-blue-50 text-blue-600 dark:bg-blue-900/50 dark:text-blue-400"
                  : "text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800"
              }
            `}
          >
            {loc.name}
          </button>
        ))}
      </div>
    </div>
  );

  const NavLink = ({ to, label, description, icon: Icon }) => {
    const isActive = location.pathname === to;

    return (
      <Link
        to={to}
        className={`
          flex items-center px-4 py-2 text-sm
          transition-colors duration-200
          ${
            isActive
              ? "text-blue-600 dark:text-blue-400"
              : "text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-200"
          }
        `}
      >
        <Icon size={20} className="flex-shrink-0" />
        <div className="ml-3">
          <div className="font-medium">{label}</div>
          <div className="text-xs text-gray-500 dark:text-gray-400">
            {description}
          </div>
        </div>
      </Link>
    );
  };

  return (
    <div
      className={`w-64 h-full flex flex-col border-r ${
        isDark ? "bg-gray-900 border-gray-800" : "bg-white border-gray-200"
      }`}
    >
      {/* Brand Header */}
      <div className="px-4 py-4 border-b border-gray-200 dark:border-gray-800">
        <div className="flex items-center space-x-3">
          <Logo />
          <div className="flex flex-col justify-center">
            <span
              className={`text-lg font-bold leading-tight ${
                isDark ? "text-white" : "text-gray-900"
              }`}
            >
              FeedBacked
            </span>
            {selectedRestaurant?.name && (
              <span
                className={`text-sm leading-tight ${
                  isDark ? "text-gray-400" : "text-gray-500"
                }`}
              >
                {selectedRestaurant.name}
              </span>
            )}
          </div>
        </div>
      </div>

      <div className="flex-1 overflow-y-auto">
        <LocationSection />

        <div className="mt-4">
          {navigationItems.map((link) => (
            <NavLink
              key={link.path} // Changed from link.to to link.path to match navigationConfig
              to={link.path} // Changed from link.to to link.path
              label={link.label}
              description={link.description}
              icon={link.icon}
            />
          ))}
        </div>
      </div>

      {/* User Controls Section */}
      <div
        className={`
        mt-auto p-4 border-t
        ${isDark ? "border-gray-800" : "border-gray-200"}
      `}
      >
        <div className="flex items-center justify-between">
          <button
            onClick={toggleTheme}
            className={`
              p-2 rounded-lg transition-colors
              ${
                isDark
                  ? "text-yellow-300 hover:bg-gray-800"
                  : "text-gray-700 hover:bg-gray-100"
              }
            `}
            aria-label="Toggle theme"
          >
            {isDark ? <Sun size={20} /> : <Moon size={20} />}
          </button>

          <button
            onClick={handleLogout}
            className={`
              flex items-center gap-2 px-4 py-2 rounded-lg
              transition-colors font-medium text-sm
              ${
                isDark
                  ? "text-gray-300 hover:text-white hover:bg-gray-800"
                  : "text-gray-700 hover:text-black hover:bg-gray-100"
              }
            `}
          >
            <LogOut size={18} />
            Log out
          </button>
        </div>
      </div>
    </div>
  );
};

DashboardIntegratedSidebar.propTypes = {
  navigationItems: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      icon: PropTypes.elementType.isRequired,
    })
  )
};

DashboardIntegratedSidebar.defaultProps = {
  navigationItems: []
};

// Helper function to get icon component
const getIconComponent = (label) => {
  const props = { size: 20, className: "flex-shrink-0" };
  switch (label.toLowerCase()) {
    case "trends":
      return <TrendingUp {...props} />;
    case "reviews":
      return <MessageSquare {...props} />;
    case "analytics":
      return <BarChart2 {...props} />;
    case "staff":
      return <Users {...props} />;
    case "reservations":
      return <Calendar {...props} />;
    case "administration":
      return <Settings {...props} />;
    default:
      return <Clock {...props} />;
  }
};

export default DashboardIntegratedSidebar;
