import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { 
  selectAuth, 
  selectAuthLoading, 
  selectIsAuthenticated 
} from '../../../store/slices/authSlice';
import { DateFilterProvider } from '../../../contexts/DateFilterContext';
import DashboardContent from './DashboardContent';
import Loading from '../../../components/common/Loading';
import { 
  fetchLocations,
  selectLocationLoading,
  selectLocationsInitialized 
} from '../../../store/slices/locationSlice';

const DashboardClient = () => {
  const dispatch = useDispatch();
  
  // Auth selectors
  const { 
    userRole, 
    selectedRestaurant, 
    accessToken, 
    authInitialized 
  } = useSelector(selectAuth);
  const isAuthLoading = useSelector(selectAuthLoading);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  
  // Location selectors
  const isLocationLoading = useSelector(selectLocationLoading);
  const locationsInitialized = useSelector(selectLocationsInitialized);

  // Fetch locations when component mounts and auth is ready
  useEffect(() => {
    if (isAuthenticated && accessToken && !locationsInitialized) {
      dispatch(fetchLocations());
    }
  }, [dispatch, isAuthenticated, accessToken, locationsInitialized]);

  // Combined loading state
  const isLoading = isAuthLoading || isLocationLoading;

  // Check all required data
  const canRenderDashboard = !isLoading && 
                            isAuthenticated && 
                            authInitialized &&
                            locationsInitialized &&
                            userRole && 
                            selectedRestaurant &&
                            accessToken;

  if (!canRenderDashboard) {
    return <Loading.Spinner fullScreen />;
  }

  return (
    <DateFilterProvider>
      <DashboardContent
        userRole={userRole}
        selectedRestaurant={selectedRestaurant}
      />
    </DateFilterProvider>
  );
};

export default DashboardClient;