import React, { useState, useEffect, useContext } from "react";
import { ThemeContext } from "../../../contexts/ThemeContext";
import {
  fetchLibroReservations,
  fetchLibroBookingDetails,
  testLibroAuthentication,
} from "../../../services/api/apiUtility";
import {
    Search,
    Filter,
    ArrowUpDown,
    Users,
    Calendar,
    User,
    MessageSquare,
  } from "lucide-react";
  import { X } from "lucide-react";
  import useMediaQuery from '../../../hooks/useMediaQuery';

const Reservations = () => {
  const [reservations, setReservations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState("time");
  const [filterStatus, setFilterStatus] = useState("all");
  const [startDate, setStartDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [endDate, setEndDate] = useState(
    new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString().split("T")[0]
  );
  const [page, setPage] = useState(0);
  const { theme } = useContext(ThemeContext);
  const [selectedReservation, setSelectedReservation] = useState(null);
  const [editedNote, setEditedNote] = useState("");
  const isMobile = useMediaQuery("(max-width: 768px)");

  useEffect(() => {
    const testAuth = async () => {
      try {
        await testLibroAuthentication();
        console.log("Authentication successful");
        loadReservations();
      } catch (error) {
        console.error("Authentication failed:", error);
        setError("Authentication failed. Please check your credentials.");
      }
    };
    testAuth();
  }, []); 

  const openModal = (reservation) => {
    setSelectedReservation(reservation);
    setEditedNote(reservation.attributes.note || "");
  };
  
  const closeModal = () => {
    setSelectedReservation(null);
    setEditedNote("");
  };

  const saveNote = () => {
    setReservations(reservations.map(res => 
      res.id === selectedReservation.id 
        ? { ...res, attributes: { ...res.attributes, note: editedNote } } 
        : res
    ));
    closeModal();
  };

  const loadReservations = async () => {
    try {
      setLoading(true);
      const response = await fetchLibroReservations({
        "started-after": `${startDate}T00:00:00`,
        "started-before": `${endDate}T23:59:59`,
        limit: 50,
        offset: page * 50,
      });
      if (response && response.data) {
        const reservationsWithDetails = await Promise.all(
          response.data.map(async (reservation) => {
            try {
              const bookingDetails = await fetchLibroBookingDetails(
                reservation.id
              );
              return { ...reservation, details: bookingDetails };
            } catch (error) {
              console.error(
                `Error fetching details for reservation ${reservation.id}:`,
                error
              );
              return reservation;
            }
          })
        );
        setReservations((prevReservations) => {
          const newReservations = [...prevReservations];
          reservationsWithDetails.forEach((reservation) => {
            const index = newReservations.findIndex(r => r.id === reservation.id);
            if (index === -1) {
              newReservations.push(reservation);
            } else {
              newReservations[index] = reservation;
            }
          });
          return newReservations;
        });
      } else {
        throw new Error("No reservation data received");
      }
    } catch (err) {
      console.error("Error loading reservations:", err);
      setError(err.message || "Failed to load reservations. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setReservations([]);
    setPage(0);
    loadReservations();
  }, [startDate, endDate]);

  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const filteredReservations = reservations
  .filter((reservation) => {
    const personName = reservation.details && reservation.details.included
      ? (reservation.details.included.find(item => item.type === "person")?.attributes["first-name"] + " " +
         reservation.details.included.find(item => item.type === "person")?.attributes["last-name"]).toLowerCase()
      : "";
    
    return (
      (filterStatus === "all" || reservation.attributes.status === filterStatus) &&
      (searchTerm === "" ||
        reservation.attributes["table-number"]?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        reservation.attributes.status.toLowerCase().includes(searchTerm.toLowerCase()) ||
        personName.includes(searchTerm.toLowerCase()))
    );
  })
  .sort((a, b) => {
    if (sortBy === "time") {
      return new Date(b.attributes.time) - new Date(a.attributes.time);
    } else if (sortBy === "size") {
      return b.attributes.size - a.attributes.size;
    }
    return 0;
  });

  const isDark = theme === "dark";

  return (
    <div
      className={`min-h-screen p-4 md:p-8 ${
        isDark ? "bg-gray-900 text-white" : "bg-gray-100 text-gray-900"
      }`}
    >
  
      <div className="mb-6 flex flex-col md:flex-row gap-4">
        <div className="w-full md:w-auto md:flex-grow">
          <div
            className={`relative flex items-center ${
              isDark ? "bg-gray-800" : "bg-white"
            } rounded-lg shadow-md`}
          >
            <Search className="absolute left-3 text-gray-400" size={20} />
            <input
              type="text"
              placeholder="Search reservations..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className={`w-full pl-10 pr-4 py-3 rounded-lg focus:outline-none ${
                isDark ? "bg-gray-800 text-white" : "bg-white text-gray-900"
              }`}
            />
          </div>
        </div>
        <div className="flex items-center gap-2">
          <Filter size={20} className="text-gray-400" />
          <select
            value={filterStatus}
            onChange={(e) => setFilterStatus(e.target.value)}
            className={`w-full md:w-auto p-3 rounded-lg ${
              isDark ? "bg-gray-800 text-white" : "bg-white text-gray-900"
            } border ${isDark ? "border-gray-700" : "border-gray-300"}`}
          >
            <option value="all">All Statuses</option>
            <option value="seated">Seated</option>
            <option value="completed">Completed</option>
            <option value="canceled">Canceled</option>
          </select>
        </div>
        <div className="flex items-center gap-2">
          <ArrowUpDown size={20} className="text-gray-400" />
          <select
            value={sortBy}
            onChange={(e) => setSortBy(e.target.value)}
            className={`w-full md:w-auto p-3 rounded-lg ${
              isDark ? "bg-gray-800 text-white" : "bg-white text-gray-900"
            } border ${isDark ? "border-gray-700" : "border-gray-300"}`}
          >
            <option value="time">Sort by Time</option>
            <option value="size">Sort by Party Size</option>
          </select>
        </div>
        <div className="flex items-center gap-2 flex-wrap">
          <Calendar size={20} className="text-gray-400" />
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className={`w-full md:w-auto p-3 rounded-lg ${
              isDark ? "bg-gray-800 text-white" : "bg-white text-gray-900"
            } border ${isDark ? "border-gray-700" : "border-gray-300"}`}
          />
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className={`w-full md:w-auto p-3 rounded-lg ${
              isDark ? "bg-gray-800 text-white" : "bg-white text-gray-900"
            } border ${isDark ? "border-gray-700" : "border-gray-300"}`}
          />
        </div>
      </div>
  
      {loading && <p className="text-center py-4">Loading reservations...</p>}
      {error && <p className="text-center text-red-500 py-4">{error}</p>}
  
      {isMobile ? (
        <div className="space-y-4">
          {filteredReservations.map((reservation) => (
            <MobileReservationCard
              key={reservation.id}
              reservation={reservation}
              openModal={openModal}
            />
          ))}
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredReservations.map((reservation) => {
            const personName =
              reservation.details && reservation.details.included
                ? reservation.details.included.find(
                    (item) => item.type === "person"
                  )?.attributes["first-name"] +
                  " " +
                  reservation.details.included.find(
                    (item) => item.type === "person"
                  )?.attributes["last-name"]
                : "Unknown";
            return (
              <div
                key={reservation.id}
                className={`p-6 rounded-lg shadow-md ${
                  isDark ? "bg-gray-800" : "bg-white"
                } cursor-pointer ${
                  isDark
                    ? "hover:bg-gray-700"
                    : "hover:bg-gray-100"
                } transition-colors duration-200`}
                onClick={() => openModal(reservation)}
              >
                <div className="flex justify-between items-center mb-4">
                  <span
                    className={`text-lg font-semibold ${getStatusColor(
                      reservation.attributes.status,
                      isDark
                    )}`}
                  >
                    {reservation.attributes.status.charAt(0).toUpperCase() +
                      reservation.attributes.status.slice(1)}
                  </span>
                  <span
                    className={`px-3 py-1 rounded-full text-sm ${
                      isDark
                        ? "bg-blue-900 text-blue-200"
                        : "bg-blue-100 text-blue-800"
                    }`}
                  >
                    Table {reservation.attributes["table-number"]}
                  </span>
                </div>
                <div className="flex items-center mb-2">
                  <User size={18} className="mr-2 text-gray-400" />
                  <span>{personName}</span>
                </div>
                <div className="flex items-center mb-2">
                  <Users size={18} className="mr-2 text-gray-400" />
                  <span>Party of {reservation.attributes.size}</span>
                </div>
                <div className="flex items-center mb-2">
                  <Calendar size={18} className="mr-2 text-gray-400" />
                  <span>{formatDate(reservation.attributes.time)}</span>
                </div>
                <div className="flex items-center">
                  <MessageSquare size={18} className="mr-2 text-gray-400" />
                  <span>
                    {reservation.attributes.note
                      ? reservation.attributes.note
                      : "No note provided"}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      )}
  
      {filteredReservations.length === 50 && (
        <div className="mt-6 text-center">
          <button
            onClick={() => setPage((prevPage) => prevPage + 1)}
            className={`px-4 py-3 rounded-lg ${
              isDark
                ? "bg-blue-600 hover:bg-blue-700"
                : "bg-blue-500 hover:bg-blue-600"
            } text-white w-full md:w-auto`}
          >
            Load More
          </button>
        </div>
      )}
  
      {/* Modal */}
      {selectedReservation && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className={`p-8 rounded-lg ${isDark ? "bg-gray-800" : "bg-white"} w-full max-w-2xl`}>
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-2xl font-bold">Reservation Details</h2>
              <button onClick={closeModal} className="text-gray-500 hover:text-gray-700">
                <X size={24} />
              </button>
            </div>
            <div className="mb-4">
              <p><strong>Status:</strong> {selectedReservation.attributes.status}</p>
              <p><strong>Table:</strong> {selectedReservation.attributes["table-number"]}</p>
              <p><strong>Party Size:</strong> {selectedReservation.attributes.size}</p>
              <p><strong>Time:</strong> {formatDate(selectedReservation.attributes.time)}</p>
              <p><strong>Guest:</strong> {selectedReservation.details.included.find(item => item.type === "person")?.attributes["first-name"] + " " + selectedReservation.details.included.find(item => item.type === "person")?.attributes["last-name"]}</p>
            </div>
            <div className="mb-4">
              <label htmlFor="note" className="block mb-2 font-bold">Note:</label>
              <textarea
                id="note"
                value={editedNote}
                onChange={(e) => setEditedNote(e.target.value)}
                className={`w-full p-2 rounded-lg ${isDark ? "bg-gray-700 text-white" : "bg-gray-100 text-black"}`}
                rows="4"
              />
            </div>
            <button
              onClick={saveNote}
              className={`px-4 py-2 rounded-lg ${
                isDark
                  ? "bg-blue-600 hover:bg-blue-700"
                  : "bg-blue-500 hover:bg-blue-600"
              } text-white`}
            >
              Save Note
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const getStatusColor = (status, isDark) => {
  switch (status) {
    case "seated":
      return isDark ? "text-green-400" : "text-green-600";
    case "completed":
      return isDark ? "text-blue-400" : "text-blue-600";
    case "canceled":
      return isDark ? "text-red-400" : "text-red-600";
    default:
      return isDark ? "text-gray-400" : "text-gray-600";
  }
};

const MobileReservationCard = ({ reservation, openModal }) => {
  const { theme } = useContext(ThemeContext);
  const isDark = theme === "dark";
  
  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const personName =
    reservation.details && reservation.details.included
      ? reservation.details.included.find(
          (item) => item.type === "person"
        )?.attributes["first-name"] +
        " " +
        reservation.details.included.find(
          (item) => item.type === "person"
        )?.attributes["last-name"]
      : "Unknown";

  return (
    <div
      className={`p-4 rounded-lg shadow-sm mb-4 ${
        isDark ? "bg-gray-800" : "bg-white"
      }`}
      onClick={() => openModal(reservation)}
    >
      <div className="flex justify-between items-start mb-3">
        <span
          className={`text-sm font-semibold px-2 py-1 rounded-full ${getStatusColor(
            reservation.attributes.status,
            isDark
          )}`}
        >
          {reservation.attributes.status.charAt(0).toUpperCase() +
            reservation.attributes.status.slice(1)}
        </span>
        <span
          className={`px-3 py-1 rounded-full text-sm ${
            isDark
              ? "bg-blue-900 text-blue-200"
              : "bg-blue-100 text-blue-800"
          }`}
        >
          Table {reservation.attributes["table-number"]}
        </span>
      </div>

      <div className="space-y-2">
        <div className="flex items-center">
          <User size={16} className="text-gray-400 mr-2" />
          <span className={isDark ? "text-gray-200" : "text-gray-900"}>
            {personName}
          </span>
        </div>
        <div className="flex items-center">
          <Users size={16} className="text-gray-400 mr-2" />
          <span className={isDark ? "text-gray-200" : "text-gray-900"}>
            Party of {reservation.attributes.size}
          </span>
        </div>
        <div className="flex items-center">
          <Calendar size={16} className="text-gray-400 mr-2" />
          <span className={isDark ? "text-gray-200" : "text-gray-900"}>
            {formatDate(reservation.attributes.time)}
          </span>
        </div>
        {reservation.attributes.note && (
          <div className="flex items-center">
            <MessageSquare size={16} className="text-gray-400 mr-2" />
            <span className={`${isDark ? "text-gray-300" : "text-gray-600"} line-clamp-2`}>
              {reservation.attributes.note}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Reservations;
