import React, { useState } from 'react';
import { Check, AlertCircle, Bot, BadgeCheck, Crown, Zap, PlusCircle } from 'lucide-react';

const PricingCard = ({ 
    tier, 
    price, 
    description, 
    features, 
    setupFee,
    addOns,
    isPopular
}) => {
    const [isAnnual, setIsAnnual] = useState(false);
    
    // Calculate monthly and annual prices
    const monthlyPrice = price;
    const annualMonthlyPrice = ((price * 12 * 0.9) / 12).toFixed(0);
    const displayPrice = isAnnual ? annualMonthlyPrice : monthlyPrice;
    
    // Calculate and format total annual savings
    const annualSavings = ((price * 12) - (price * 12 * 0.9)).toFixed(0);
    
    return (
        <div className={`
            bg-white rounded-lg shadow-sm p-8 
            ${isPopular ? 'ring-2 ring-blue-600 shadow-lg relative' : ''}
        `}>
            {isPopular && (
                <span className="absolute top-0 right-0 transform translate-x-2 -translate-y-2 bg-blue-600 text-white px-3 py-1 rounded-full text-sm font-medium">
                    Most Popular
                </span>
            )}
            <h3 className="text-2xl font-bold mb-2">{tier}</h3>
            <div className="mb-4">
                <span className="text-4xl font-bold">${displayPrice}</span>
                <span className="text-gray-600 ml-2">/month per location</span>
                {isAnnual && (
                    <div className="text-sm text-green-600 mt-1">
                        Save ${annualSavings} annually
                    </div>
                )}
            </div>
            <div className="mb-4">
                <span className="text-sm text-gray-600">Setup Fee: ${setupFee}</span>
            </div>
            <p className="text-gray-600 mb-6">{description}</p>
            
            <div className="mb-6">
                <div className="w-full max-w-xs mx-auto bg-gray-100 rounded-full p-1">
                    <div className="relative flex">
                        <button 
                            onClick={() => setIsAnnual(false)}
                            className={`flex-1 relative z-10 py-2 px-4 text-sm font-medium rounded-full transition-colors duration-200
                                ${!isAnnual ? 'text-white' : 'text-gray-600'}`}
                        >
                            Monthly
                        </button>
                        <button 
                            onClick={() => setIsAnnual(true)}
                            className={`flex-1 relative z-10 py-2 px-4 text-sm font-medium rounded-full transition-colors duration-200
                                ${isAnnual ? 'text-white' : 'text-gray-600'}`}
                        >
                            Annual (10% off)
                        </button>
                        <div 
                            className={`absolute inset-y-0 w-1/2 bg-blue-600 rounded-full transition-transform duration-200 transform
                                ${isAnnual ? 'translate-x-full' : 'translate-x-0'}`}
                        />
                    </div>
                </div>
            </div>

            <div className="space-y-4 mb-8">
                {Object.entries(features).map(([category, items]) => (
                    <div key={category}>
                        <h4 className="font-semibold text-gray-900 mb-2">{category}</h4>
                        <ul className="space-y-2">
                            {items.map((feature) => (
                                <li key={feature} className="flex items-start">
                                    <Check className="h-5 w-5 text-green-500 mr-2 flex-shrink-0 mt-1" />
                                    <span className="text-gray-700">{feature}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>

            {addOns && (
                <div className="mb-6">
                    <h4 className="font-semibold text-gray-900 mb-2">Available Add-ons:</h4>
                    <ul className="space-y-2">
                        {addOns.map((addon) => (
                            <li key={addon} className="flex items-start">
                                <PlusCircle className="h-5 w-5 text-blue-500 mr-2 flex-shrink-0 mt-1" />
                                <span className="text-gray-700">{addon}</span>
                            </li>
                        ))}
                    </ul>
                </div>
            )}

            <button className={`
                w-full py-3 rounded-lg font-medium transition-colors duration-200
                ${isPopular 
                    ? 'bg-blue-600 text-white hover:bg-blue-700' 
                    : 'bg-gray-100 text-gray-900 hover:bg-gray-200'}
            `}>
                Get Started
            </button>
        </div>
    );
};
  

const PricingPage = () => {
  const plans = [
    {
      tier: "Starter",
      price: 79,
      setupFee: 100,
      annualDiscount: 10,
      description: "Perfect for single restaurants and fast-food outlets aiming to manage their online reputation effectively.",
      features: {
        "Review Management": [
          "AI-generated replies for up to 100 reviews/month",
          "Fetch and reply to Google reviews"
        ],
        "Basic Analytics": [
          "Negative vs. positive review ratio",
          "Number of reviews per location",
          "Total Reviews"
        ],
        "Alerts": [
          "Customizable alerts for critical keywords"
        ],
        "Support": [
          "Email support",
          "Enterprise-grade security features"
        ]
      },
      addOns: [
        "Extra 100 AI-generated replies: $50/month"
      ]
    },
    {
      tier: "Growth",
      price: 149,
      setupFee: 100,
      annualDiscount: 15,
      isPopular: true,
      description: "Ideal for mid-sized restaurants and casual dining chains seeking enhanced review management.",
      features: {
        "Advanced Review Management": [
          "AI-generated replies for up to 250 reviews/month",
          "Multi-platform review integration",
          "Advanced Tone Customization",
          "Automated 1-star and 2-star review management"
        ],
        "Enhanced Analytics": [
          "Staff mentions and insights",
          "Menu item mentions and insights",
          "Keyword analysis by service aspect",
          "Sentiment analysis"
        ],
        "Reporting & Tracking": [
          "Monthly email reports",
          "Monitor up to 4 keywords",
          "Custom Reply Templates"
        ],
        "Support": [
          "Priority email and chat support"
        ]
      },
      addOns: [
        "Extra 250 AI-generated replies: $50/month"
      ]
    },
    {
      tier: "Pro",
      price: 249,
      setupFee: 250,
      annualDiscount: 20,
      description: "For larger restaurant chains requiring comprehensive analytics and AI-driven insights.",
      features: {
        "Extensive Management": [
          "AI-generated replies for up to 1000 reviews/month",
          "AI Internal Agent for insights",
          "POS Integration",
          "Delivery App Tracker Integration",
          "Reservation System Integration"
        ],
        "Advanced Features": [
          "Weekly email reports",
          "Monitor up to 6 keywords",
          "Full customization of AI reply prompts",
          "Custom feature requests"
        ],
        "Premium Support": [
          "Dedicated account manager",
          "Personalized Onboarding Sessions",
          "Access to Beta Features"
        ]
      },
      addOns: [
        "Extra 1,000 AI-generated replies: $50/month"
      ]
    }
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section */}
      <div className="bg-white border-b">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24 text-center">
          <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-6">
            Simple, Transparent Pricing
          </h1>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Choose the plan that's right for your restaurant. Scale as you grow.
          </p>
        </div>
      </div>

      {/* Pricing Grid */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="grid md:grid-cols-3 gap-8">
          {plans.map((plan) => (
            <PricingCard key={plan.tier} {...plan} />
          ))}
        </div>

        {/* Enterprise Section */}
        <div className="mt-16 bg-white rounded-xl p-8 shadow-sm">
          <div className="text-center max-w-3xl mx-auto">
            <h3 className="text-2xl font-bold mb-4">Enterprise</h3>
            <p className="text-gray-600 mb-6">
              Custom pricing based on needs. Ideal for franchisors to have an overview of each location's franchisees and performance.
            </p>
            <div className="grid md:grid-cols-2 gap-6 mb-8">
              <div className="flex items-start">
                <Check className="h-5 w-5 text-green-500 mr-2 flex-shrink-0 mt-1" />
                <span>All Pro features included</span>
              </div>
              <div className="flex items-start">
                <Check className="h-5 w-5 text-green-500 mr-2 flex-shrink-0 mt-1" />
                <span>Custom AI Solutions</span>
              </div>
              <div className="flex items-start">
                <Check className="h-5 w-5 text-green-500 mr-2 flex-shrink-0 mt-1" />
                <span>24/7 dedicated support with SLA guarantees</span>
              </div>
              <div className="flex items-start">
                <Check className="h-5 w-5 text-green-500 mr-2 flex-shrink-0 mt-1" />
                <span>Fully customized reporting solutions</span>
              </div>
            </div>
            <button className="px-8 py-4 bg-gray-900 text-white rounded-lg hover:bg-gray-800 transition-colors">
              Contact Sales
            </button>
          </div>
        </div>

        {/* Add-ons Section */}
        <div className="mt-16">
          <h2 className="text-2xl font-bold text-center mb-8">Additional Add-ons</h2>
          <div className="grid md:grid-cols-2 gap-6 max-w-4xl mx-auto">
            <div className="bg-white p-6 rounded-lg shadow-sm">
              <h3 className="font-semibold mb-2">Additional Keyword Tracking</h3>
              <p className="text-gray-600">$20/month per keyword beyond included amount</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-sm">
              <h3 className="font-semibold mb-2">Custom Feature Development</h3>
              <p className="text-gray-600">Pricing based on project scope</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingPage;