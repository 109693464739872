import { useState, useCallback } from 'react';
import {
  fetchUsers as apiFetchUsers,
  createUser as apiCreateUser,
  deleteUser as apiDeleteUser,
  fetchRoles as apiFetchRoles,
} from '../../../services/api/apiUtility';

const useUserManagement = (accessToken) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [deleteSuccess, setDeleteSuccess] = useState(false);

  const fetchUsers = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await apiFetchUsers(accessToken);
      if (response.status === 'success' && Array.isArray(response.data)) {
        setUsers(response.data);
      } else {
        throw new Error('Unexpected response format');
      }
    } catch (error) {
      console.error('Error loading users:', error);
      setError('Failed to load users. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [accessToken]);

  const fetchRoles = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await apiFetchRoles(accessToken);
      if (response.status === 'success' && Array.isArray(response.data)) {
        setRoles(response.data);
      } else {
        throw new Error('Unexpected response format');
      }
    } catch (error) {
      console.error('Error fetching roles:', error);
      setError('Failed to fetch roles. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [accessToken]);

  const addUser = useCallback(async (userData) => {
    setLoading(true);
    setError(null);
    try {
      // Ensure roles is an array of role IDs
      const formattedUserData = {
        ...userData,
        roles: Array.isArray(userData.roles) ? userData.roles : [userData.roles],
      };

      const response = await apiCreateUser(formattedUserData, accessToken);
      if (response.status === 'success') {
        await fetchUsers(); // Refresh the users list after adding a new user
      }
      return response;
    } catch (error) {
      console.error('Error adding user:', error);
      const errorMessage = error.response?.data?.message || error.message || 'An error occurred while adding the user.';
      setError(errorMessage);
      throw new Error(errorMessage);
    } finally {
      setLoading(false);
    }
  }, [accessToken, fetchUsers]);

  const handleDeleteUser = useCallback(async (userId) => {
    setLoading(true);
    setError(null);
    try {
      const response = await apiDeleteUser(userId, accessToken);
      if (response.status === 'success') {
        await fetchUsers();
        setDeleteSuccess(true);
        setTimeout(() => setDeleteSuccess(false), 3000);
      } else {
        throw new Error('Failed to delete user');
      }
    } catch (error) {
      console.error('Error deleting user:', error);
      setError('Failed to delete user. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [accessToken, fetchUsers]);

  return {
    users,
    roles,
    loading,
    error,
    deleteSuccess,
    fetchUsers,
    fetchRoles,
    addUser,
    handleDeleteUser,
  };
};

export default useUserManagement;