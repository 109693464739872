import React, { useContext, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Utensils, MessageSquare, Calendar, ThumbsUp, Search } from "lucide-react";
import DataTable, { zIndexConfig } from "../../../components/common/DataTable";
import { ThemeContext } from "../../../contexts/ThemeContext";
import { useDateFilter } from "../../../contexts/DateFilterContext";
import DateFilter from "../../../components/common/DateFilter";
import useMediaQuery from "../../../hooks/useMediaQuery";
import {
  selectFilteredMenuItems,
  selectDashboardLoading,
  selectDashboardError,
  selectMenuItems,
} from "../../../store/slices/dashboardSlice";
import Loading from "../../../components/common/Loading";

const getSentimentColor = (sentiment, isDark) => {
  const baseColors = {
    positive: isDark
      ? "bg-green-800 text-green-100 border-green-700"
      : "bg-green-100 text-green-800 border-green-200",
    negative: isDark
      ? "bg-red-800 text-red-100 border-red-700"
      : "bg-red-100 text-red-800 border-red-200",
    mixed: isDark
      ? "bg-yellow-800 text-yellow-100 border-yellow-700"
      : "bg-yellow-100 text-yellow-800 border-yellow-200",
    default: isDark
      ? "bg-gray-700 text-gray-100 border-gray-600"
      : "bg-gray-100 text-gray-800 border-gray-200",
  };

  return baseColors[sentiment.toLowerCase()] || baseColors.default;
};

const MenuItemsTable = ({ loadMoreMenuItems }) => {
  const { theme } = useContext(ThemeContext);
  const isDark = theme === "dark";
  const isMobile = useMediaQuery("(max-width: 768px)");
  const { dateFilter, handleDateChange } = useDateFilter("menu");

  // Redux selectors
  const menuItems = useSelector(selectFilteredMenuItems);
  const { loading: isLoading } = useSelector(selectMenuItems);
  const error = useSelector(selectDashboardError);
  const menuState = useSelector(selectMenuItems);
  const hasMore = menuState.hasMore;

  // Add state for search and filters
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedSentiment, setSelectedSentiment] = useState("");

  // Add filter function
  const filteredMenuItems = useMemo(() => {
    return menuItems.filter(item => {
      const matchesSearch = searchTerm === "" || 
        item.item.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.review_snippet.toLowerCase().includes(searchTerm.toLowerCase());
      
      const matchesSentiment = selectedSentiment === "" || 
        item.sentiment.toLowerCase() === selectedSentiment.toLowerCase();

      return matchesSearch && matchesSentiment;
    });
  }, [menuItems, searchTerm, selectedSentiment]);

  // Mobile card renderer
  const MenuItemCard = ({ item }) => (
    <div
      className={`p-4 rounded-lg shadow-sm mb-4 border ${
        isDark 
          ? "bg-gray-800 border-gray-700" 
          : "bg-white border-gray-200"
      }`}
    >
      {/* Header */}
      <div className="flex justify-between items-start mb-3">
        <div className="flex items-center space-x-2">
          <Utensils size={16} className="text-gray-400" />
          <span className={`font-medium ${isDark ? "text-gray-200" : "text-gray-900"}`}>
            {item.item}
          </span>
        </div>
        <span
          className={`px-2 py-1 text-xs font-semibold rounded-full border ${
            getSentimentColor(item.sentiment, isDark)
          }`}
        >
          {item.sentiment}
        </span>
      </div>

      {/* Snippet Content */}
      <div className="space-y-3">
        <div className="flex items-start space-x-2">
          <MessageSquare size={16} className="text-gray-400 mt-1 flex-shrink-0" />
          <p className={`text-sm ${isDark ? "text-gray-300" : "text-gray-600"}`}>
            {item.review_snippet}
          </p>
        </div>

        {/* Date */}
        <div className="flex items-center text-xs text-gray-500 dark:text-gray-400">
          <Calendar size={12} className="mr-1" />
          {new Date(item.review_date).toLocaleDateString()}
        </div>
      </div>
    </div>
  );

  // Desktop columns configuration
  const columns = [
    { 
      key: "item", 
      label: "Item Name", 
      sortable: true, 
      width: "20%" 
    },
    { 
      key: "sentiment", 
      label: "Sentiment", 
      sortable: true, 
      width: "15%" 
    },
    { 
      key: "review_snippet", 
      label: "Snippet", 
      sortable: false, 
      width: "50%" 
    },
    { 
      key: "review_date", 
      label: "Last Mentioned", 
      sortable: true, 
      width: "15%" 
    },
  ];

  // Custom cell renderers for desktop view
  const customCellRenderers = {
    item: (value) => (
      <div className={`flex items-center ${isDark ? "text-gray-200" : "text-gray-900"}`}>
        <Utensils size={16} className="mr-2 text-gray-400" />
        {value}
      </div>
    ),
    sentiment: (value) => (
      <span
        className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full border ${
          getSentimentColor(value, isDark)
        }`}
      >
        {value}
      </span>
    ),
    review_snippet: (value) => (
      <div className={`${isDark ? "text-gray-300" : "text-gray-600"} line-clamp-2`}>
        {value}
      </div>
    ),
    review_date: (value) => (
      <div className={`flex items-center ${isDark ? "text-gray-300" : "text-gray-600"}`}>
        <Calendar size={16} className="mr-2 text-gray-400" />
        {new Date(value).toLocaleDateString()}
      </div>
    ),
  };

  // Filters
  const filters = useMemo(() => [
    {
      key: "sentiment",
      placeholder: "All Sentiments",
      options: ["positive", "negative", "mixed", "neutral"].map((sentiment) => ({
        value: sentiment,
        label: sentiment.charAt(0).toUpperCase() + sentiment.slice(1),
      })),
      filterFunction: (item, value) =>
        item.sentiment.toLowerCase() === value.toLowerCase(),
    },
  ], []);

  if (error) {
    return (
      <div className="p-4 bg-red-100 dark:bg-red-900/50 rounded-lg">
        <p className="text-red-700 dark:text-red-200">{error}</p>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <div className={`relative z-${zIndexConfig.datePickerDropdown}`}>
        <DateFilter
          dateFilter={dateFilter}
          onDateChange={handleDateChange}
          className="mb-4"
        />
      </div>

      <Loading.ContentLoader loading={isLoading} type="table">
        {isMobile ? (
          // Mobile View
          <div className="space-y-4">
            {/* Mobile Search and Filters */}
            <div className="sticky top-0 bg-gray-100 dark:bg-gray-900 p-4 rounded-lg shadow-sm">
              <div className="relative mb-4">
                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                <input
                  type="text"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder="Search menu items..."
                  className={`w-full pl-10 pr-4 py-2 rounded-lg border ${
                    isDark 
                      ? "bg-gray-800 border-gray-700 text-white" 
                      : "bg-white border-gray-200"
                  }`}
                />
              </div>
              <div className="flex gap-2 overflow-x-auto pb-2">
                {filters.map((filter) => (
                  <select
                    key={filter.key}
                    value={selectedSentiment}
                    onChange={(e) => setSelectedSentiment(e.target.value)}
                    className={`px-3 py-2 rounded-lg border ${
                      isDark
                        ? "bg-gray-800 border-gray-700 text-white"
                        : "bg-white border-gray-200"
                    }`}
                  >
                    <option value="">{filter.placeholder}</option>
                    {filter.options.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                ))}
              </div>
            </div>

            {/* Use filtered items for mobile cards */}
            {filteredMenuItems.map((item, index) => (
              <MenuItemCard key={index} item={item} />
            ))}

            {/* Load More Button */}
            {hasMore && !isLoading && filteredMenuItems.length > 0 && (
              <button
                onClick={loadMoreMenuItems}
                className={`w-full py-2 px-4 rounded-lg transition-colors ${
                  isDark
                    ? "bg-gray-700 text-gray-200 hover:bg-gray-600"
                    : "bg-gray-100 text-gray-700 hover:bg-gray-200"
                }`}
              >
                Load More
              </button>
            )}
          </div>
        ) : (
          // Desktop View
          <DataTable
            columns={columns}
            data={menuItems}
            defaultSortKey="review_date"
            defaultSortDirection="desc"
            filters={filters}
            searchPlaceholder="Search menu items..."
            customCellRenderers={customCellRenderers}
            theme={theme}
            hasMore={hasMore}
            onLoadMore={loadMoreMenuItems}
            isLoadingMore={isLoading && menuItems.length > 0}
            noDataMessage="No menu items found"
          />
        )}
      </Loading.ContentLoader>
    </div>
  );
};

MenuItemsTable.propTypes = {
  loadMoreMenuItems: PropTypes.func.isRequired,
};

export default React.memo(MenuItemsTable);