// src/components/dashboard/DashboardCharts.js
import React, { useContext, useCallback } from 'react';
import { ThemeContext } from '../../../contexts/ThemeContext';
import { AuthContext } from '../../../App';
import useChartData from '../hooks/useChartData';
import useProcessedData from '../hooks/useProcessedData';
import DateFilter from '../../../components/common/DateFilter';
import { useDateFilter } from '../../../contexts/DateFilterContext';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  LabelList,
} from 'recharts';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8', '#82ca9d'];

const DashboardCharts = ({ selectedLocation, locations }) => {
  const { theme } = useContext(ThemeContext);
  const { userRole } = useContext(AuthContext);
  const { dateFilter, handleDateChange } = useDateFilter('graphs');
  const isDark = theme === 'dark';

  // Use useChartData to fetch all data needed for charts
  const { chartData, loading, error } = useChartData(
    selectedLocation,
    locations,
    dateFilter.startDate,
    dateFilter.endDate
  );

  // Function to get location name by ID
  const getLocationNameById = useCallback(
    (id) => {
      const location = locations.find((loc) => loc.id === id);
      return location ? location.name : 'Unknown';
    },
    [locations]
  );

  // Process chart data for visualization
  const processedData = useProcessedData(
    chartData,
    selectedLocation,
    getLocationNameById,
    userRole
  );

  const Card = ({ children, title }) => (
    <div
      className={`${
        isDark ? 'bg-gray-800 text-white' : 'bg-white text-black'
      } rounded-lg shadow-md overflow-hidden`}
    >
      <div className="px-4 py-5 sm:px-6">
        <h3
          className={`text-lg leading-6 font-medium ${
            isDark ? 'text-gray-100' : 'text-gray-900'
          }`}
        >
          {title}
        </h3>
      </div>
      <div className="px-4 py-5 sm:p-6">{children}</div>
    </div>
  );

  const DynamicBarChart = ({ data, dataKey, fill, title, yAxisDomain }) => (
    <Card title={title}>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={data} margin={{ top: 20, right: 30, left: 0, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" stroke={isDark ? '#444' : '#ccc'} />
          <XAxis dataKey="name" stroke={isDark ? '#fff' : '#000'} />
          <YAxis
            domain={yAxisDomain}
            allowDataOverflow={false}
            stroke={isDark ? '#fff' : '#000'}
          />
          <Tooltip
            formatter={(value) => value.toFixed(1)}
            contentStyle={{
              backgroundColor: isDark ? '#333' : '#fff',
              color: isDark ? '#fff' : '#000',
              border: `1px solid ${isDark ? '#555' : '#ddd'}`,
            }}
          />
          <Bar dataKey={dataKey} fill={fill}>
            <LabelList
              dataKey={dataKey}
              position="top"
              formatter={(value) => value.toFixed(1)}
              fill={isDark ? '#fff' : '#000'}
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Card>
  );

  return (
    <div className="space-y-6">
      <DateFilter
        dateFilter={dateFilter}
        onDateChange={handleDateChange}
        className="mb-4"
      />

      {/* Display loading or error messages */}
      {loading && <p>Loading chart data...</p>}
      {error && (
        <div className="p-4 bg-red-100 dark:bg-red-900/50 rounded-lg">
          <p className="text-red-700 dark:text-red-200">{error}</p>
        </div>
      )}

      {!loading && !error && (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <Card title="Total Reviews by Location">
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={processedData}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="totalReviews"
                  label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                >
                  {processedData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip
                  contentStyle={{
                    backgroundColor: isDark ? '#333' : '#fff',
                    color: isDark ? '#fff' : '#000',
                    border: `1px solid ${isDark ? '#555' : '#ddd'}`,
                  }}
                />
              </PieChart>
            </ResponsiveContainer>
          </Card>

          <DynamicBarChart
            data={processedData}
            dataKey="positiveReviews"
            fill="#4CAF50"
            title="Positive Reviews"
            yAxisDomain={[0, 'auto']}
          />

          <DynamicBarChart
            data={processedData}
            dataKey="negativeReviews"
            fill="#F44336"
            title="Negative Reviews"
            yAxisDomain={[0, 'auto']}
          />

          <DynamicBarChart
            data={processedData}
            dataKey="avgRating"
            fill="#FFC107"
            title="Average Rating"
            yAxisDomain={[0, 5]}
          />
        </div>
      )}
    </div>
  );
};

export default DashboardCharts;