// src/App.js
import React, { useState, createContext } from "react";
import { useSelector } from 'react-redux';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  Navigate,
} from "react-router-dom";
import Loading from "./components/common/Loading";
import Login from "./features/auth/components/Login";
import MainPageNavbar from "./components/layout/MainPageNavbar";
import DashboardClient from "./features/dashboard/components/DashboardClient";
import LandingPage from "./features/public/components/LandingPage";
import Blog from "./features/public/components/Blog";
import Enterprise from "./features/public/components/Enterprise";
import Pricing from "./features/public/components/Pricing";
import RestaurantSelector from "./features/restaurant/components/RestaurantSelector";
import { ThemeProvider } from "./contexts/ThemeContext";
import { useAuth } from "./features/auth/hooks/useAuth";
import { selectAuthInitialized } from './store/slices/loadingSlice';
import "./assets/styles/index.css";
import "./assets/styles/App.css";
import OnboardingFlow from "./features/onboarding/components/OnboardingFlow";

// Create an authentication context
export const AuthContext = createContext();

// Protected Route Component
const ProtectedRoute = ({ children, allowedRoles = [], requireRestaurant = false }) => {
  const { accessToken, userRole, selectedRestaurant } = useAuth();
  const location = useLocation();

  if (!accessToken) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (allowedRoles.length > 0 && !allowedRoles.includes(userRole)) {
    return <Navigate to="/dashboard/trends" replace />;
  }

  if (requireRestaurant && userRole === "SuperAdmin" && !selectedRestaurant) {
    return <Navigate to="/restaurant-selector" replace />;
  }

  return children;
};

// Public Route Component
const PublicRoute = ({ children }) => {
  const { accessToken } = useAuth();
  
  if (accessToken) {
    return <Navigate to="/dashboard/trends" replace />;
  }
  
  return children;
};

/**
 * ConditionalNavbar Component
 * Renders the Navbar on specified routes.
 */
const ConditionalNavbar = ({ navLinks }) => {
  const location = useLocation();
  const showNavbarPaths = ["/enterprise", "/blog", "/pricing"];
  const showNavbar = showNavbarPaths.includes(location.pathname);

  return showNavbar ? (
    <MainPageNavbar brand="FeedBacked" links={navLinks} />
  ) : null;
};

function App() {
  const { accessToken, userRole, logout, setUserRole, setAccessToken } = useAuth();
  const authInitialized = useSelector(selectAuthInitialized);
  const [selectedRestaurant, setSelectedRestaurant] = useState(null);
  const [authorizedLocations, setAuthorizedLocations] = useState([]);

  // Define the navigation links
  const navLinks = [
    { to: "/", label: "Home" },
    { to: "/services", label: "Services" },
    { to: "/blog", label: "Blog" },
    { to: "/contact", label: "Contact" },
  ];

  // Logout function
  const logoutSession = () => {
    logout();
    setSelectedRestaurant(null);
  };

  // Show loading spinner while auth is initializing
  if (!authInitialized) {
    return <Loading.Spinner fullScreen />;
  }

  return (
    <ThemeProvider>
      <AuthContext.Provider
        value={{
          authInitialized,
          accessToken,
          setAccessToken,
          userRole,
          setUserRole,
          logout: logoutSession,
          selectedRestaurant,
          setSelectedRestaurant,
          authorizedLocations,
          setAuthorizedLocations,
        }}
      >
        <Router>
          {!authInitialized ? (
            <Loading.Spinner fullScreen />
          ) : (
            <>
              <ConditionalNavbar navLinks={navLinks} />
              <div className="min-h-screen flex flex-col">
                <Routes>
                  {/* Public Routes */}
                  <Route
                    path="/"
                    element={
                      <PublicRoute>
                        <LandingPage />
                      </PublicRoute>
                    }
                  />
                  <Route path="/enterprise" element={<Enterprise />} />
                  <Route path="/blog" element={<Blog />} />
                  <Route path="/pricing" element={<Pricing />} />
                  <Route
                    path="/login"
                    element={
                      <PublicRoute>
                        <Login />
                      </PublicRoute>
                    }
                  />
                  <Route 
                    path="/onboarding" 
                    element={
                        <OnboardingFlow />
                    } 
                  />

                  {/* Protected Routes */}
                  <Route
                    path="/restaurant-selector"
                    element={
                      <ProtectedRoute allowedRoles={["SuperAdmin"]}>
                        <RestaurantSelector />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/dashboard/*"
                    element={
                      <ProtectedRoute requireRestaurant>
                        <DashboardClient />
                      </ProtectedRoute>
                    }
                  />

                  {/* Catch all route */}
                  <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
              </div>
            </>
          )}
        </Router>
      </AuthContext.Provider>
    </ThemeProvider>
  );
}

export default App;