import React, { useContext, useMemo, Suspense, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { ThemeContext } from "../../../contexts/ThemeContext";
import DashboardLayout from "./DashboardLayout";
import DashboardRoutes from "./DashboardRoutes";
import { getNavigationItems } from "../../../utils/navigationConfig";
import Loading from "../../../components/common/Loading";
import {
 fetchDashboardData,
 locationChanged,
 selectDashboardState,
 selectDashboardLoading,
 selectDashboardError,
 selectFilteredReviews,
 selectFilteredStaffMentions,
 selectFilteredMenuItems
} from "../../../store/slices/dashboardSlice";
import { selectUserRole } from "../../../store/slices/authSlice";
import {
 selectSelectedLocation, 
 selectLocations,
 selectLocationLoading
} from "../../../store/slices/locationSlice";
import { useDateFilter } from "../../../contexts/DateFilterContext";

const LoadingWrapper = ({ children, isLoading, error = null }) => {
 if (error) {
   return (
     <div className="flex items-center justify-center min-h-screen p-4">
       <div className="text-center">
         <h2 className="text-xl font-semibold mb-2">Error Loading Dashboard</h2>
         <p className="text-gray-600 dark:text-gray-300">{error}</p>
       </div>
     </div>
   );
 }

 if (isLoading) {
   return <Loading.Spinner fullScreen />;
 }

 return children;
};

const DashboardContent = ({ selectedRestaurant }) => {
 const dispatch = useDispatch();
 const { theme } = useContext(ThemeContext);
 
 // Request tracking refs
 const requestTracker = useRef({
   lastRequest: null,
   inProgress: false,
   timeoutId: null,
   lastLocation: null
 });

 // Essential selectors  
 const selectedLocation = useSelector(selectSelectedLocation);
 const locations = useSelector(selectLocations);
 const locationLoading = useSelector(selectLocationLoading);
 const userRole = useSelector(selectUserRole);

 // Dashboard data
 const {
   reviews,
   staffMentions,
   menuItems,
   trendData,
   processedData,
 } = useSelector(selectDashboardState);
 const isLoading = useSelector(selectDashboardLoading);
 const error = useSelector(selectDashboardError);

 // Filtered data
 const filteredReviews = useSelector(selectFilteredReviews);
 const filteredStaffMentions = useSelector(selectFilteredStaffMentions);
 const filteredMenuItems = useSelector(selectFilteredMenuItems);

 // Date filters
 const { dateFilter: reviewsDateFilter } = useDateFilter("reviews");
 const { dateFilter: staffDateFilter } = useDateFilter("staff");
 const { dateFilter: menuDateFilter } = useDateFilter("menu"); 

 // Navigation setup
 const navigationItems = useMemo(() => getNavigationItems(userRole), [userRole]);
 const defaultRoute = useMemo(() => navigationItems[0]?.path || "/dashboard", [navigationItems]);

 // Fetch data with request tracking
 const fetchData = useCallback((locationId, offset = 0) => {
   const requestSignature = JSON.stringify({
     locationId,
     offset,
     dateFilters: {
       reviews: reviewsDateFilter,
       staff: staffDateFilter,
       menu: menuDateFilter
     }
   });

   // Skip if same request is in progress or was just made
   if (requestTracker.current.inProgress || requestTracker.current.lastRequest === requestSignature) {
     return;
   }

   requestTracker.current.inProgress = true;
   requestTracker.current.lastRequest = requestSignature;

   dispatch(fetchDashboardData({
     locationId,
     offset,
     limit: 50,
     dateFilters: {
       reviews: {
         startDate: reviewsDateFilter.startDate,
         endDate: reviewsDateFilter.endDate,
       },
       staff: {
         startDate: staffDateFilter.startDate,
         endDate: staffDateFilter.endDate,
       },
       menu: {
         startDate: menuDateFilter.startDate,
         endDate: menuDateFilter.endDate,
       }
     }
   })).finally(() => {
     requestTracker.current.inProgress = false;
   });
 }, [dispatch, reviewsDateFilter, staffDateFilter, menuDateFilter]);

 // Combined effect for location and date filter changes
 useEffect(() => {
   if (!selectedLocation) return;

   // Clear any pending timeouts
   if (requestTracker.current.timeoutId) {
     clearTimeout(requestTracker.current.timeoutId);
   }

   const locationId = selectedLocation.id || "all";

   // Only dispatch locationChanged if location actually changed
   if (requestTracker.current.lastLocation !== locationId) {
     dispatch(locationChanged(locationId));
     requestTracker.current.lastLocation = locationId;
   }

   // Debounce the data fetch
   requestTracker.current.timeoutId = setTimeout(() => {
     fetchData(locationId);
   }, 300);

   return () => {
     if (requestTracker.current.timeoutId) {
       clearTimeout(requestTracker.current.timeoutId);
     }
   };
 }, [
   selectedLocation,
   dispatch,
   fetchData,
   reviewsDateFilter.startDate,
   reviewsDateFilter.endDate,
   staffDateFilter.startDate,
   staffDateFilter.endDate,
   menuDateFilter.startDate,
   menuDateFilter.endDate
 ]);

 // Load more handlers with request tracking
 const loadMoreHandlers = useMemo(() => ({
   reviews: () => {
     if (reviews.loading || requestTracker.current.inProgress) return;
     fetchData(selectedLocation?.id || "all", reviews.data.length);
   },
   staffMentions: () => {
     if (staffMentions.loading || requestTracker.current.inProgress) return;
     fetchData(selectedLocation?.id || "all", staffMentions.data.length);
   },
   menuItems: () => {
     if (menuItems.loading || requestTracker.current.inProgress) return;
     fetchData(selectedLocation?.id || "all", menuItems.data.length);
   }
 }), [selectedLocation, reviews, staffMentions, menuItems, fetchData]);

 return (
   <LoadingWrapper isLoading={locationLoading || !selectedLocation} error={error}>
     <DashboardLayout
       navigationItems={navigationItems}
       brandName={selectedRestaurant?.name || "FeedBacked"}
     >
       <div className="relative">
         {isLoading && (
           <div className="fixed top-4 right-4">
             <Loading.Spinner size="small" />
           </div>
         )}
         <Suspense fallback={<Loading.Spinner />}>
           <Routes>
             <Route
               path="/*"
               element={
                 <DashboardRoutes
                   userRole={userRole}
                   selectedLocation={selectedLocation}
                   trendData={trendData}
                   processedData={processedData}
                   filteredReviews={filteredReviews}
                   reviewsLoading={reviews.loading}
                   reviewsHasMore={reviews.hasMore}
                   loadMoreReviews={loadMoreHandlers.reviews}
                   staffMentions={filteredStaffMentions}
                   staffLoading={staffMentions.loading}
                   staffHasMore={staffMentions.hasMore}
                   loadMoreStaffMentions={loadMoreHandlers.staffMentions}
                   menuItems={filteredMenuItems}
                   menuLoading={menuItems.loading}
                   menuHasMore={menuItems.hasMore}
                   loadMoreMenuItems={loadMoreHandlers.menuItems}
                   locations={locations}
                   selectedRestaurant={selectedRestaurant}
                 />
               }
             />
             <Route path="*" element={<Navigate to={defaultRoute} replace />} />
           </Routes>
         </Suspense>
       </div>
     </DashboardLayout>
   </LoadingWrapper>
 );
};

DashboardContent.propTypes = {
 selectedRestaurant: PropTypes.shape({
   id: PropTypes.string,
   name: PropTypes.string,
 }),
};

export default DashboardContent;