import React, { useState, useRef, useEffect, useContext, useCallback } from 'react';
import { DayPicker } from 'react-day-picker';
import { CalendarIcon, XCircle, ChevronLeft, ChevronRight } from 'lucide-react';
import { format, addDays, startOfMonth, endOfMonth, subMonths } from 'date-fns';
import { ThemeContext } from '../../contexts/ThemeContext';
import 'react-day-picker/dist/style.css';

const PRESET_RANGES = [
  { label: 'Last 7 days', getDates: () => ({ from: addDays(new Date(), -7), to: new Date() }) },
  { label: 'Last 30 days', getDates: () => ({ from: addDays(new Date(), -30), to: new Date() }) },
  { label: 'This month', getDates: () => ({ from: startOfMonth(new Date()), to: new Date() }) },
  { label: 'Last month', getDates: () => ({ 
    from: startOfMonth(subMonths(new Date(), 1)), 
    to: endOfMonth(subMonths(new Date(), 1)) 
  }) },
];

const DateFilter = ({ dateFilter, onDateChange, className = '' }) => {
  const [isOpen, setIsOpen] = useState(false);
  const datePickerRef = useRef(null);
  const { theme } = useContext(ThemeContext);
  const isDark = theme === 'dark';

  const handleSelect = useCallback((range) => {
    if (!range) {
      onDateChange('startDate', '');
      onDateChange('endDate', '');
      return;
    }

    const formatDateForAPI = (date) => {
      if (!date) return '';
      return format(date, 'yyyy-MM-dd');
    };

    onDateChange('startDate', formatDateForAPI(range.from));
    onDateChange('endDate', formatDateForAPI(range.to || range.from));
  }, [onDateChange]);

  const handleReset = useCallback((e) => {
    e.stopPropagation();
    handleSelect(null);
  }, [handleSelect]);

  const handlePresetSelect = useCallback((preset) => {
    const range = preset.getDates();
    handleSelect(range);
    setIsOpen(false);
  }, [handleSelect]);

  const parseDate = useCallback((dateString) => {
    if (!dateString) return undefined;
    const [year, month, day] = dateString.split('-').map(Number);
    return new Date(year, month - 1, day);
  }, []);

  const selected = {
    from: parseDate(dateFilter.startDate),
    to: parseDate(dateFilter.endDate)
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  const formatDisplayDate = useCallback((date) => {
    return format(date, 'MMM dd, yyyy');
  }, []);

  const footer = selected.from && selected.to && (
    <div className={`px-3 py-2 text-sm ${isDark ? 'text-gray-300' : 'text-gray-600'}`}>
      {`${formatDisplayDate(selected.from)} - ${formatDisplayDate(selected.to)}`}
    </div>
  );

  return (
    <div className={`relative ${className}`}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={`flex items-center w-full justify-between px-3 py-2 text-sm rounded-md border
          ${isDark 
            ? 'bg-gray-800 border-gray-700 text-gray-200 hover:bg-gray-700' 
            : 'bg-white border-gray-200 text-gray-700 hover:bg-gray-50'}
          focus:outline-none focus:ring-2 focus:ring-blue-500 transition-colors duration-200`}
        aria-expanded={isOpen}
        aria-haspopup="dialog"
      >
        <div className="flex items-center gap-2">
          <CalendarIcon className={`h-4 w-4 ${isDark ? 'text-gray-400' : 'text-gray-500'}`} />
          <span>
            {selected.from
              ? selected.to
                ? `${formatDisplayDate(selected.from)} - ${formatDisplayDate(selected.to)}`
                : formatDisplayDate(selected.from)
              : 'Select date range'}
          </span>
        </div>
        
        {(selected.from || selected.to) && (
          <button
            type="button"
            className="ml-2"
            onClick={handleReset}
            aria-label="Clear date selection"
          >
            <XCircle className={`h-4 w-4 ${isDark ? 'text-gray-400 hover:text-gray-300' : 'text-gray-500 hover:text-gray-600'}`} />
          </button>
        )}
      </button>

      {isOpen && (
        <div
          ref={datePickerRef}
          className={`absolute z-10 mt-1 rounded-lg shadow-lg border
            ${isDark ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'}`}
        >
          <div className="flex">
            <div className="p-3 border-r border-gray-200 dark:border-gray-700">
              <div className="space-y-2">
                {PRESET_RANGES.map((preset, index) => (
                  <button
                    key={index}
                    onClick={() => handlePresetSelect(preset)}
                    className={`w-full text-left px-3 py-2 rounded-md text-sm
                      ${isDark 
                        ? 'hover:bg-gray-700 text-gray-300' 
                        : 'hover:bg-gray-100 text-gray-700'}`}
                  >
                    {preset.label}
                  </button>
                ))}
              </div>
            </div>

            <DayPicker
              mode="range"
              selected={selected}
              onSelect={handleSelect}
              numberOfMonths={2}
              footer={footer}
              className="p-3"
              formatters={{
                formatWeekdayName: (weekday) => {
                  const day = weekday.toString().slice(0, 2);
                  return (
                    <span className={isDark ? 'text-white' : 'text-gray-600'}>
                      {day}
                    </span>
                  );
                }
              }}
              components={{
                IconLeft: () => <ChevronLeft className={`h-4 w-4 ${isDark ? 'text-white' : ''}`} />,
                IconRight: () => <ChevronRight className={`h-4 w-4 ${isDark ? 'text-white' : ''}`} />
              }}
              classNames={{
                months: "flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0",
                month: "space-y-4",
                caption: "flex justify-center relative items-center h-10",
                caption_label: isDark ? "text-gray-200 font-medium" : "text-gray-600 font-medium",
                nav: "space-x-1 flex items-center",
                nav_button: `h-7 w-7 bg-transparent p-0 ${
                  isDark ? 'text-white hover:text-gray-200' : 'text-gray-600 hover:text-gray-900'
                }`,
                nav_button_previous: "absolute left-1",
                nav_button_next: "absolute right-1",
                table: "w-full border-collapse space-y-1",
                head_row: "flex",
                head_cell: "text-sm w-8 font-medium",
                row: "flex w-full mt-2",
                cell: "relative p-0 text-center text-sm focus-within:relative focus-within:z-20",
                day: `h-8 w-8 p-0 font-normal rounded-md aria-selected:opacity-100 
                  ${isDark ? 'text-gray-300 hover:bg-gray-700' : 'text-gray-900 hover:bg-gray-100'}`,
                day_selected: "bg-yellow-500 text-white hover:bg-yellow-600",
                day_today: "text-blue-500 font-bold",
                day_outside: isDark ? "text-gray-600 opacity-50" : "text-gray-400 opacity-50",
                day_disabled: isDark ? "text-gray-600" : "text-gray-400",
                day_range_middle: isDark ? "aria-selected:bg-gray-700" : "aria-selected:bg-gray-100",
                day_hidden: "invisible"
              }}
              styles={{
                head_cell: isDark ? { color: 'white' } : {}
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(DateFilter);