// useChartData.js

import { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../../App';
import { getGoogleReviews } from '../../../services/api/apiUtility';

const useChartData = (selectedLocation, locations, startDate, endDate) => {
  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { accessToken } = useContext(AuthContext);

  useEffect(() => {
    if (!selectedLocation || !accessToken) {
      return;
    }

    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        let allReviews = [];

        if (selectedLocation.name === 'All') {
          // Fetch reviews for all locations
          const locationIds = locations.map((loc) => loc.id).filter(Boolean);

          const allReviewsPromises = locationIds.map((locId) =>
            getGoogleReviews(
              locId,
              null,
              null,
              accessToken,
              startDate,
              endDate
            )
              .then((result) => {
                if (result.status === 'success') {
                  return result.data.map((review) => ({
                    ...review,
                    location_id: locId,
                  }));
                } else {
                  throw new Error(result.message);
                }
              })
              .catch((err) => {
                console.error(`Error fetching reviews for location ${locId}:`, err);
                return []; // Return empty array on error
              })
          );

          const allReviewsResults = await Promise.allSettled(allReviewsPromises);

          allReviewsResults.forEach((res) => {
            if (res.status === 'fulfilled') {
              allReviews = allReviews.concat(res.value);
            }
          });
        } else {
          // Fetch reviews for the selected location
          const result = await getGoogleReviews(
            selectedLocation.id,
            null,
            null,
            accessToken,
            startDate,
            endDate
          );

          if (result.status === 'success') {
            allReviews = result.data.map((review) => ({
              ...review,
              location_id: selectedLocation.id,
            }));
          } else {
            throw new Error(result.message);
          }
        }

        setChartData(allReviews);
      } catch (err) {
        console.error('Error fetching chart data:', err);
        setError(`Error fetching chart data: ${err.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedLocation, locations, accessToken, startDate, endDate]);

  return {
    chartData,
    loading,
    error,
  };
};

export default useChartData;
