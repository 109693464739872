import React, { useContext } from 'react';
import { Users, Trash2, Search } from 'lucide-react';
import { ThemeContext } from '../../../contexts/ThemeContext';
import { useSelector } from 'react-redux';
import { selectAuthorizedLocations } from '../../../store/slices/authSlice';

const UserTable = ({ users, searchTerm, setSearchTerm, setDeleteConfirmation, currentUserEmail }) => {
  const { theme } = useContext(ThemeContext);
  const authorizedLocations = useSelector(selectAuthorizedLocations);
  const isDark = theme === 'dark';

  return (
    <div className={`p-6 rounded-lg shadow-lg ${isDark ? 'bg-gray-800' : 'bg-white'}`}>
      <h2 className="text-xl font-bold mb-4 flex items-center">
        <Users className="mr-2" color={isDark ? '#9C27B0' : '#673AB7'} />
        All Users
      </h2>

      {/* Search Bar */}
      <div className="relative mb-6">
        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
        <input
          type="text"
          placeholder="Search users..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className={`w-full pl-10 pr-4 py-3 rounded-lg border 
            ${isDark ? 'bg-gray-700 border-gray-600 text-white' : 'bg-white border-gray-200 text-black'}
            focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
        />
      </div>

      {/* Users Table */}
      <div className="overflow-x-auto">
        <table className={`min-w-full divide-y ${isDark ? 'divide-gray-700' : 'divide-gray-200'}`}>
          <thead className={isDark ? 'bg-gray-700' : 'bg-gray-50'}>
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                Username
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                Email
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                Role
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                Location
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className={`${isDark ? 'bg-gray-800' : 'bg-white'} divide-y ${
            isDark ? 'divide-gray-700' : 'divide-gray-200'
          }`}>
            {users.map((user) => (
              <tr key={user.id}>
                <td className="px-6 py-4 whitespace-nowrap">
                  {user.username}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {user.email}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {user.roles && user.roles.length > 0
                    ? user.roles.map((role) => role.name).join(", ")
                    : "N/A"}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {authorizedLocations.find(
                    (loc) => loc.id === user.location_id
                  )?.name || "Unknown"}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {user.email !== currentUserEmail && (
                    <button
                      onClick={() => setDeleteConfirmation(user)}
                      className="text-red-500 hover:text-red-700 transition-colors"
                    >
                      <Trash2 size={20} />
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UserTable;