// src/components/RestaurantSelector.js
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Search, PlusCircle, Building2, CheckCircle, Trash2, AlertCircle } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import {
  fetchRestaurants,
  addRestaurant,
  removeRestaurant,
  clearError,
  setShowSuccess,
  selectRestaurants,
  selectRestaurantLoading,
  selectRestaurantError,
  selectRestaurantSuccess
} from '../../../store/slices/restaurantSlice';
import { setSelectedRestaurant } from '../../../store/slices/authSlice';

const RestaurantSelector = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  // Local state
  const [newRestaurantName, setNewRestaurantName] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);

  // Redux selectors
  const restaurants = useSelector(selectRestaurants);
  const loading = useSelector(selectRestaurantLoading);
  const error = useSelector(selectRestaurantError);
  const showSuccess = useSelector(selectRestaurantSuccess);

  // Fetch restaurants on mount
  useEffect(() => {
    dispatch(fetchRestaurants());
  }, [dispatch]);

  // Cleanup success message on unmount
  useEffect(() => {
    return () => {
      dispatch(setShowSuccess(false));
    };
  }, [dispatch]);

  const handleAddRestaurant = async () => {
    const resultAction = await dispatch(addRestaurant(newRestaurantName));
    if (!resultAction.error) {
      setNewRestaurantName('');
    }
  };

  const handleInputChange = (e) => {
    setNewRestaurantName(e.target.value);
    if (error) dispatch(clearError());
  };

  const handleSelectRestaurant = (restaurant) => {
    dispatch(setSelectedRestaurant(restaurant));
    navigate('/dashboard/trends');
  };

  const handleDeleteRestaurant = async (id) => {
    await dispatch(removeRestaurant(id));
    setDeleteConfirmation(null);
  };

  const filteredRestaurants = restaurants.filter(restaurant =>
    restaurant?.name?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <h2 className="text-3xl font-bold mb-6 text-gray-800">Select Restaurant</h2>
      
      {/* Search Section */}
      <div className="mb-6 flex gap-2">
        <div className="relative flex-grow">
          <input
            type="text"
            placeholder="Search restaurants..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full p-3 pr-10 border rounded-lg shadow-sm focus:ring-2 focus:ring-blue-300 focus:border-blue-500 transition"
          />
          <Search className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
        </div>
      </div>

      {/* Add Restaurant Section */}
      <div className="mb-6">
        <div className="flex gap-2">
          <input
            type="text"
            placeholder="New Restaurant Name"
            value={newRestaurantName}
            onChange={handleInputChange}
            className={`flex-grow p-3 border rounded-lg shadow-sm focus:ring-2 focus:ring-green-300 focus:border-green-500 transition ${
              error ? 'border-red-500' : ''
            }`}
          />
          <button 
            onClick={handleAddRestaurant}
            className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 transition duration-300 ease-in-out flex items-center"
            disabled={loading}
          >
            <PlusCircle size={20} className="mr-2" />
            Add
          </button>
        </div>
        {error && (
          <div className="mt-2 text-red-500 flex items-center">
            <AlertCircle size={16} className="mr-2" />
            {error}
          </div>
        )}
      </div>

      {/* Success Message */}
      {showSuccess && (
        <div className="fixed top-4 right-4 bg-green-500 text-white p-4 rounded-lg shadow-lg flex items-center animate-bounce">
          <CheckCircle size={24} className="mr-2" />
          Action completed successfully!
        </div>
      )}

      {/* Loading Indicator */}
      {loading && (
        <div className="text-gray-500 mb-4 flex items-center">
          <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-gray-500 mr-2"></div>
          Loading...
        </div>
      )}

      {/* Restaurants Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {filteredRestaurants.map((restaurant) => (
          <div key={restaurant.id} className="bg-white border rounded-lg p-6 hover:shadow-lg transition-shadow">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center">
                <Building2 size={24} className="text-gray-500 mr-2" />
                <h3 className="text-xl font-semibold text-gray-800">{restaurant.name}</h3>
              </div>
              <button
                onClick={() => setDeleteConfirmation(restaurant)}
                className="text-red-500 hover:text-red-700 transition-colors"
              >
                <Trash2 size={20} />
              </button>
            </div>
            <button 
              onClick={() => handleSelectRestaurant(restaurant)} 
              className="w-full px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition duration-300 ease-in-out flex items-center justify-center"
            >
              View Dashboard
            </button>
          </div>
        ))}
      </div>

      {/* Delete Confirmation Modal */}
      {deleteConfirmation && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-xl">
            <h3 className="text-xl font-bold mb-4">Confirm Deletion</h3>
            <p className="mb-4">Are you sure you want to delete "{deleteConfirmation.name}"?</p>
            <div className="flex justify-end space-x-2">
              <button
                onClick={() => setDeleteConfirmation(null)}
                className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400 transition-colors"
              >
                Cancel
              </button>
              <button
                onClick={() => handleDeleteRestaurant(deleteConfirmation.id)}
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition-colors"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RestaurantSelector;