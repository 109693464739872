import React from 'react';
import { Loader } from 'lucide-react';
import { useSelector } from 'react-redux';
import { selectLoadingState } from '../../store/slices/loadingSlice';

// Main spinner component
export const LoadingSpinner = ({ size = 'default', fullScreen = false, loadingKey }) => {
  const isLoading = useSelector((state) => selectLoadingState(state, loadingKey));

  const sizeClasses = {
    small: 'w-4 h-4',
    default: 'w-8 h-8',
    large: 'w-12 h-12'
  };

  const spinner = (
    <div className="flex flex-col items-center justify-center">
      <Loader className={`animate-spin ${sizeClasses[size]} text-blue-600 dark:text-blue-400`} />
      <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">Loading...</p>
    </div>
  );

  // If loadingKey is provided, only show when that specific state is loading
  if (loadingKey && !isLoading) {
    return null;
  }

  if (fullScreen) {
    return (
      <div className="fixed inset-0 bg-white/80 dark:bg-gray-900/80 backdrop-blur-sm z-50 flex items-center justify-center">
        {spinner}
      </div>
    );
  }

  return spinner;
};

// Dashboard skeleton loader with optional Redux integration
export const DashboardSkeleton = ({ loadingKey }) => {
  const isLoading = useSelector((state) => 
    loadingKey ? selectLoadingState(state, loadingKey) : true
  );

  if (loadingKey && !isLoading) return null;

  return (
    <div className="space-y-6 w-full animate-pulse">
      {/* Stats Grid */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
        {[...Array(4)].map((_, i) => (
          <div 
            key={i} 
            className="h-32 bg-gray-200 dark:bg-gray-700 rounded-lg p-4"
          >
            <div className="h-4 bg-gray-300 dark:bg-gray-600 rounded w-3/4 mb-4"></div>
            <div className="h-8 bg-gray-300 dark:bg-gray-600 rounded w-1/2"></div>
          </div>
        ))}
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="h-[300px] bg-gray-200 dark:bg-gray-700 rounded-lg"></div>
        <div className="h-[300px] bg-gray-200 dark:bg-gray-700 rounded-lg"></div>
      </div>
    </div>
  );
};

// Table skeleton loader with optional Redux integration
export const TableSkeleton = ({ rows = 5, loadingKey }) => {
  const isLoading = useSelector((state) => 
    loadingKey ? selectLoadingState(state, loadingKey) : true
  );

  if (loadingKey && !isLoading) return null;

  return (
    <div className="space-y-4 w-full animate-pulse">
      <div className="h-10 bg-gray-200 dark:bg-gray-700 rounded-lg w-full"></div>
      {[...Array(rows)].map((_, i) => (
        <div key={i} className="h-16 bg-gray-200 dark:bg-gray-700 rounded-lg w-full"></div>
      ))}
    </div>
  );
};

// Enhanced ContentLoader with Redux integration
export const ContentLoader = ({ 
  children, 
  type = 'default',
  loadingKey,
  fallback = null
}) => {
  const isLoading = useSelector((state) => 
    loadingKey ? selectLoadingState(state, loadingKey) : false
  );

  const skeletons = {
    default: <LoadingSpinner loadingKey={loadingKey} />,
    dashboard: <DashboardSkeleton loadingKey={loadingKey} />,
    table: <TableSkeleton loadingKey={loadingKey} />
  };

  return (
    <div className="relative transition-opacity duration-200">
      {isLoading ? (fallback || skeletons[type]) : children}
    </div>
  );
};

export default {
  Spinner: LoadingSpinner,
  Dashboard: DashboardSkeleton,
  Table: TableSkeleton,
  ContentLoader
};