import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { jwtDecode } from "jwt-decode";
import {
  login,
  refreshSession,
  logoutSession,
  getLocations,
  getRestaurant,
} from "../../services/api/apiUtility";
import { setAuthInitialized } from "./loadingSlice";

const initialState = {
  accessToken: null,
  userRole: null,
  isLoading: false,
  error: null,
  authorizedLocations: [],
  selectedRestaurant: null,
  accessTokenExpiresIn: null,
  authInitialized: false 
};

export const loginUser = createAsyncThunk(
  'auth/login',
  async ({ email, password }, { rejectWithValue }) => {
    try {
      // 1. Login
      const loginResponse = await login(email, password);
      if (!loginResponse.status === 'success') throw new Error('Authentication failed');
      
      const { access_token, expires_in } = loginResponse.data;
      const decodedToken = jwtDecode(access_token);
      const userRole = decodedToken.resource_access.restos.roles[0];

      // 2. Get locations and restaurant in sequence, not parallel
      let locations = [];
      let restaurant = null;

      if (userRole !== 'SuperAdmin') {
        const locationsResponse = await getLocations(access_token);
        if (locationsResponse.status === 'success') {
          locations = locationsResponse.data;
        }

        const restaurantResponse = await getRestaurant(access_token);
        if (restaurantResponse.status === 'success') {
          [restaurant] = restaurantResponse.data;
        }
      }

      return {
        accessToken: access_token,
        userRole,
        authorizedLocations: locations,
        selectedRestaurant: restaurant,
        accessTokenExpiresIn: Number(expires_in)
      };

    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const refreshAuth = createAsyncThunk(
  "auth/refresh",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await refreshSession();
      const { access_token, refresh_token, expires_in } = response.data;

      if (!access_token || !refresh_token) {
        throw new Error("Invalid response from server: Missing tokens");
      }

      const decodedToken = jwtDecode(access_token);
      const userRole = decodedToken.resource_access.restos.roles[0];

      return {
        accessToken: access_token,
        userRole,
        accessTokenExpiresIn: Number(expires_in),
      };
    } catch (error) {
      // Don't treat refresh failures as fatal errors
      return rejectWithValue(null);
    } finally {
      // Always mark auth as initialized after refresh attempt
      dispatch(setAuthInitialized(true));
    }
  }
);

export const logout = createAsyncThunk(
  "auth/logout",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      await logoutSession();
      return true;
    } catch (error) {
      return true; // Continue with logout even if server request fails
    } finally {
      dispatch(setAuthInitialized(true));
    }
  }
);

export const selectAuthState = (state) => ({
  userRole: state.auth.userRole,
  selectedRestaurant: state.auth.selectedRestaurant,
  accessToken: state.auth.accessToken
});

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setSelectedRestaurant: (state, action) => {
      state.selectedRestaurant = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Login cases
      .addCase(loginUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        console.log('Login fulfilled:', action.payload);
        return {
          ...state,
          ...action.payload,
          isLoading: false,
          error: null,
          authInitialized: true
        };
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      // Refresh cases
      .addCase(refreshAuth.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(refreshAuth.fulfilled, (state, action) => {
        return {
          ...state,
          ...action.payload,
          isLoading: false,
          error: null,
        };
      })
      .addCase(refreshAuth.rejected, (state) => {
        // Just clear auth state on refresh failure without setting error
        return {
          ...initialState,
          isLoading: false,
        };
      })
      // Logout cases
      .addCase(logout.fulfilled, () => initialState)
      .addCase(logout.rejected, () => initialState);
  },
});

export const { setSelectedRestaurant } = authSlice.actions;

// Selectors
export const selectAuth = (state) => state.auth;
export const selectIsAuthenticated = (state) => {
  const isAuthenticated = Boolean(state.auth.accessToken && state.auth.authInitialized);
  console.log('selectIsAuthenticated:', {
    hasToken: !!state.auth.accessToken,
    isInitialized: state.auth.authInitialized,
    result: isAuthenticated
  });
  return isAuthenticated;
};
export const selectUserRole = (state) => state.auth.userRole;
export const selectAuthorizedLocations = (state) => state.auth.authorizedLocations;
export const selectSelectedRestaurant = (state) => state.auth.selectedRestaurant;
export const selectAuthLoading = (state) => state.auth.isLoading || !state.auth.authInitialized;
export const selectAccessToken = (state) => state.auth.accessToken;

export default authSlice.reducer;