import React, { useState, useContext } from 'react';
import { PlusCircle, ChevronDown } from 'lucide-react';
import { ThemeContext } from '../../../contexts/ThemeContext';
import { useSelector } from 'react-redux';
import { selectAuthorizedLocations } from '../../../store/slices/authSlice';

const AddUserForm = ({  roles, addUserError, addUserSuccess, handleAddUser, isMobile}) => {
    const { theme } = useContext(ThemeContext);
    const authorizedLocations = useSelector(selectAuthorizedLocations);
    const [isExpanded, setIsExpanded] = useState(!isMobile);
    const isDark = theme === 'dark';
  
  const [newUser, setNewUser] = useState({
    username: '',
    email: '',
    firstname: '',
    lastname: '',
    roles: [],
    location_id: '',
  });
  const [addingUser, setAddingUser] = useState(false);

  const handleSubmit = async () => {
    setAddingUser(true);
    const success = await handleAddUser(newUser);
    if (success) {
      setNewUser({
        username: '',
        email: '',
        firstname: '',
        lastname: '',
        roles: [],
        location_id: '',
      });
    }
    setAddingUser(false);
  };

  const inputClass = `w-full px-4 py-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent
    ${isDark ? 'bg-gray-700 border-gray-600 text-white' : 'bg-white border-gray-200 text-black'}`;

    return (
        <div className={`rounded-lg shadow-lg mb-8 ${isDark ? 'bg-gray-800' : 'bg-white'}`}>
          {/* Collapsible Header */}
          <button
            onClick={() => setIsExpanded(!isExpanded)}
            className="w-full p-4 text-left flex items-center justify-between"
          >
            <div className="flex items-center">
              <PlusCircle 
                className="mr-2" 
                size={isMobile ? 24 : 20} 
                color={isDark ? '#4CAF50' : '#2196F3'} 
              />
              <h2 className="text-xl font-bold">Add New User</h2>
            </div>
            <ChevronDown
              className={`transition-transform duration-200 ${isExpanded ? 'rotate-180' : ''}`}
              size={isMobile ? 24 : 20}
            />
          </button>
      
          {/* Collapsible Content */}
          {isExpanded && (
            <div className={`p-4 ${isMobile ? 'border-t' : ''} ${isDark ? 'border-gray-700' : 'border-gray-200'}`}>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <input
                  type="text"
                  placeholder="Username"
                  value={newUser.username}
                  onChange={(e) => setNewUser({ ...newUser, username: e.target.value })}
                  className={inputClass}
                />
                
                <input
                  type="email"
                  placeholder="Email"
                  value={newUser.email}
                  onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
                  className={inputClass}
                />
                
                <input
                  type="text"
                  placeholder="First Name"
                  value={newUser.firstname}
                  onChange={(e) => setNewUser({ ...newUser, firstname: e.target.value })}
                  className={inputClass}
                />
                
                <input
                  type="text"
                  placeholder="Last Name"
                  value={newUser.lastname}
                  onChange={(e) => setNewUser({ ...newUser, lastname: e.target.value })}
                  className={inputClass}
                />
                
                <select
                  value={newUser.roles[0] || ''}
                  onChange={(e) => setNewUser({ ...newUser, roles: [e.target.value] })}
                  className={`${inputClass} ${isMobile ? 'h-12' : ''}`}
                >
                  <option value="">Select Role</option>
                  {roles.map((role) => (
                    <option key={role.id} value={role.id}>
                      {role.name}
                    </option>
                  ))}
                </select>
                
                <select
                  value={newUser.location_id}
                  onChange={(e) => setNewUser({ ...newUser, location_id: e.target.value })}
                  className={`${inputClass} ${isMobile ? 'h-12' : ''}`}
                >
                  <option value="">Select Location</option>
                  {authorizedLocations.map((location) => (
                    <option key={location.id} value={location.id}>
                      {location.name}
                    </option>
                  ))}
                </select>
              </div>
      
              {/* Submit Button */}
              <button
                onClick={handleSubmit}
                disabled={addingUser}
                className={`w-full p-4 rounded-lg mt-6 font-medium transition-all duration-300 
                  ${addUserSuccess ? 'bg-green-500 text-white' : 'bg-blue-600 text-white hover:bg-blue-700'}
                  ${addingUser ? 'opacity-50 cursor-not-allowed' : 'hover:shadow-lg'}
                  ${isMobile ? 'text-lg' : 'text-base'}`}
              >
                {addingUser ? 'Adding...' : addUserSuccess ? 'User Added Successfully' : 'Add User'}
              </button>
      
              {/* Error Message */}
              {addUserError && (
                <div className="mt-4 p-3 bg-red-100 dark:bg-red-900/50 rounded-lg">
                  <p className="text-red-600 dark:text-red-200 text-sm">
                    {addUserError}
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      );
};

export default AddUserForm;