import {
  createSlice,
  createAsyncThunk,
  createSelector,
  createAction
} from "@reduxjs/toolkit";
import {
  getGoogleReviews,
  getStaffMentions,
  getMenuInsights,
  approveReviewResponse,
  regenerateReviewResponseGPT,
  updateReviewResponse as updateReviewResponseApi
} from "../../services/api/apiUtility";
import { 
  startOfMonth, 
  endOfMonth, 
  subMonths,
  format,
  subDays
} from "date-fns";

// Define initial state
const today = new Date();
const thisMonth = {
  startDate: format(startOfMonth(today), 'yyyy-MM-dd'),
  endDate: format(today, 'yyyy-MM-dd')
};

const lastMonth = {
  startDate: format(startOfMonth(subMonths(today, 1)), 'yyyy-MM-dd'),
  endDate: format(endOfMonth(subMonths(today, 1)), 'yyyy-MM-dd')
};

const initialState = {
  currentLocationId: null,
  trendData: [
    { title: "Total Reviews", value: "419", change: 5.2 },
    { title: "Average Rating", value: "4.3", change: 0.2 },
    { title: "Positive Reviews", value: "83%", change: 2.1 },
  ],
  reviews: {
    data: [],
    hasMore: true,
    loading: false,
    approvingReviews: {},
    error: null,
    lastUpdated: null,
  },
  staffMentions: {
    data: [],
    hasMore: true,
    loading: false,
    error: null,
    lastUpdated: null,
  },
  menuItems: {
    data: [],
    hasMore: true,
    loading: false,
    error: null,
    lastUpdated: null,
  },
  processedData: [],
  dateRanges: {
    current: {
      startDate: format(subDays(new Date(), 6), 'yyyy-MM-dd'),
      endDate: format(new Date(), 'yyyy-MM-dd')
    },
    comparison: {
      startDate: format(subDays(new Date(), 13), 'yyyy-MM-dd'),
      endDate: format(subDays(new Date(), 7), 'yyyy-MM-dd')
    }
  },
  metrics: {
    current: null,
    comparison: null,
    loading: false,
    error: null
  },
  trendReviews: {
    current: [],
    comparison: [],
    loading: false,
    error: null
  },
  trendStaff: {
    current: [],
    comparison: [],
    loading: false,
    error: null
  },
  trendMenu: {
    current: [],
    comparison: [],
    loading: false,
    error: null
  }
};


export const fetchDashboardData = createAsyncThunk(
  "dashboard/fetchDashboardData",
  async ({ locationId, offset = 0, limit = 50, dateFilters }, { getState }) => {
    const state = getState();
    const { accessToken } = state.auth;
    const { selectedLocation } = state.location;

    if (!accessToken) throw new Error("Access token required");

    try {
      // Use 'all' endpoint for superadmin or when all locations are selected
      const effectiveLocationId = (!locationId || 
                                 locationId === "all" || 
                                 selectedLocation?.name === "All") ? 'all' : locationId;

      // Single API call for all data
      const [reviews, staff, menu] = await Promise.all([
        getGoogleReviews(
          effectiveLocationId,
          offset,
          limit,
          accessToken,
          dateFilters.reviews.startDate,
          dateFilters.reviews.endDate
        ),
        getStaffMentions(
          effectiveLocationId,
          offset,
          limit,
          accessToken,
          dateFilters.staff.startDate,
          dateFilters.staff.endDate
        ),
        getMenuInsights(
          effectiveLocationId,
          offset,
          limit,
          accessToken,
          dateFilters.menu.startDate,
          dateFilters.menu.endDate
        )
      ]);

      return {
        reviews: reviews.status === 'success' ? reviews.data : [],
        staffMentions: staff.status === 'success' ? staff.data : [],
        menuItems: menu.status === 'success' ? menu.data : [],
        locationId: effectiveLocationId,
        offset,
        hasMore: [reviews, staff, menu].some(response => 
          response.status === 'success' && response.data.length === limit
        )
      };
    } catch (error) {
      if (error.response?.status === 403) {
        throw new Error("Authentication expired or invalid");
      }
      throw error;
    }
  }
);



// Add approveReview thunk
export const approveReview = createAsyncThunk(
  'dashboard/approveReview',
  async ({ locationId, reviewId, accessToken }, { rejectWithValue }) => {
    try {
      const response = await approveReviewResponse(locationId, reviewId, accessToken);
      
      if (response.status === 'success') {
        return { reviewId, status: 'approved', location_id: locationId };
      }
      
      throw new Error(response.message || 'Failed to approve review');
    } catch (error) {
      console.error('Error approving review:', error);
      return rejectWithValue(error.message || 'Failed to approve review');
    }
  }
);

export const updateReviewResponse = createAsyncThunk(
  'dashboard/updateReviewResponse',
  async ({ locationId, reviewId, response, accessToken }, { rejectWithValue }) => {
    try {
      const result = await updateReviewResponseApi(  // <-- Use renamed function
        locationId,
        reviewId,
        response,
        accessToken
      );
      
      if (result.status === 'success') {
        return { reviewId, response, locationId };
      }
      throw new Error(result.message || 'Failed to update review response');
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const regenerateReview = createAsyncThunk(
  'dashboard/regenerateReview',
  async ({ locationId, reviewId, accessToken }, { rejectWithValue }) => {
    try {
      const result = await regenerateReviewResponseGPT(
        locationId,
        reviewId,
        accessToken
      );

      if (result.status === 'success') {
        return { 
          reviewId, 
          locationId,
          response: result.data.response 
        };
      }
      throw new Error(result.message || 'Failed to regenerate response');
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchTrendReviews = createAsyncThunk(
  'dashboard/fetchTrendReviews',
  async (_, { getState }) => {
    const state = getState();
    const { accessToken } = state.auth;
    const { selectedLocation } = state.location;
    const { dateRanges } = state.dashboard;

    if (!accessToken) throw new Error("Access token required");

    try {
      // Get reviews for both current and comparison periods
      const [currentReviews, comparisonReviews] = await Promise.all([
        getGoogleReviews(
          selectedLocation?.id || 'all',
          0,
          1000, // Increased limit to get more reviews
          accessToken,
          dateRanges.current.startDate,
          dateRanges.current.endDate
        ),
        getGoogleReviews(
          selectedLocation?.id || 'all',
          0,
          1000,
          accessToken,
          dateRanges.comparison.startDate,
          dateRanges.comparison.endDate
        )
      ]);

      return {
        current: currentReviews.data || [],
        comparison: comparisonReviews.data || []
      };
    } catch (error) {
      throw error;
    }
  }
);

export const fetchTrendStaff = createAsyncThunk(
  'dashboard/fetchTrendStaff',
  async (_, { getState }) => {
    const state = getState();
    const { accessToken } = state.auth;
    const { selectedLocation } = state.location;
    const { dateRanges } = state.dashboard;

    if (!accessToken) throw new Error("Access token required");

    try {
      const [currentStaff, comparisonStaff] = await Promise.all([
        getStaffMentions(
          selectedLocation?.id || 'all',
          0,
          1000,
          accessToken,
          dateRanges.current.startDate,
          dateRanges.current.endDate
        ),
        getStaffMentions(
          selectedLocation?.id || 'all',
          0,
          1000,
          accessToken,
          dateRanges.comparison.startDate,
          dateRanges.comparison.endDate
        )
      ]);

      return {
        current: currentStaff.data || [],
        comparison: comparisonStaff.data || []
      };
    } catch (error) {
      throw error;
    }
  }
);

export const fetchTrendMenu = createAsyncThunk(
  'dashboard/fetchTrendMenu',
  async (_, { getState }) => {
    const state = getState();
    const { accessToken } = state.auth;
    const { selectedLocation } = state.location;
    const { dateRanges } = state.dashboard;

    if (!accessToken) throw new Error("Access token required");

    try {
      const [currentMenu, comparisonMenu] = await Promise.all([
        getMenuInsights(
          selectedLocation?.id || 'all',
          0,
          1000,
          accessToken,
          dateRanges.current.startDate,
          dateRanges.current.endDate
        ),
        getMenuInsights(
          selectedLocation?.id || 'all',
          0,
          1000,
          accessToken,
          dateRanges.comparison.startDate,
          dateRanges.comparison.endDate
        )
      ]);

      return {
        current: currentMenu.data || [],
        comparison: comparisonMenu.data || []
      };
    } catch (error) {
      throw error;
    }
  }
);

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    clearDashboardData: (state) => {
      return { ...initialState, trendData: state.trendData };
    },
    locationChanged: (state, action) => {
      const newLocationId = action.payload;
      if (state.currentLocationId !== newLocationId) {
        state.currentLocationId = newLocationId;

        // Reset pagination state
        state.reviews.hasMore = true;
        state.staffMentions.hasMore = true;
        state.menuItems.hasMore = true;

        // Clear existing data to force refresh
        state.reviews.data = [];
        state.staffMentions.data = [];
        state.menuItems.data = [];
      }
    },
    setProcessedData: (state, action) => {
      state.processedData = action.payload;
    },
    updateTrendData: (state, action) => {
      state.trendData = action.payload;
    },
    setDateRanges: (state, action) => {
      state.dateRanges = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDashboardData.pending, (state) => {
        state.reviews.loading = true;
        state.staffMentions.loading = true;
        state.menuItems.loading = true;
        state.reviews.error = null;
        state.staffMentions.error = null;
        state.menuItems.error = null;
      })
      .addCase(fetchDashboardData.fulfilled, (state, action) => {
        console.log('Dashboard data received:', action.payload);
        const { reviews, staffMentions, menuItems, offset, hasMore } = action.payload;
        
        // Update all data sections
        if (offset === 0) {
          state.reviews.data = reviews;
          state.staffMentions.data = staffMentions;
          state.menuItems.data = menuItems;
        } else {
          state.reviews.data = [...state.reviews.data, ...reviews];
          state.staffMentions.data = [...state.staffMentions.data, ...staffMentions];
          state.menuItems.data = [...state.menuItems.data, ...menuItems];
        }

        // Update loading and status
        state.reviews.loading = false;
        state.staffMentions.loading = false;
        state.menuItems.loading = false;
        state.reviews.hasMore = hasMore;
        state.staffMentions.hasMore = hasMore;
        state.menuItems.hasMore = hasMore;
        state.reviews.lastUpdated = Date.now();
        state.staffMentions.lastUpdated = Date.now();
        state.menuItems.lastUpdated = Date.now();
      })
      .addCase(fetchDashboardData.rejected, (state, action) => {
        state.reviews.loading = false;
        state.staffMentions.loading = false;
        state.menuItems.loading = false;
        state.reviews.error = action.error.message;
        state.staffMentions.error = action.error.message;
        state.menuItems.error = action.error.message;
      });

      builder.addCase(approveReview.pending, (state, action) => {
        const { reviewId } = action.meta.arg;
        state.reviews.approvingReviews[reviewId] = true;
      })
      .addCase(approveReview.fulfilled, (state, action) => {
        const { reviewId } = action.meta.arg;
        delete state.reviews.approvingReviews[reviewId];
        state.reviews.data = state.reviews.data.map(review =>
          review.id === reviewId
            ? { 
                ...review, 
                response_approved: true,
                status: 'approved', 
                approved_date: new Date().toISOString()
              }
            : review
        );
      })
      .addCase(approveReview.rejected, (state, action) => {
        const { reviewId } = action.meta.arg;
        delete state.reviews.approvingReviews[reviewId];
        state.reviews.error = action.error.message;
      });

      builder.addCase(updateReviewResponse.pending, (state) => {
        state.reviews.loading = true;
        state.reviews.error = null;
      })
      builder.addCase(updateReviewResponse.fulfilled, (state, action) => {
        const { reviewId, response } = action.payload;
        state.reviews.data = state.reviews.data.map(review => 
          review.id === reviewId 
            ? { 
                ...review, 
                response,
                status: 'edited'
              }
            : review
        );
        state.reviews.loading = false;
      })
      builder.addCase(updateReviewResponse.rejected, (state, action) => {
        state.reviews.loading = false;
        state.reviews.error = action.payload || 'Failed to update review';
      });
      // builder.addCase(regenerateReview.fulfilled, (state, action) => {
      //   const { reviewId, response } = action.payload;
      //   state.reviews.data = state.reviews.data.map(review => 
      //     review.id === reviewId 
      //       ? { ...review, response }
      //       : review
      //   );
      // });
      builder.addCase(fetchMetrics.pending, (state) => {
        state.metrics.loading = true;
        state.metrics.error = null;
      })
      builder.addCase(fetchMetrics.fulfilled, (state, action) => {
        state.metrics.current = action.payload.current;
        state.metrics.comparison = action.payload.comparison;
        state.metrics.loading = false;
      })
      builder.addCase(fetchMetrics.rejected, (state, action) => {
        state.metrics.loading = false;
        state.metrics.error = action.error.message;
      });
      
      builder
        .addCase(fetchTrendReviews.pending, (state) => {
          state.trendReviews.loading = true;
          state.trendReviews.error = null;
        })
        .addCase(fetchTrendReviews.fulfilled, (state, action) => {
          state.trendReviews.current = action.payload.current;
          state.trendReviews.comparison = action.payload.comparison;
          state.trendReviews.loading = false;
        })
        .addCase(fetchTrendReviews.rejected, (state, action) => {
          state.trendReviews.loading = false;
          state.trendReviews.error = action.error.message;
        });

      builder
        .addCase(fetchTrendStaff.pending, (state) => {
          state.trendStaff.loading = true;
        state.trendStaff.error = null;
      })
      .addCase(fetchTrendStaff.fulfilled, (state, action) => {
        state.trendStaff.current = action.payload.current;
        state.trendStaff.comparison = action.payload.comparison;
        state.trendStaff.loading = false;
      })
      .addCase(fetchTrendStaff.rejected, (state, action) => {
        state.trendStaff.loading = false;
        state.trendStaff.error = action.error.message;
      })
      
      .addCase(fetchTrendMenu.pending, (state) => {
        state.trendMenu.loading = true;
        state.trendMenu.error = null;
      })
      .addCase(fetchTrendMenu.fulfilled, (state, action) => {
        state.trendMenu.current = action.payload.current;
        state.trendMenu.comparison = action.payload.comparison;
        state.trendMenu.loading = false;
      })
      .addCase(fetchTrendMenu.rejected, (state, action) => {
        state.trendMenu.loading = false;
        state.trendMenu.error = action.error.message;
      });
  },
});

// Actions
export const {
  clearDashboardData,
  locationChanged,
  setProcessedData,
  updateTrendData,
} = dashboardSlice.actions;

// Basic Selectors
export const selectDashboardState = (state) => state.dashboard;
export const selectCurrentLocationId = (state) =>
  state.dashboard.currentLocationId;
export const selectReviews = (state) => state.dashboard.reviews;
export const selectStaffMentions = (state) => state.dashboard.staffMentions;
export const selectMenuItems = (state) => state.dashboard.menuItems;
export const selectTrendData = (state) => state.dashboard.trendData;

// Complex Selectors
export const selectFilteredReviews = createSelector(
  [selectReviews, selectCurrentLocationId],
  (reviews, currentLocationId) => {
    if (!currentLocationId || currentLocationId === "all") {
      return reviews.data;
    }
    return reviews.data.filter(
      (review) => review.location_id === currentLocationId
    );
  }
);
export const selectFilteredStaffMentions = createSelector(
  [selectStaffMentions, selectCurrentLocationId],
  (staffMentions, currentLocationId) => {
    if (!currentLocationId || currentLocationId === "all") {
      return staffMentions.data;
    }
    return staffMentions.data.filter(
      (mention) => mention.location_id === currentLocationId
    );
  }
);
export const selectFilteredMenuItems = createSelector(
  [selectMenuItems, selectCurrentLocationId],
  (menuItems, currentLocationId) => {
    if (!currentLocationId || currentLocationId === "all") {
      return menuItems.data;
    }
    return menuItems.data.filter(
      (item) => item.location_id === currentLocationId
    );
  }
);

export const selectProcessedData = createSelector(
  [selectReviews, selectCurrentLocationId],
  (reviews, locationId) => {
    if (!reviews.data.length) return [];

    const processedData = [];
    const reviewsByLocation = {};

    // Group reviews by location
    reviews.data.forEach((review) => {
      const locationKey = review.location_id || "unknown";
      if (!reviewsByLocation[locationKey]) {
        reviewsByLocation[locationKey] = {
          totalReviews: 0,
          sumRating: 0,
          positiveReviews: 0,
          negativeReviews: 0,
        };
      }

      const stats = reviewsByLocation[locationKey];
      stats.totalReviews++;
      stats.sumRating += review.rating;

      if (review.rating >= 4) {
        stats.positiveReviews++;
      } else if (review.rating <= 2) {
        stats.negativeReviews++;
      }
    });

    // Convert stats to processed data format
    Object.entries(reviewsByLocation).forEach(([locId, stats]) => {
      processedData.push({
        locationId: locId,
        totalReviews: stats.totalReviews,
        avgRating: stats.sumRating / stats.totalReviews,
        positiveReviews: stats.positiveReviews,
        negativeReviews: stats.negativeReviews,
      });
    });

    return processedData;
  }
);

export const selectStaffMentionsLoading = createSelector(
  [selectStaffMentions],
  (staffMentions) => staffMentions.loading
);

export const selectStaffMentionsHasMore = createSelector(
  [selectStaffMentions],
  (staffMentions) => staffMentions.hasMore
);

export const selectStaffMentionsError = createSelector(
  [selectStaffMentions],
  (staffMentions) => staffMentions.error
);

// Loading States Selector
export const selectDashboardLoading = createSelector(
  [selectReviews, selectStaffMentions, selectMenuItems],
  (reviews, staffMentions, menuItems) => {
    return reviews.loading || staffMentions.loading || menuItems.loading;
  }
);

// Combined Error Selector
export const selectDashboardError = createSelector(
  [selectReviews, selectStaffMentions, selectMenuItems],
  (reviews, staffMentions, menuItems) => {
    return reviews.error || staffMentions.error || menuItems.error;
  }
);

export const selectReviewsLoading = createSelector(
  [selectReviews],
  (reviews) => reviews.loading
);

export const setDateRanges = createAction('dashboard/setDateRanges');

export const fetchMetrics = createAsyncThunk(
  'dashboard/fetchMetrics',
  async (_, { getState }) => {
    const state = getState();
    const { dateRanges, reviews, staffMentions, menuItems } = state.dashboard;

    const calculatePeriodMetrics = (startDate, endDate) => {
      // Convert dates once for better performance
      const start = new Date(startDate);
      const end = new Date(endDate);
    
      // Filter data for the period
      const periodReviews = reviews.data.filter(review => {
        const reviewDate = new Date(review.review_date);
        return reviewDate >= start && reviewDate <= end;
      });
    
      const totalReviews = periodReviews.length;
      const averageRating = totalReviews > 0
        ? periodReviews.reduce((sum, review) => sum + Number(review.rating), 0) / totalReviews
        : 0;
      const positiveReviews = periodReviews.filter(review => Number(review.rating) >= 4).length;
    
      return {
        totalReviews,
        averageRating: Number(averageRating.toFixed(1)),
        positiveRate: totalReviews > 0 ? (positiveReviews / totalReviews) * 100 : 0
      };
    };

    return {
      current: calculatePeriodMetrics(
        dateRanges.current.startDate,
        dateRanges.current.endDate
      ),
      comparison: calculatePeriodMetrics(
        dateRanges.comparison.startDate,
        dateRanges.comparison.endDate
      )
    };
  }
);

// Add selectors
export const selectDateRanges = state => state.dashboard.dateRanges;
export const selectMetrics = state => state.dashboard.metrics;
export const selectTrendReviews = state => state.dashboard.trendReviews;
export const selectTrendStaff = state => state.dashboard.trendStaff;
export const selectTrendMenu = state => state.dashboard.trendMenu;

export const selectReviewApproving = (state, reviewId) => 
  state.dashboard.reviews.approvingReviews[reviewId] || false;

export default dashboardSlice.reducer;