// src/utils/navigationConfig.js
import { 
    Star,
    BarChart2,
    Users,
    Calendar,
    Settings,
    FileText,
    TrendingUp,
    Utensils
  } from 'lucide-react';
  
  export const getNavigationItems = (userRole) => {
    const baseItems = [
      { 
        icon: TrendingUp, 
        label: 'Trends',
        path: '/dashboard/trends',
        description: 'Overview & Metrics'
      }
    ];
  
    const roleNavigation = {
      SuperAdmin: [
        { icon: BarChart2, label: 'Analytics', path: '/dashboard/graphs', description: 'Performance Insights' },
        { icon: Star, label: 'Reviews', path: '/dashboard/reviews', description: 'Customer Feedback' },
        { icon: Users, label: 'Staff', path: '/dashboard/staff', description: 'Team Management' },
        { icon: Utensils, label: 'Menu', path: '/dashboard/menu', description: 'Menu Insights' },
        { icon: FileText, label: 'Reports', path: '/dashboard/reports', description: 'Generated Reports' },
        { icon: Calendar, label: 'Reservations', path: '/dashboard/reservations', description: 'Booking Management' },
        { icon: Settings, label: 'Settings', path: '/dashboard/administration', description: 'System Configuration' },
      ],
      Admin: [
        { icon: Star, label: 'Reviews', path: '/dashboard/reviews', description: 'Customer Feedback' },
        { icon: Users, label: 'Staff', path: '/dashboard/staff', description: 'Team Management' },
        { icon: Utensils, label: 'Menu', path: '/dashboard/menu', description: 'Menu Insights' },
        { icon: BarChart2, label: 'Analytics', path: '/dashboard/graphs', description: 'Performance Insights' },
        { icon: FileText, label: 'Reports', path: '/dashboard/reports', description: 'Generated Reports' },
        { icon: Calendar, label: 'Reservations', path: '/dashboard/reservations', description: 'Booking Management' },
        { icon: Settings, label: 'Settings', path: '/dashboard/administration', description: 'System Configuration' },
      ],
      Manager: [
        { icon: Star, label: 'Reviews', path: '/dashboard/reviews', description: 'Customer Feedback' },
        { icon: BarChart2, label: 'Analytics', path: '/dashboard/graphs', description: 'Performance Insights' },
        { icon: Users, label: 'Staff', path: '/dashboard/staff', description: 'Team Management' },
      ],
      BOH: [
        { icon: Utensils, label: 'Menu', path: '/dashboard/menu', description: 'Menu Management' },
        { icon: FileText, label: 'Reports', path: '/dashboard/reports', description: 'View Reports' },
      ],
    };
  
    return [...baseItems, ...(roleNavigation[userRole] || [])];
  };
  