import React, { createContext, useContext, useState, useCallback } from 'react';
import dayjs from 'dayjs';

const DateFilterContext = createContext();

export const DateFilterProvider = ({ children }) => {
  const [dateFilters, setDateFilters] = useState({
    graphs: {
      startDate: dayjs().subtract(30, 'day').format('YYYY-MM-DD'),
      endDate: dayjs().format('YYYY-MM-DD')
    },
    reviews: {
      startDate: dayjs().subtract(30, 'day').format('YYYY-MM-DD'),
      endDate: dayjs().format('YYYY-MM-DD')
    },
    staff: {
      startDate: dayjs().subtract(30, 'day').format('YYYY-MM-DD'),
      endDate: dayjs().format('YYYY-MM-DD')
    },
    menu: {
      startDate: dayjs().subtract(30, 'day').format('YYYY-MM-DD'),
      endDate: dayjs().format('YYYY-MM-DD')
    }
  });

  const handleDateChange = useCallback((section, type, value) => {
    setDateFilters(prev => ({
      ...prev,
      [section]: {
        ...prev[section],
        [type]: value
      }
    }));
  }, []);

  const clearDateFilter = useCallback((section) => {
    setDateFilters(prev => ({
      ...prev,
      [section]: {
        startDate: dayjs().subtract(30, 'day').format('YYYY-MM-DD'),
        endDate: dayjs().format('YYYY-MM-DD')
      }
    }));
  }, []);

  return (
    <DateFilterContext.Provider 
      value={{ 
        dateFilters, 
        handleDateChange, 
        clearDateFilter 
      }}
    >
      {children}
    </DateFilterContext.Provider>
  );
};

export const useDateFilter = (section) => {
  const context = useContext(DateFilterContext);
  if (!context) {
    throw new Error('useDateFilter must be used within a DateFilterProvider');
  }

  return {
    dateFilter: context.dateFilters[section],
    handleDateChange: (type, value) => context.handleDateChange(section, type, value),
    clearDateFilter: () => context.clearDateFilter(section),
    onDateChange: (type, value) => context.handleDateChange(section, type, value)
  };
};