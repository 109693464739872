import React from 'react';
import { CheckCircle, MessageSquare, Loader2, Send, Edit } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import { 
  selectReviewsLoading,
  selectReviewApproving,
  approveReview 
} from '../../../store/slices/dashboardSlice';
import { selectAccessToken } from '../../../store/slices/authSlice';
import { cn } from '../../../components/lib/utils';

// Alternative color scheme
const STATUS_CONFIG = {
  approved: {
    color: 'bg-amber-400',
    icon: CheckCircle,
    tooltip: 'Approved - Pending Post',
  },
  posted: {
    color: 'bg-emerald-500',
    icon: Send,
    tooltip: 'Posted to Google',
  },
  edited: {
    color: 'bg-sky-500',
    icon: Edit,
    tooltip: 'Edited',
  },
};

const ReviewActionCell = ({ review, onOpenModal }) => {
  const dispatch = useDispatch();
  const isApproving = useSelector(state => selectReviewApproving(state, review.id));
  const accessToken = useSelector(selectAccessToken);

  const statusConfig = STATUS_CONFIG[review.status];

  const handleApproveClick = async (e) => {
    e.stopPropagation();
    try {
      await dispatch(approveReview({
        locationId: review.location_id,
        reviewId: review.id,
        accessToken
      })).unwrap();
    } catch (error) {
      console.error('Error approving review:', error);
    }
  };

  return (
    <div className="flex items-center space-x-2">
      {review.response && (
        <div className="relative">
          {/* Status Indicator */}
          {statusConfig && (
            <div
              className={cn(
                "absolute -top-2 -right-2 flex items-center justify-center w-5 h-5 rounded-full border-2 border-white dark:border-gray-800 z-10 transition-colors duration-200 group/status",
                statusConfig.color
              )}
            >
              <statusConfig.icon 
                size={12} 
                className="text-white"
              />
              {/* Tooltip for status */}
              <span className="absolute -top-8 left-1/2 transform -translate-x-1/2 
                bg-black text-white text-xs py-1 px-2 rounded opacity-0 
                group-hover/status:opacity-100 transition-opacity whitespace-nowrap">
                {statusConfig.tooltip}
              </span>
            </div>
          )}
          
          <button
            onClick={handleApproveClick}
            disabled={isApproving}
            className={cn(
              "p-2 rounded-full transition-colors duration-200",
              "hover:bg-green-100 dark:hover:bg-green-900/30",
              "text-green-600 dark:text-green-400",
              "disabled:opacity-50 disabled:cursor-not-allowed",
              "group relative",
              isApproving && "animate-pulse"
            )}
            title="Approve & Post Response"
          >
            {isApproving ? (
              <Loader2 size={20} className="animate-spin" />
            ) : (
              <CheckCircle size={20} />
            )}
            <span className="absolute -top-8 left-1/2 transform -translate-x-1/2 
              bg-black text-white text-xs py-1 px-2 rounded opacity-0 
              group-hover:opacity-100 transition-opacity whitespace-nowrap">
              {isApproving ? 'Approving...' : 'Approve & Post'}
            </span>
          </button>
        </div>
      )}
      
      <button
        onClick={(e) => {
          e.stopPropagation();
          onOpenModal(review);
        }}
        className={cn(
          "p-2 rounded-full transition-colors duration-200",
          "hover:bg-blue-100 dark:hover:bg-blue-900/30",
          "text-blue-600 dark:text-blue-400",
          "group relative"
        )}
        title="Edit Response"
      >
        <MessageSquare size={20} />
        <span className="absolute -top-8 left-1/2 transform -translate-x-1/2 
          bg-black text-white text-xs py-1 px-2 rounded opacity-0 
          group-hover:opacity-100 transition-opacity whitespace-nowrap">
          Edit Response
        </span>
      </button>
    </div>
  );
};

export default ReviewActionCell;