import React from 'react';
import { Bot, BookOpen, Clock, User, ChevronRight } from 'lucide-react';

const BlogPage = () => {
  const blogPosts = [
    {
      title: "Maximizing Restaurant Reviews: The Ultimate Guide to AI-Powered Responses",
      excerpt: "Learn how AI is revolutionizing the way restaurants handle customer feedback and improve their online presence.",
      author: "Sarah Wilson",
      date: "March 15, 2024",
      readTime: "5 min read",
      category: "AI & Technology"
    },
    {
      title: "5 Ways to Turn Negative Reviews into Growth Opportunities",
      excerpt: "Discover proven strategies for transforming critical feedback into actionable improvements for your restaurant.",
      author: "Michael Chen",
      date: "March 10, 2024",
      readTime: "4 min read",
      category: "Restaurant Management"
    },
    {
      title: "The Impact of Response Time on Customer Satisfaction",
      excerpt: "New study reveals how quick response times to reviews can significantly boost customer retention and ratings.",
      author: "Emily Rodriguez",
      date: "March 5, 2024",
      readTime: "6 min read",
      category: "Customer Experience"
    }
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section */}
      <div className="bg-white border-b">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
          <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-6 text-center">
            Insights for Restaurant Success
          </h1>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto text-center mb-12">
            Expert advice, industry trends, and practical tips to help your restaurant thrive in the digital age
          </p>
        </div>
      </div>

      {/* Blog Posts Grid */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {blogPosts.map((post) => (
            <article key={post.title} className="bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow duration-300">
              <div className="p-6">
                <div className="text-blue-600 text-sm font-medium mb-2">{post.category}</div>
                <h2 className="text-xl font-bold text-gray-900 mb-3 hover:text-blue-600 transition-colors">
                  {post.title}
                </h2>
                <p className="text-gray-600 mb-4">{post.excerpt}</p>
                <div className="flex items-center justify-between text-sm text-gray-500">
                  <div className="flex items-center">
                    <User size={16} className="mr-2" />
                    {post.author}
                  </div>
                  <div className="flex items-center">
                    <Clock size={16} className="mr-2" />
                    {post.readTime}
                  </div>
                </div>
              </div>
            </article>
          ))}
        </div>

        {/* Newsletter Section */}
        <div className="mt-16 bg-blue-600 rounded-xl p-8 text-white">
          <div className="max-w-2xl mx-auto text-center">
            <h3 className="text-2xl font-bold mb-4">Stay Updated</h3>
            <p className="mb-6">Get the latest insights and tips delivered straight to your inbox.</p>
            <div className="flex flex-col sm:flex-row gap-4 justify-center">
              <input
                type="email"
                placeholder="Enter your email"
                className="px-4 py-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-300 text-gray-900 w-full sm:w-auto"
              />
              <button className="px-6 py-3 bg-white text-blue-600 rounded-lg font-medium hover:bg-blue-50 transition-colors">
                Subscribe
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogPage;