import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
  loginUser, 
  refreshAuth, 
  selectAuth,
} from '../../../store/slices/authSlice';
import { setAuthInitialized } from '../../../store/slices/loadingSlice';
import { logout as logoutAction } from '../../../store/slices/authSlice';

export const useAuth = () => {
  const dispatch = useDispatch();
  const auth = useSelector(selectAuth);

  // Initial auth check
  useEffect(() => {
    const initializeAuth = async () => {
      try {
        if (!auth.authInitialized) {
          await dispatch(refreshAuth()).unwrap();
        }
      } catch (error) {
        console.error('Auth refresh failed:', error);
      } finally {
        // Always set authInitialized to true after attempting refresh
        dispatch(setAuthInitialized(true));
      }
    };

    initializeAuth();
  }, [dispatch, auth.authInitialized]);

  // Set up refresh interval
  useEffect(() => {
    if (!auth.accessTokenExpiresIn || !auth.accessToken) return;

    const interval = setInterval(() => {
      dispatch(refreshAuth());
    }, (auth.accessTokenExpiresIn * 1000) - 1000); // Refresh 1 second before expiration

    return () => clearInterval(interval);
  }, [auth.accessTokenExpiresIn, auth.accessToken, dispatch]);

  const login = async (email, password) => {
    try {
      await dispatch(loginUser({ email, password })).unwrap();
      return true;
    } catch (error) {
      console.error('Login failed:', error);
      return false;
    }
  };

  const handleLogout = async () => {
    try {
      await dispatch(logoutAction()).unwrap();
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  // Helper function to get dashboard links based on role
  const getDashboardLinks = useCallback((role) => {
    if (role === "Admin" || role === "SuperAdmin") {
      return [
        { to: "/dashboard/trends", label: "Trend" },
        { to: "/dashboard/graphs", label: "Graphs" },
        { to: "/dashboard/reviews", label: "Reviews" },
        { to: "/dashboard/staff", label: "Staff" },
        { to: "/dashboard/menu", label: "Menu" },
        { to: "/dashboard/reports", label: "Reports" },
        { to: "/dashboard/reservations", label: "Reservations" },
        { to: "/dashboard/administration", label: "Administration" },
      ];
    } else if (role === "Manager") {
      return [
        { to: "/dashboard/trends", label: "Trend" },
        { to: "/dashboard/graphs", label: "Graphs" },
        { to: "/dashboard/reviews", label: "Reviews" },
        { to: "/dashboard/staff", label: "Staff" },
      ];
    } else if (role === "BOH") {
      return [
        { to: "/dashboard/menu", label: "Menu" },
        { to: "/dashboard/reports", label: "Reports" },
      ];
    }
    return [];
  }, []);

  return {
    ...auth,
    login,
    logout: handleLogout,
    getDashboardLinks
  };
};