import axios from "axios";
import {
  API_ENDPOINTS,
  LIBRO_ENDPOINTS,
  getApiUrl,
  getLibroApiUrl,
  LIBRO_API_HEADERS,
} from "./config";

export const apiRequest = async (
  endpoint,
  method = "GET",
  data = null,
  params = {},
  headers = {}
) => {
  const url = getApiUrl(endpoint);
  const config = {
    method,
    url,
    headers: {
      "Content-Type": "application/json",
      ...headers,
    },
    params,
  };

  if (data) {
    config.data = data;
  }

  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.error("API request failed:", error);
    throw error;
  }
};

// Helper functions for common API operations
export const redirectToLoginPage = () =>
  (window.location = getApiUrl(API_ENDPOINTS.LOGIN_PAGE));

// Helper functions for common API operations
export const login = (username, password) =>
  apiRequest(API_ENDPOINTS.LOGIN, "POST", { username, password });

export const refreshSession = () =>
  apiRequest(API_ENDPOINTS.REFRESH, "POST", {}, {}, { credentials: "include" });

export const logoutSession = () =>
  apiRequest(API_ENDPOINTS.LOGOUT, "POST", {}, {}, { credentials: "include" });

export const getUsers = (token) =>
  apiRequest(
    API_ENDPOINTS.USERS,
    "GET",
    null,
    {},
    { Authorization: `Bearer ${token}` }
  );

export const getUser = (id, token) =>
  apiRequest(
    API_ENDPOINTS.USER.GET(id),
    "GET",
    null,
    {},
    { Authorization: `Bearer ${token}` }
  );

export const getLocations = (token) =>
  apiRequest(
    API_ENDPOINTS.LOCATION.ALL,
    "GET",
    null,
    {},
    { Authorization: `Bearer ${token}` }
  );

export const fetchRoles = async (token) => {
  try {
    const response = await apiRequest(
      API_ENDPOINTS.GET_ROLES,
      "GET",
      null,
      {},
      { Authorization: `Bearer ${token}` }
    );
    return response;
  } catch (error) {
    console.error("Error fetching roles:", error);
    throw error;
  }
};

export const createUser = (userData, accessToken) =>
  apiRequest(
    API_ENDPOINTS.USER.CREATE,
    "POST",
    {
      location_id: userData.location_id,
      email: userData.email,
      username: userData.username,
      firstname: userData.firstname,
      lastname: userData.lastname,
      roles: userData.roles,
    },
    {},
    { Authorization: `Bearer ${accessToken}` }
  );

// New functions for fetching reviews
export const getRegularReviews = (locationId, offset, limit, token) =>
  apiRequest(
    API_ENDPOINTS.LOCATION.REVIEWS.PAGINATED(locationId, offset, limit),
    "GET",
    null,
    {},
    { Authorization: `Bearer ${token}` }
  );

export const getGoogleReviews = async (
  locationId,
  offset,
  limit,
  token,
  startDate,
  endDate
) => {
  // Ensure we have a valid locationId, defaulting to 'all'
  const effectiveLocationId = locationId || 'all';
  
  const params = {
    startDate,
    endDate,
    offset,
    limit
  };

  try {
    const response = await apiRequest(
      API_ENDPOINTS.LOCATION.GOOGLE_REVIEWS.PAGINATED(effectiveLocationId, offset, limit),
      "GET",
      null,
      params,
      { Authorization: `Bearer ${token}` }
    );

    // Add error logging
    if (!response.data || response.data.length === 0) {
      console.log('No reviews data received for:', {
        locationId: effectiveLocationId,
        startDate,
        endDate
      });
    }

    return response;
  } catch (error) {
    console.error('Error fetching reviews:', error);
    throw error;
  }
};

// Function to get all reviews (both regular and Google)
export const getAllReviews = async (locationId, offset, limit, token) => {
  try {
    const [regularReviews, googleReviews] = await Promise.all([
      getRegularReviews(locationId, offset, limit, token),
      getGoogleReviews(locationId, offset, limit, token),
    ]);

    if (
      regularReviews.status === "success" &&
      googleReviews.status === "success"
    ) {
      return {
        status: "success",
        data: [...regularReviews.data, ...googleReviews.data],
      };
    } else {
      throw new Error("Failed to fetch all reviews");
    }
  } catch (error) {
    console.error("Error fetching all reviews:", error);
    throw error;
  }
};

export const getStaffMentions = (
  locationId,
  offset,
  limit,
  token,
  startDate,
  endDate
) => {
  const params = { startDate, endDate };

  const isRestaurantId = locationId?.startsWith('restaurant_');
  const actualId = isRestaurantId ? locationId.replace('restaurant_', '') : locationId;
  
  const endpoint = isRestaurantId
    ? API_ENDPOINTS.RESTAURANT.STAFF_MENTIONS.PAGINATED(actualId, offset, limit)
    : API_ENDPOINTS.LOCATION.STAFF_MENTIONS.PAGINATED(actualId, offset, limit);

  return apiRequest(
    endpoint,
    "GET",
    null,
    params,
    { Authorization: `Bearer ${token}` }
  );
};

export const getMenuInsights = (
  locationId,
  offset,
  limit,
  token,
  startDate,
  endDate
) => {
  const params = { startDate, endDate };

  const isRestaurantId = locationId?.startsWith('restaurant_');
  const actualId = isRestaurantId ? locationId.replace('restaurant_', '') : locationId;
  
  const endpoint = isRestaurantId
    ? API_ENDPOINTS.RESTAURANT.MENU_INSIGHTS.PAGINATED(actualId, offset, limit)
    : API_ENDPOINTS.LOCATION.MENU_INSIGHTS.PAGINATED(actualId, offset, limit);

  return apiRequest(
    endpoint,
    "GET",
    null,
    params,
    { Authorization: `Bearer ${token}` }
  );
};



// OpenAI API This needs to be removed and use the regenerateReviewResponseGPT function instead
export const regenerateReviewResponse = async (
  reviewData,
  instructions = ""
) => {
  const url = "https://api.openai.com/v1/chat/completions";
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
  };

  const data = {
    model: "ft:gpt-4o-2024-08-06:personal::AFUtvuv2",
    messages: [
      {
        role: "system",
        content:
          "Do not write 'reply:' and use the following instructions (if any): " +
          instructions,
      },
      {
        role: "user",
        content: `name: ${reviewData.customer_name} rating: ${reviewData.rating} location: ${reviewData.location} review: ${reviewData.review}`,
      },
    ],
  };

  try {
    const response = await axios.post(url, data, { headers });
    return response.data.choices[0].message.content;
  } catch (error) {
    console.error("Error calling GPT API:", error);
    if (error.response) {
      console.error("Response data:", error.response.data);
      console.error("Response status:", error.response.status);
      console.error("Response headers:", error.response.headers);
    }
    throw error;
  }
};

export const updateReviewResponse = async (
  locationId,
  reviewId,
  response,
  token
) => {
  try {
    const result = await apiRequest(
      API_ENDPOINTS.LOCATION.GOOGLE_REVIEWS.UPDATE_RESPONSE(
        locationId,
        reviewId
      ),
      "PUT",
      { response },
      {},
      { Authorization: `Bearer ${token}` }
    );
    return result;
  } catch (error) {
    console.error("Error updating review response:", error);
    throw error;
  }
};

export const regenerateReviewResponseGPT = async (
  locationId,
  reviewId,
  token
) => {
  try {
    // Add logging to verify the URL and parameters
    const endpoint = API_ENDPOINTS.LOCATION.GOOGLE_REVIEWS.REGENERATE_RESPONSE(locationId, reviewId);
    console.log('Regenerate endpoint:', endpoint);
    console.log('Parameters:', { locationId, reviewId, token });

    const result = await apiRequest(
      endpoint,
      "GET",
      null,
      {},
      { Authorization: `Bearer ${token}` }
    );
    return result;
  } catch (error) {
    console.error("Error regenerating review response:", error);
    // Enhance error logging
    console.error("Error details:", {
      status: error.response?.status,
      data: error.response?.data,
      url: error.config?.url
    });
    throw error;
  }
};

export const createRestaurant = async (restaurantData, token) => {
  try {
    const response = await apiRequest(
      API_ENDPOINTS.RESTAURANT.CREATE,
      'POST',
      {
        name: restaurantData.name,
        subscription_tier_id: restaurantData.subscription_tier_id,
        weekly_report_day: restaurantData.weekly_report_day
      },
      {},
      { Authorization: `Bearer ${token}` }
    );
    return response;
  } catch (error) {
    console.error('Error creating restaurant:', error);
    throw error;
  }
};

export const updateRestaurant = async (id, restaurantData, token) => {
  try {
    const response = await apiRequest(
      API_ENDPOINTS.RESTAURANT.UPDATE(id),
      'PUT',
      {
        name: restaurantData.name,
        subscription_tier_id: restaurantData.subscription_tier_id,
        weekly_report_day: restaurantData.weekly_report_day
      },
      {},
      { Authorization: `Bearer ${token}` }
    );
    return response;
  } catch (error) {
    console.error('Error updating restaurant:', error);
    throw error;
  }
};

export const deleteRestaurant = async (id, token) => {
  try {
    const response = await apiRequest(
      API_ENDPOINTS.RESTAURANT.DELETE(id),
      "DELETE",
      null,
      {},
      { Authorization: `Bearer ${token}` }
    );
    return response;
  } catch (error) {
    console.error("Error deleting restaurant:", error);
    throw error;
  }
};
export const updateRestaurantIntegrations = async (restaurantId, integrationData, token) => {
  try {
    const response = await apiRequest(
      API_ENDPOINTS.RESTAURANT.INTEGRATIONS(restaurantId),
      'PUT',
      integrationData,
      {},
      { Authorization: `Bearer ${token}` }
    );
    return response;
  } catch (error) {
    console.error('Error updating restaurant integrations:', error);
    throw error;
  }
};

export const approveReviewResponse = async (locationId, reviewId, token) => {
  try {
    const response = await apiRequest(
      API_ENDPOINTS.LOCATION.GOOGLE_REVIEWS.APPROVE_RESPONSE(locationId, reviewId),
      'PUT',
      null,
      {},
      { Authorization: `Bearer ${token}` }
    );
    return response;
  } catch (error) {
    console.error('Error approving review response:', error);
    throw error;
  }
};

export const deleteUser = async (userId, token) => {
  try {
    const response = await apiRequest(
      API_ENDPOINTS.USER.DELETE(userId),
      "DELETE",
      null,
      {},
      { Authorization: `Bearer ${token}` }
    );
    return response;
  } catch (error) {
    console.error("Error deleting user:", error);
    throw error;
  }
};

export const fetchUsers = async (token) => {
  try {
    const response = await apiRequest(
      API_ENDPOINTS.USERS,
      "GET",
      null,
      {},
      { Authorization: `Bearer ${token}` }
    );
    return response;
  } catch (error) {
    console.error("Error fetching users:", error);
    throw error;
  }
};

export const getRestaurant = (token) =>
  apiRequest(
    API_ENDPOINTS.RESTAURANT.ALL,
    "GET",
    null,
    {},
    { Authorization: `Bearer ${token}` }
  );

export const createLocation = async (locationData, token) => {
  try {
    const response = await apiRequest(
      "/location",
      "POST",
      locationData,
      {},
      { Authorization: `Bearer ${token}` }
    );
    return response;
  } catch (error) {
    console.error("Error creating location:", error);
    throw error;
  }
};

export const updateLocation = async (id, locationData, token) => {
  try {
    const response = await apiRequest(
      `/location/${id}`,
      "PUT",
      locationData,
      {},
      { Authorization: `Bearer ${token}` }
    );
    return response;
  } catch (error) {
    console.error("Error updating location:", error);
    throw error;
  }
};

export const deleteLocation = async (id, token) => {
  try {
    const response = await apiRequest(
      `/location/${id}`,
      "DELETE",
      null,
      {},
      { Authorization: `Bearer ${token}` }
    );
    return response;
  } catch (error) {
    console.error("Error deleting location:", error);
    throw error;
  }
};
// Libro API

let libroJWT = null;

const getLibroJWT = async () => {
  console.log("Attempting to get new JWT");
  try {
    const email = process.env.REACT_APP_LIBRO_EMAIL;
    const token = process.env.REACT_APP_LIBRO_TOKEN;

    if (!email || !token) {
      throw new Error("Libro credentials are not set in environment variables");
    }

    console.log("Sending authentication request to Libro API");
    const response = await axios.post(
      getLibroApiUrl(LIBRO_ENDPOINTS.AUTHENTICATION),
      { email, token },
      { headers: LIBRO_API_HEADERS }
    );

    // console.log("Authentication response received:", response.data);

    // Ensure you are correctly accessing the 'token' field
    if (response.data && response.data.token) {
      libroJWT = response.data.token;
      return libroJWT;
    } else {
      throw new Error("JWT not found in authentication response");
    }
  } catch (error) {
    console.error(
      "Error authenticating with Libro:",
      error.response ? error.response.data : error.message
    );
    console.error("Full error object:", error);
    throw error;
  }
};

export const fetchLibroReservations = async (params = {}) => {
  try {
    console.log("Fetching Libro JWT");
    const jwt = await getLibroJWT();
    console.log("JWT obtained, sending request for reservations");
    const response = await axios.get(getLibroApiUrl(LIBRO_ENDPOINTS.BOOKINGS), {
      params: {
        limit: 50,
        offset: params.offset || 0,
        include: "person", // Add this line to include person data
        ...params,
      },
      headers: {
        ...LIBRO_API_HEADERS,
        Authorization: `JWT ${jwt}`,
      },
    });
    console.log("Reservations response received:", response.data);
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching Libro reservations:",
      error.response ? error.response.data : error.message
    );
    console.error("Full error object:", error);
    throw error;
  }
};

export const testLibroAuthentication = async () => {
  try {
    console.log("Testing Libro authentication");
    const jwt = await getLibroJWT();
    console.log("Authentication successful. JWT obtained:", jwt);
    return true;
  } catch (error) {
    console.error(
      "Authentication failed:",
      error.response ? error.response.data : error.message
    );
    console.error("Full error object:", error);
    throw error;
  }
};

export const fetchLibroBookingDetails = async (bookingId) => {
  try {
    const jwt = await getLibroJWT();
    const response = await axios.get(
      getLibroApiUrl(LIBRO_ENDPOINTS.BOOKING(bookingId)),
      {
        headers: {
          ...LIBRO_API_HEADERS,
          Authorization: `JWT ${jwt}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(
      `Error fetching Libro booking details for ID ${bookingId}:`,
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

// Report functions
export const getRestaurantReports = (restaurantId, token) => 
  apiRequest(
    API_ENDPOINTS.RESTAURANT.REPORTS.GET(restaurantId),
    'GET',
    null,
    {},
    { Authorization: `Bearer ${token}` }
  );

export const createStaffReport = (restaurantId, dateRange, token) =>
  apiRequest(
    API_ENDPOINTS.RESTAURANT.REPORTS.CREATE_STAFF(restaurantId),
    'POST',
    dateRange,
    {},
    { Authorization: `Bearer ${token}` }
  );

export const createMenuReport = (restaurantId, dateRange, token) =>
  apiRequest(
    API_ENDPOINTS.RESTAURANT.REPORTS.CREATE_MENU(restaurantId),
    'POST',
    dateRange,
    {},
    { Authorization: `Bearer ${token}` }
  );

// Subscription tier functions
export const getSubscriptionTiers = (token) =>
  apiRequest(
    API_ENDPOINTS.SUBSCRIPTION_TIER.ALL,
    'GET',
    null,
    {},
    { Authorization: `Bearer ${token}` }
  );

export const createSubscriptionTier = (tierData, token) =>
  apiRequest(
    API_ENDPOINTS.SUBSCRIPTION_TIER.CREATE,
    'POST',
    tierData,
    {},
    { Authorization: `Bearer ${token}` }
  );

export const updateSubscriptionTier = (tierId, tierData, token) =>
  apiRequest(
    API_ENDPOINTS.SUBSCRIPTION_TIER.UPDATE(tierId),
    'PUT',
    tierData,
    {},
    { Authorization: `Bearer ${token}` }
  );

export const deleteSubscriptionTier = (tierId, token) =>
  apiRequest(
    API_ENDPOINTS.SUBSCRIPTION_TIER.DELETE(tierId),
    'DELETE',
    null,
    {},
    { Authorization: `Bearer ${token}` }
  );
