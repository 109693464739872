import React, { useContext } from 'react';
import { ThemeContext } from '../../../contexts/ThemeContext';
import { useAdministration } from '../hooks/useAdministration';
import AddUserForm from './AddUserForm';
import UserTable from './Usertable';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import { CheckCircle } from 'lucide-react';
import useMediaQuery from '../../../hooks/useMediaQuery';
import MobileUserCardList from './MobileUserCardList';
import { useSelector } from 'react-redux';
import { selectAuth } from '../../../store/slices/authSlice';

const Administration = () => {
  const { theme } = useContext(ThemeContext);
  const auth = useSelector(selectAuth);
  const isDark = theme === 'dark';
  const isMobile = useMediaQuery('(max-width: 768px)');
  const {
    users,
    roles,
    loading,
    error,
    deleteSuccess,
    deleteConfirmation,
    addUserError,
    addUserSuccess,
    searchTerm,
    setSearchTerm,
    handleAddUser,
    handleDeleteUser,
    setDeleteConfirmation,
    authorizedLocations
  } = useAdministration();

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4 bg-red-100 dark:bg-red-900/50 rounded-lg m-4">
        <p className="text-red-700 dark:text-red-200">{error}</p>
      </div>
    );
  }

  return (
    <div className={`min-h-screen ${isDark ? 'bg-gray-900 text-white' : 'bg-gray-100 text-black'}`}>
      <div className="container mx-auto px-4 py-8">
        {/* Success Toast */}
        {deleteSuccess && (
          <div className="fixed top-4 right-4 bg-green-500 text-white p-4 rounded-lg shadow-lg flex items-center animate-bounce">
            <CheckCircle size={24} className="mr-2" />
            User deleted successfully!
          </div>
        )}

        {/* Collapsible Add User Form */}
        <div className="mb-6">
          <AddUserForm
            roles={roles}
            authorizedLocations={authorizedLocations}
            addUserError={addUserError}
            addUserSuccess={addUserSuccess}
            handleAddUser={handleAddUser}
            isMobile={isMobile}
          />
        </div>

        {/* Responsive User List */}
        {isMobile ? (
          <MobileUserCardList
            users={users}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            setDeleteConfirmation={setDeleteConfirmation}
            authorizedLocations={authorizedLocations}
            currentUserEmail={auth.email}
          />
        ) : (
          <UserTable
            users={users}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            setDeleteConfirmation={setDeleteConfirmation}
            authorizedLocations={authorizedLocations}
            currentUserEmail={auth.email}
          />
        )}
      </div>

      {/* Delete Confirmation Modal */}
      {deleteConfirmation && (
        <DeleteConfirmationModal
          isDark={isDark}
          deleteConfirmation={deleteConfirmation}
          handleDeleteUser={handleDeleteUser}
          setDeleteConfirmation={setDeleteConfirmation}
        />
      )}
    </div>
  );
};

export default Administration;
