import React from 'react';
import PropTypes from 'prop-types';
import { TrendingUp, TrendingDown } from 'lucide-react';

const OverviewCard = ({ title, value, change, comparisonValue }) => {
  const isPositive = change >= 0;
  const changeAbs = Math.abs(change);

  return (
    <div className="p-6 bg-white dark:bg-gray-800 rounded-lg shadow-sm">
      <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400">
        {title}
      </h3>
      <div className="mt-2 flex items-baseline justify-between">
        <div>
          <p className="text-2xl font-semibold text-gray-900 dark:text-white">
            {value}
          </p>
          <div className="flex items-center mt-1 space-x-2">
            <span className="text-xs text-gray-500 dark:text-gray-400">
              vs
            </span>
            <span className="text-xs font-medium text-gray-600 dark:text-gray-300">
              {comparisonValue}
            </span>
          </div>
        </div>
        <p
          className={`flex flex-col items-end text-sm font-semibold ${
            isPositive ? 'text-green-600 dark:text-green-400' : 'text-red-600 dark:text-red-400'
          }`}
        >
          <span className="flex items-center">
            {isPositive ? (
              <TrendingUp className="self-center flex-shrink-0 h-4 w-4 text-green-500 dark:text-green-400 mr-1" />
            ) : (
              <TrendingDown className="self-center flex-shrink-0 h-4 w-4 text-red-500 dark:text-red-400 mr-1" />
            )}
            <span>{changeAbs.toFixed(1)}%</span>
          </span>
          <span className="text-xs text-gray-500 dark:text-gray-400 mt-1">
            Change
          </span>
        </p>
      </div>
    </div>
  );
};

OverviewCard.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  comparisonValue: PropTypes.string.isRequired,
  change: PropTypes.number.isRequired,
};

export default OverviewCard;