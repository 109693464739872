import React from 'react';
import { Users, Trash2, Search } from 'lucide-react';

const MobileUserCardList = ({
  users,
  searchTerm,
  setSearchTerm,
  setDeleteConfirmation,
  authorizedLocations,
  currentUserEmail
}) => {
  return (
    <div className="space-y-4">
      {/* Mobile Search */}
      <div className="sticky top-0 bg-gray-100 dark:bg-gray-900 p-4 -mx-4">
        <div className="relative">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
          <input
            type="text"
            placeholder="Search users..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full pl-10 pr-4 py-3 rounded-lg border bg-white dark:bg-gray-800
              dark:border-gray-700 dark:text-white focus:ring-2 focus:ring-blue-500"
          />
        </div>
      </div>

      {/* User Cards */}
      <div className="grid gap-4">
        {users.map((user) => (
          <div
            key={user.id}
            className="bg-white dark:bg-gray-800 rounded-lg shadow-sm p-4"
          >
            <div className="flex justify-between items-start mb-2">
              <div>
                <h3 className="font-medium">{user.username}</h3>
                <p className="text-sm text-gray-600 dark:text-gray-400">
                  {user.email}
                </p>
              </div>
              {user.email !== currentUserEmail && (
                <button
                  onClick={() => setDeleteConfirmation(user)}
                  className="text-red-500 p-2"
                >
                  <Trash2 size={20} />
                </button>
              )}
            </div>
            
            <div className="space-y-1 text-sm">
              <div className="flex justify-between">
                <span className="text-gray-600 dark:text-gray-400">Role:</span>
                <span>
                  {user.roles && user.roles.length > 0
                    ? user.roles.map((role) => role.name).join(", ")
                    : "N/A"}
                </span>
              </div>
              <div className="flex justify-between">
                <span className="text-gray-600 dark:text-gray-400">Location:</span>
                <span>
                  {authorizedLocations.find(
                    (loc) => loc.id === user.location_id
                  )?.name || "Unknown"}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MobileUserCardList;