import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { 
  X, 
  CheckCircle, 
  MessageSquare, 
  Star, 
  User,
  Wand2
} from "lucide-react";
import { ThemeContext } from "../../../contexts/ThemeContext";
import {
  selectAccessToken,
  selectSelectedRestaurant,
} from "../../../store/slices/authSlice";
import { 
  selectReviewsLoading,
  updateReviewResponse,
  regenerateReview
} from "../../../store/slices/dashboardSlice";
import { useReviewApproval } from "../hooks/useReviewApproval";

const ReviewResponseModal = ({
  isOpen,
  onClose,
  review,
  isRegenerating = false,
  setIsRegenerating = () => {},
}) => {
  const dispatch = useDispatch();
  const { theme } = useContext(ThemeContext);
  const isDark = theme === "dark";
  const [isSaving, setIsSaving] = useState(false);
  const [aiInstructions, setAiInstructions] = useState("");
  const [responseText, setResponseText] = useState("");
  const [error, setError] = useState(null);
  const [originalResponse, setOriginalResponse] = useState("");

  // Redux selectors
  const accessToken = useSelector(selectAccessToken);
  const selectedRestaurant = useSelector(selectSelectedRestaurant);
  const isLoading = useSelector(selectReviewsLoading);
  const handleApprove = useReviewApproval(onClose);

  useEffect(() => {
    if (review) {
      setResponseText(review.response || "");
      setOriginalResponse(review.response || "");
      setError(null);
    }
  }, [review]);

  const handleGenerateAIResponse = async () => {
    if (isRegenerating) return;
    
    try {
      setIsRegenerating(true);
      setError(null);
      
      const result = await dispatch(regenerateReview({
        locationId: review.location_id,
        reviewId: review.id,
        accessToken
      })).unwrap();
      
      setResponseText(result.response);
      setAiInstructions("");
    } catch (error) {
      setError("Error generating AI response: " + error.message);
      console.error("Error generating AI response:", error);
    } finally {
      setIsRegenerating(false);
    }
  };

  const handleSave = async () => {
    if (!responseText.trim() || isSaving) return;
    
    setIsSaving(true);
    setError(null);

    try {
      await dispatch(updateReviewResponse({
        locationId: review.location_id,
        reviewId: review.id,
        response: responseText,
        accessToken
      })).unwrap();
      
      setOriginalResponse(responseText);
      
      if (selectedRestaurant?.response_zapier_url) {
        await fetch(selectedRestaurant.response_zapier_url, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            reviewId: review.id,
            locationId: review.location_id,
            reviewContent: review.review,
            response: responseText,
            updateTime: new Date().toISOString(),
          }),
        });
      }

      onClose();
    } catch (error) {
      setError("Error saving response: " + error.message);
      console.error("Error saving response:", error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleModalClose = () => {
    setResponseText(originalResponse);
    onClose();
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      handleModalClose();
    }
  };

  if (!isOpen) return null;

  return (
    <div 
      className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4 backdrop-blur-sm"
      onClick={handleOverlayClick}
    >
      <div 
        className={`w-full max-w-2xl h-[90vh] md:h-auto md:max-h-[90vh] flex flex-col rounded-lg shadow-xl 
          ${isDark ? "bg-gray-900 border border-gray-700" : "bg-white"}
          transform transition-all duration-200`}
      >
        {/* Header - Fixed at top */}
        <div className={`flex-shrink-0 sticky top-0 ${
          isDark ? "bg-gray-900" : "bg-white"
        } p-4 border-b ${
          isDark ? "border-gray-700" : "border-gray-200"
        } flex justify-between items-center rounded-t-lg`}>
          <div className="flex items-center space-x-2">
            <MessageSquare className={isDark ? "text-gray-400" : "text-gray-500"} size={20} />
            <h2 className={`text-xl font-semibold ${isDark ? "text-gray-100" : "text-gray-900"}`}>
              Edit Response
            </h2>
          </div>
          <button
            onClick={handleModalClose}
            className={`p-2 rounded-full transition-colors ${
              isDark 
                ? "text-gray-400 hover:text-gray-300 hover:bg-gray-800" 
                : "text-gray-500 hover:text-gray-700 hover:bg-gray-100"
            }`}
          >
            <X size={20} />
          </button>
        </div>

        {/* Scrollable Content */}
        <div className="flex-1 overflow-y-auto">
          <div className="p-6 space-y-6">
            {error && (
              <div className="p-3 rounded-lg bg-red-100 dark:bg-red-900/50 text-red-700 dark:text-red-200">
                {error}
              </div>
            )}

            {/* Review Details */}
            <div className={`rounded-lg p-4 space-y-3 ${isDark ? "bg-gray-800" : "bg-gray-50"}`}>
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-2">
                  <User size={16} className={isDark ? "text-gray-400" : "text-gray-500"} />
                  <span className={`font-medium ${isDark ? "text-gray-200" : "text-gray-700"}`}>
                    {review?.customer_name || "Anonymous"}
                  </span>
                </div>
                <div className="flex items-center space-x-1">
                  <Star size={16} className="text-yellow-400 fill-current" />
                  <span className={isDark ? "text-gray-200" : "text-gray-700"}>
                    {review?.rating}
                  </span>
                </div>
              </div>
              <p className={`text-base ${isDark ? "text-gray-300" : "text-gray-600"}`}>
                {review?.review}
              </p>
            </div>

            {/* AI Instructions */}
            <div className="space-y-3">
              <label className={`block text-sm font-medium ${
                isDark ? "text-gray-200" : "text-gray-700"
              }`}>
                AI Instructions (Optional)
              </label>
              <textarea
                className={`w-full px-4 py-3 rounded-lg border transition-colors ${
                  isDark 
                    ? "bg-gray-800 border-gray-600 text-gray-200 focus:border-blue-500" 
                    : "bg-white border-gray-300 text-gray-900 focus:border-blue-400"
                } focus:ring-2 focus:ring-blue-500/20 focus:outline-none`}
                rows={3}
                placeholder="Enter specific instructions for AI response generation..."
                value={aiInstructions}
                onChange={(e) => setAiInstructions(e.target.value)}
              />
              <button
                onClick={handleGenerateAIResponse}
                disabled={isRegenerating}
                className={`w-full flex items-center justify-center gap-2 px-4 py-3 rounded-lg
                  transition-colors ${
                  isDark 
                    ? "bg-green-600 hover:bg-green-700 disabled:bg-green-800" 
                    : "bg-green-500 hover:bg-green-600 disabled:bg-green-300"
                  } text-white disabled:cursor-not-allowed`}
              >
                <Wand2 size={18} className={isRegenerating ? "animate-spin" : ""} />
                {isRegenerating ? "Generating..." : "Generate AI Response"}
              </button>
            </div>

            {/* Response Text */}
            <div className="space-y-3">
              <label className={`block text-sm font-medium ${
                isDark ? "text-gray-200" : "text-gray-700"
              }`}>
                Response
              </label>
              <textarea
                className={`w-full px-4 py-3 rounded-lg border transition-colors ${
                  isDark 
                    ? "bg-gray-800 border-gray-600 text-gray-200 focus:border-blue-500" 
                    : "bg-white border-gray-300 text-gray-900 focus:border-blue-400"
                } focus:ring-2 focus:ring-blue-500/20 focus:outline-none`}
                rows={6}
                value={responseText}
                onChange={(e) => setResponseText(e.target.value)}
              />
            </div>
          </div>
        </div>

        {/* Action Buttons - Fixed at bottom */}
        <div className={`flex-shrink-0 sticky bottom-0 ${
          isDark ? "bg-gray-900" : "bg-white"
        } p-4 border-t ${
          isDark ? "border-gray-700" : "border-gray-200"
        } flex flex-col sm:flex-row gap-2 sm:justify-end rounded-b-lg`}>
          <button
            onClick={handleModalClose}
            className={`px-4 py-2 rounded-lg transition-colors ${
              isDark 
                ? "bg-gray-800 text-gray-300 hover:bg-gray-700" 
                : "bg-gray-100 text-gray-700 hover:bg-gray-200"
            }`}
          >
            Cancel
          </button>
          <button
            onClick={() => handleApprove(review)}
            disabled={isLoading || isSaving}
            className={`flex items-center justify-center gap-2 px-4 py-2 rounded-lg
              transition-colors ${
              isDark 
                ? "bg-green-600 hover:bg-green-700 disabled:bg-green-800" 
                : "bg-green-500 hover:bg-green-600 disabled:bg-green-300"
              } text-white disabled:cursor-not-allowed`}
          >
            <CheckCircle size={18} />
            {isLoading ? "Approving..." : "Approve & Post"}
          </button>
          <button
            onClick={handleSave}
            disabled={isSaving || !responseText.trim()}
            className={`px-4 py-2 rounded-lg transition-colors ${
              isDark 
                ? "bg-blue-600 hover:bg-blue-700 text-white" 
                : "bg-blue-500 hover:bg-blue-600 text-white"
            } disabled:opacity-50 disabled:cursor-not-allowed`}
          >
            {isSaving ? "Saving..." : "Save Changes"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ReviewResponseModal);