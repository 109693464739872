import { useState, useEffect } from 'react';

const useMediaQuery = (query) => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    // Create a MediaQueryList object
    const mediaQueryList = window.matchMedia(query);

    // Update the state with the current value
    setMatches(mediaQueryList.matches);

    // Define a handler function
    const handler = (event) => {
      setMatches(event.matches);
    };

    // Add the listener
    mediaQueryList.addEventListener('change', handler);

    // Clean up the listener on unmount
    return () => {
      mediaQueryList.removeEventListener('change', handler);
    };
  }, [query]);

  return matches;
};

export default useMediaQuery;
