import React, { useContext, useMemo, useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { User, MessageSquare, Calendar, Search } from "lucide-react";
import DataTable, { zIndexConfig } from "../../../components/common/DataTable";
import { ThemeContext } from "../../../contexts/ThemeContext";
import { useDateFilter } from "../../../contexts/DateFilterContext";
import DateFilter from "../../../components/common/DateFilter";
import useMediaQuery from "../../../hooks/useMediaQuery";
import {
  selectFilteredStaffMentions,
  selectStaffMentionsLoading,
  selectStaffMentionsHasMore,
  locationChanged,
} from "../../../store/slices/dashboardSlice";
import {
  selectSelectedLocation,
} from "../../../store/slices/locationSlice";
import { useDispatch } from "react-redux";

const getSentimentColor = (sentiment, isDark) => {
  const baseColors = {
    positive: isDark
      ? "bg-green-800 text-green-100"
      : "bg-green-100 text-green-800",
    negative: isDark
      ? "bg-red-800 text-red-100"
      : "bg-red-100 text-red-800",
    mixed: isDark
      ? "bg-yellow-800 text-yellow-100"
      : "bg-yellow-100 text-yellow-800",
    default: isDark
      ? "bg-gray-700 text-gray-100"
      : "bg-gray-100 text-gray-800",
  };

  return baseColors[sentiment.toLowerCase()] || baseColors.default;
};

const StaffMentionsTable = ({ onLoadMore }) => {
  const { theme } = useContext(ThemeContext);
  const isDark = theme === "dark";
  const isMobile = useMediaQuery("(max-width: 768px)");
  const { dateFilter, handleDateChange } = useDateFilter("staff");
  const dispatch = useDispatch();
  const selectedLocation = useSelector(selectSelectedLocation);

  // Redux selectors
  const staffMentions = useSelector(selectFilteredStaffMentions);
  const isLoading = useSelector(selectStaffMentionsLoading);
  const hasMore = useSelector(selectStaffMentionsHasMore);

  // Update location in Redux when it changes
  useEffect(() => {
    if (selectedLocation) {
      dispatch(locationChanged(selectedLocation.id));
    }
  }, [selectedLocation, dispatch]);

  // Log for debugging
  useEffect(() => {
    console.log('Selected Location:', selectedLocation);
    console.log('Staff Mentions:', staffMentions);
  }, [selectedLocation, staffMentions]);

  // Add these new state hooks
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedSentiment, setSelectedSentiment] = useState("");

  // Add this memoized filtered data function
  const filteredStaffMentions = useMemo(() => {
    return staffMentions.filter(mention => {
      const matchesSearch = searchTerm === "" || 
        mention.staff_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        mention.snippet.toLowerCase().includes(searchTerm.toLowerCase());
      
      const matchesSentiment = selectedSentiment === "" || 
        mention.sentiment.toLowerCase() === selectedSentiment.toLowerCase();

      const matchesLocation = !selectedLocation?.id || 
        selectedLocation.id === "all" || 
        mention.location_id === selectedLocation.id;

      return matchesSearch && matchesSentiment && matchesLocation;
    });
  }, [staffMentions, searchTerm, selectedSentiment, selectedLocation]);

  // Mobile card renderer
  const MobileStaffCard = ({ mention }) => (
    <div
      className={`p-4 rounded-lg shadow-sm mb-4 ${
        isDark ? "bg-gray-800" : "bg-white"
      }`}
    >
      {/* Header with Staff Name and Sentiment */}
      <div className="flex justify-between items-start mb-3">
        <div className="flex items-center space-x-2">
          <User size={16} className="text-gray-400" />
          <span className={`font-medium ${isDark ? "text-gray-200" : "text-gray-900"}`}>
            {mention.staff_name}
          </span>
        </div>
        <span
          className={`px-2 py-1 text-xs font-semibold rounded-full ${getSentimentColor(
            mention.sentiment,
            isDark
          )}`}
        >
          {mention.sentiment}
        </span>
      </div>

      {/* Snippet Content */}
      <div className="space-y-2">
        <div className="flex items-start space-x-2">
          <MessageSquare size={16} className="text-gray-400 mt-1 flex-shrink-0" />
          <p className={`text-sm ${isDark ? "text-gray-300" : "text-gray-600"}`}>
            {mention.snippet}
          </p>
        </div>

        {/* Date */}
        <div className="flex items-center text-xs text-gray-500 dark:text-gray-400">
          <Calendar size={12} className="mr-1" />
          {new Date(mention.review_date).toLocaleDateString()}
        </div>
      </div>
    </div>
  );

  // Desktop columns configuration
  const columns = [
    { 
      key: "staff_name", 
      label: "Name(s)", 
      sortable: true, 
      width: "15%" 
    },
    { 
      key: "sentiment", 
      label: "Sentiment", 
      sortable: true, 
      width: "15%" 
    },
    { 
      key: "snippet", 
      label: "Snippet", 
      sortable: false, 
      width: "55%" 
    },
    { 
      key: "review_date", 
      label: "Mention Date", 
      sortable: true, 
      width: "15%" 
    },
  ];

  // Custom cell renderers for desktop view
  const customCellRenderers = {
    staff_name: (value) => (
      <div className={`flex items-center ${isDark ? "text-gray-200" : "text-gray-900"}`}>
        <User size={16} className="mr-2 text-gray-400" />
        {value}
      </div>
    ),
    sentiment: (value) => (
      <span
        className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${getSentimentColor(
          value,
          isDark
        )}`}
      >
        {value}
      </span>
    ),
    snippet: (value) => (
      <div className={`${isDark ? "text-gray-300" : "text-gray-600"} line-clamp-2`}>
        {value}
      </div>
    ),
    review_date: (value) => (
      <div className={`flex items-center ${isDark ? "text-gray-300" : "text-gray-600"}`}>
        <Calendar size={16} className="mr-2 text-gray-400" />
        {new Date(value).toLocaleDateString()}
      </div>
    ),
  };

  // Filters
  const filters = [
    {
      key: "sentiment",
      placeholder: "All Sentiments",
      options: ["positive", "negative", "mixed"].map((sentiment) => ({
        value: sentiment,
        label: sentiment.charAt(0).toUpperCase() + sentiment.slice(1),
      })),
      filterFunction: (item, value) =>
        item.sentiment.toLowerCase() === value.toLowerCase(),
    },
  ];

  const handleLoadMore = useCallback(() => {
    if (selectedLocation) {
      onLoadMore({
        locationId: selectedLocation.id,
        // any other params you need
      });
    }
  }, [onLoadMore, selectedLocation]);

  return (
    <div className="space-y-4">
      <div className={`relative z-${zIndexConfig.datePickerDropdown}`}>
        <DateFilter
          dateFilter={dateFilter}
          onDateChange={handleDateChange}
          className="mb-4"
        />
      </div>

      {isMobile ? (
        <div className="space-y-4">
          {/* Add Mobile Search and Filters */}
          <div className="sticky top-0 bg-gray-100 dark:bg-gray-900 p-4 rounded-lg shadow-sm">
            <div className="relative mb-4">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={16} />
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Search staff mentions..."
                className={`w-full pl-10 pr-4 py-2 rounded-lg border ${
                  isDark 
                    ? "bg-gray-800 border-gray-700 text-white" 
                    : "bg-white border-gray-200"
                }`}
              />
            </div>
            <select
              value={selectedSentiment}
              onChange={(e) => setSelectedSentiment(e.target.value)}
              className={`w-full px-3 py-2 rounded-lg border ${
                isDark
                  ? "bg-gray-800 border-gray-700 text-white"
                  : "bg-white border-gray-200"
              }`}
            >
              <option value="">All Sentiments</option>
              {["positive", "negative", "mixed"].map((sentiment) => (
                <option key={sentiment} value={sentiment}>
                  {sentiment.charAt(0).toUpperCase() + sentiment.slice(1)}
                </option>
              ))}
            </select>
          </div>

          {/* Add no data message for mobile */}
          {filteredStaffMentions.length === 0 ? (
            <div className={`text-center p-8 rounded-lg ${
              isDark ? "bg-gray-800 text-gray-300" : "bg-white text-gray-500"
            }`}>
              <p>No staff mentions found</p>
              <p className="text-sm mt-2">Try adjusting your search or filters</p>
            </div>
          ) : (
            /* Existing mobile cards rendering */
            filteredStaffMentions.map((mention) => (
              <MobileStaffCard key={mention.id} mention={mention} />
            ))
          )}

          {/* Update Load More button logic */}
          {hasMore && !isLoading && filteredStaffMentions.length > 0 && (
            <button
              onClick={handleLoadMore}
              className={`w-full py-2 px-4 rounded-lg ${
                isDark
                  ? "bg-gray-700 text-gray-200 hover:bg-gray-600"
                  : "bg-gray-100 text-gray-700 hover:bg-gray-200"
              } transition-colors`}
            >
              Load More
            </button>
          )}
        </div>
      ) : (
        // Desktop View - update DataTable props
        <DataTable
          columns={columns}
          data={filteredStaffMentions}
          defaultSortKey="review_date"
          defaultSortDirection="desc"
          filters={filters}
          searchPlaceholder="Search staff mentions..."
          customCellRenderers={customCellRenderers}
          theme={theme}
          isLoading={isLoading}
          hasMore={hasMore}
          onLoadMore={handleLoadMore}
          noDataMessage="No staff mentions found. Try adjusting your search or filters."
        />
      )}
    </div>
  );
};

StaffMentionsTable.propTypes = {
  onLoadMore: PropTypes.func.isRequired,
};

export default React.memo(StaffMentionsTable);