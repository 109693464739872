import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { approveReview } from '../../../store/slices/dashboardSlice';
import { 
  selectAccessToken, 
  selectSelectedRestaurant 
} from '../../../store/slices/authSlice';

/**
 * Hook for handling review approval functionality
 * @param {Function} onApprovalComplete - Callback function called after successful approval
 * @returns {Object} - Object containing approval handler and loading state
 */
export const useReviewApproval = (onApprovalComplete) => {
  const dispatch = useDispatch();
  const accessToken = useSelector(selectAccessToken);
  const selectedRestaurant = useSelector(selectSelectedRestaurant);
  const [isApproving, setIsApproving] = useState(false);
  const [error, setError] = useState(null);

  const handleApprove = useCallback(async (review) => {
    // Input validation
    if (!review) {
      console.error('Review object is required');
      return;
    }

    if (!review.location_id || !review.id) {
      console.error('Review must have location_id and id');
      return;
    }

    if (!accessToken) {
      console.error('No access token available');
      return;
    }

    setError(null);
    setIsApproving(true);

    try {
      // Dispatch approve review action
      const result = await dispatch(approveReview({
        locationId: review.location_id,
        reviewId: review.id,
        accessToken
      })).unwrap();

      // Handle Zapier webhook if configured
      if (selectedRestaurant?.approval_zapier_url) {
        try {
          await fetch(selectedRestaurant.approval_zapier_url, {
            method: 'POST',
            headers: { 
              'Content-Type': 'application/json',
              // Add optional authentication if needed
              // 'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify({
              reviewId: review.id,
              locationId: review.location_id,
              reviewContent: review.review,
              response: review.response,
              approvalTime: new Date().toISOString(),
              restaurantId: selectedRestaurant.id,
              restaurantName: selectedRestaurant.name
            }),
          });
        } catch (webhookError) {
          // Log webhook error but don't fail the approval
          console.error('Webhook notification failed:', webhookError);
        }
      }

      // Call completion callback with result
      onApprovalComplete?.(review.id, result);
      
      return result;
    } catch (error) {
      setError(error.message || 'Failed to approve review');
      console.error('Error approving review:', error);
      throw error;
    } finally {
      setIsApproving(false);
    }
  }, [dispatch, accessToken, selectedRestaurant, onApprovalComplete]);

  const resetError = useCallback(() => {
    setError(null);
  }, []);

  return {
    handleApprove,
    isApproving,
    error,
    resetError
  };
};

// Example usage:
/*
const ReviewComponent = ({ review }) => {
  const { handleApprove, isApproving, error } = useReviewApproval(
    (reviewId, result) => {
      console.log(`Review ${reviewId} approved successfully`, result);
    }
  );

  return (
    <div>
      {error && <div className="error">{error}</div>}
      <button 
        onClick={() => handleApprove(review)}
        disabled={isApproving}
      >
        {isApproving ? 'Approving...' : 'Approve Review'}
      </button>
    </div>
  );
};
*/